  import React, { useEffect, useState } from "react";
  import { FaMailBulk, FaPhone } from "react-icons/fa";
  import { useTranslation, Trans } from "react-i18next";
  import ReCAPTCHA from "react-google-recaptcha";
  import { contactDetails, contactCreate } from "../../redux/slices/contactSlice";
  import { useSelector, useDispatch } from "react-redux";
  import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
  import Swal from "sweetalert2";


  const Form = () => {
    const { i18n, t } = useTranslation();
    const [val, setVal] = useState("");

    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.contact);

    useEffect(() => {
      dispatch(contactDetails());
    }, [dispatch]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setmessage] = useState("");
    const [surname, setSurname] = useState("");
    const [phone, setPhone] = useState("");


    const resetForm = () => {
      // Use the form's reset method to clear input values
      document.getElementById("contactForm").reset();

      // Optionally, you can also reset the state values
      setName("");
      setEmail("");
      setmessage("");
      setSurname("");
      setPhone("");
    };


    

    
    const handleSubmit = async (e) => {
      e.preventDefault();
      const contact = {
        name,
        surname,
        message,
        email,
        phone
      };
      try {
        const response = await dispatch(contactCreate({ contact }));
        if (response && response.payload) {
          Swal.fire({
            title: "Success",
            html: "Your message has been sent successfully!",
            timer: 4000,
            timerProgressBar: true,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
            onClose: () => {
              // Reset the form after successful submission
              resetForm();
            },
          });
        }
      } catch (error) {
        // Catch any other errors
        Swal.fire({
          title: "Error",
          text: "An error occurred while adding the product. Please try again!",
          icon: "error",
        });
      }
    };
    

    return (
      <div className="py-8">
        <h3 className="font-semibold text-[#455694] text-center my-2 text-[25px]">
          <Trans i18nKey="description.homepart9">Contact</Trans>
        </h3>
        <div className="w-[100%] flex lg:flex-row flex-col justify-between pt-4 gap-8">
          {loading ? (
            <div className="w-[100%] lg:w-[45%] flex flex-col px-[50px] py-2 bg-slate-50">
                  Loading...

            </div>
          ) : (
            <div className="w-[100%] lg:w-[45%] flex flex-col px-[50px] py-2 bg-slate-50">
              <div className="">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12031.751427563278!2d29.015904!3d41.070351!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab644309fd86b%3A0x91aeff59c32de66a!2zVXlndXIgxLDFnyBNZXJrZXpp!5e0!3m2!1sen!2saz!4v1702722104778!5m2!1sen!2saz"
                  width="300"
                  height="200"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <p className="text-left pt-2">
                <Trans i18nKey="description.homepart11">{data?.address}</Trans>
              </p>
              <div className="flex items-center gap-4 pt-4">
                <FaPhone />
                <p>{data?.phone}</p>
              </div>

              <div className="flex items-center gap-4 pt-4">
                <FaMailBulk />
                <p>{data?.email}</p>
              </div>
            </div>
          )}

          <div className="w-[100%]">
            <form id="contactForm" onSubmit={handleSubmit} className="w-[100%]">
              <div className="">
                <input
                  required
                  className="w-[100%] border-[1px] border-[#EFEFEF] rounded-[10px] px-8 py-4  outline-none"
                  placeholder={t("description.contactpart1")}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <input
                  required
                  className="w-[100%] border-[1px] border-[#EFEFEF] rounded-[10px] px-8 py-4 outline-none"
                  placeholder={t("description.contactpart2")}
                  type="text"
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <input
                  required
                  className="w-[100%] border-[1px] border-[#EFEFEF] rounded-[10px] px-8 py-4 outline-none"
                  placeholder={t("description.contactpart3")}
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="pt-2">
              {/* <label
                htmlFor="telephone"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart10"> Telefon:</Trans>
              </label> */}
              <PhoneInput
                international
                defaultCountry="TR"
                onChange={setPhone}
                id="phone"
                name="phone"
                className="numinput"
                required
              />
            </div>
              <textarea
                required
                className="w-[100%] border-[1px] border-[#EFEFEF] rounded-[10px] px-8 py-4 mt-4 outline-none"
                placeholder={t("description.contactpart4")}
                rows={8}
                onChange={(e) => setmessage(e.target.value)}
              ></textarea>

              <ReCAPTCHA
                sitekey="6LfXKTspAAAAABmzZS3ut_3aAj8MThtV3lmUgL3b"
                onChange={(val) => setVal(val)}
              />
              <button
                type="submit"
                className="bg-indigo-500 text-white rounded-[5px]  px-8 py-4 mt-4"
              >
                <Trans i18nKey="description.homepart13">Send</Trans>
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  export default Form;
