import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          description: {
            homepart1: "Fast Shipment Abroad.",
            homepart2:
              "Kavvay manages your international shipment process seamlessly and automatically, helping you to send airmail to certain countries at affordable prices.",
            homepart3: "Register For Free",
            homepart4: "INTEGRATIONS",
            homepart5:
              "Speed up your shipments with Kavvay helpers and start making your shipments at affordable prices.",
            homepart6: "for more information on integrations .",
            homepart7: "PARTNERS",
            homepart8: "FREQUENTLY ASKED QUESTIONS",
            homepart9: "Contact",
            homepart10: "Contact information",
            homepart11:
              "Address: Nispetiye, Gazi Gucnar street, Uygur Business Center No 4/2, Beshiktash, Istanbul,34000",
            homepart12: "OUR ADVANTAGES",
            homepart13: "Send",

            contactpart1: "Write your name",
            contactpart2: "Write your surname",
            contactpart3: "Write your email",
            contactpart4: "Write your message",
            contactpart5: "Write your phone",

            Rpoart0: "Reset Password",

            footerpart0: "Company",
            footerpart1: "About",
            footerpart2: "FAQ",
            footerpart3: "Blog",
            footerpart4: "Legal",
            footerpart5: "GDPR",
            footerpart6: "Privacy Policy",
            footerpart7: "Terms & conditions",
            footerpart8: "Cookie Policy",
            footerpart9: "Compliance Statement",
            footerpart10: "Security profile",
            footerpart11: "Contact",
            footerpart12: "Support",
            footerpart13: "2024 All rights reserved by Kavvay",

            navpart1: "About",
            navpart2: "Click and Ship",
            navpart3: "Pricing",
            navpart4: "Contact",
            navpart5: "Sign in",
            navpart6: "Register",
            navmenupart1: "Packages",
            navmenupart2: "Calculate shipping",

            aboutpart1: "About",
            aboutpart2:
              "OPTIMIZE THE POST-PURCHASE EXPERIENCE FOR eCOMMERCE WITH KAVVAY",
            aboutpart3:
              "We are a young and fast growing business born in Turkey and looking to expand across the globe with team of technology and retail experts spread across different countries and different regions. We like to build high performance solutions for eCommerce businesses and have an unrelenting drive to build things fast and with outstanding quality.",
            aboutpart4:
              "We are aware that companies are experiencing an important cargo process in the background. We set out with the idea of ​​making your life easier and managing all your cargo processes. While providing   an easy shipping experience to cargo companies, we offer the most suitable cargo alternatives with dynamic pricing and fast delivery.",
            aboutpart5:
              "To businesses with Geliver; We provide various opportunities by       getting advantageous prices from all cargo companies with which they integrate. We are aware that cargo processes should be very practical now, we are here.",
            aboutpart6: "Your shipping process is much easier with us!",
            aboutpart7: "Why Come?",
            aboutpart8: "Cargo Alternative",
            aboutpart9:
              "You can have the opportunity to work with all cargo companies at the same time, or you can choose the most suitable one for you from the cargo companies we have listed for you.",
            aboutpart10: "Advantageous Price",
            aboutpart11:
              "You can have the opportunity to work with all cargo companies at the same time, or you can choose the most suitable one for you from the cargo companies we have listed for you.",
            aboutpart12: "Personalized Process",
            aboutpart13:
              "You can have the opportunity to work with all cargo companies at the same time, or you can choose the most suitable one for you from the cargo companies we have listed for you.",
            aboutpart14: "Ease of Use",
            aboutpart15:
              "You can have the opportunity to work with all cargo companies at the same time, or you can choose the most suitable one for you from the cargo companies we have listed for you.",

            epart1: "Click’n’Ship",
            epart2: "Come Easily with Geliver!",
            epart3:
              "It manages all your shipping processes; We provide fast, easy and cheap cargo service",
            epart4:
              "Click’n’Ship is the ultimate shipping solution for your business. Start shipping right away with pre negotiated rates and without having to deal with multiple delivery partners.",
            epart5: "Add your shipments",
            epart6:
              "Create shipments through a dashboard, with a spreadsheet or by using the Kavvay API and Kavvay eCommerce Connectors. Shipments are automatically assigned to the cheapest, fastest or most reliable delivery partner through an AI-driven automation engine.Alternatively, you can also select your preferred delivery service manually.",
            epart7: "Print and manage labels",
            epart8:
              "Pack your orders, print the label and you are ready to ship! The delivery partner gets notified about your delivery.",
            epart9: "Track your orders.",
            epart10:
              "Automatically track your orders and get alerted of any late deliveries, exceptions or unsatisfied customers. Offer your customers an exceptional experience by adding your own tracking page and setting up automated email or SMS notifications with information about the status of the shipment. Manage expectations by monitoring and adjusting expected delivery dates to improve your service levels. Use advanced reports to assess your past performance and make improvements to your operations.",

            calpart1: "Calculate Delivery Price",
            calpart2: "Choose Country",
            calpart3: "Height",
            calpart4: "Width",
            calpart5: "Length",
            calpart6: "Weight",
            calpart7: "Price:",
            calpart8: "Calculate",
            calpart9: "Reset",
            calpart10:
              "While calculating the weight of the shipments, the weight on the scale and the volumetric weight (desi) are taken as basis. (Length * Width * Height) / 5000 = Weight",
            calpart11:
              "Compare the current weight of the package with its volumetric weight. The heavier of the two weights is the billable weight and must be used to calculate the price.",
            calpart12:
              "Your shipments may not require customs clearance. Customs fees and taxes are calculated based on the content and value of your items and your countrys regulations.",

            calinputplaceholderpart1: "Max. 150 cm",
            calinputplaceholderpart2: "Max. 120 cm",

            calinputplaceholderpart3: "Max. 240 cm",

            calinputplaceholderpart4: "Max. 30 cm",

            calerrorpart1: "Please select country",

            calerrorpart2: "Please add height",
            calerrorpart3: "Please add width",
            calerrorpart4: "Please add length",
            calerrorpart5: "Please add weight",
            calvalidateerrorpart1: "Max height 150 cm",
            calvalidateerrorpart2: "Mak width 120 cm",
            calvalidateerrorpart3: "Mak length 240 cm",
            calvalidateerrorpart4: "Mak weight 30 kg",
            loginpart1: "Sign in",
            loginpart2: "or",
            loginpart3: "Create a new account",
            loginpart4: "remember me",
            loginpart5: "Forgot Password",
            loginpart6: "Login",

            menupart1: "Home Page",
            menupart2: "Profile",
            menupart3: "Settings",
            menupart4: "Customer Details",
            menupart5: "Account",
            menupart6: "Invoices",
            menupart7: "New Shipment",
            menupart8: "Calculate Price",
            menupart9: "Shippings",
            menupart10: "Payment",
            menupart11: "Frequently Asked Questions",
            menupart12: "Announcements",
            menupart13: "Support",

            profilepart1: "Settings:",
            profilepart2: "Name:",
            profilepart3: "Surname:",
            profilepart4: "Address:",
            profilepart5: "City:",
            profilepart6: "Province:",
            profilepart7: "Postal Code:",
            profilepart8: "Type of Membership:",
            profilepart9: "Individual",
            profilepart10: "Corporate",
            profilepart11: "ID Number:",
            profilepart12: "Phone Number:",
            profilepart13: "Company Name:",
            profilepart14: "Tax Number:",
            profilepart15: "Tax Office:",
            profilepart16: "Update",

            registerpart1: "with Geliver",
            registerpart2: "good luck!",
            registerpart3: "Speed up your deliveries to abroad with KAVVAY",
            registerpart4: "Register",
            registerpart5: "or",
            registerpart6: "Sign in",
            registerpart7: "Name:",
            registerpart8: "Last name:",
            registerpart9: "Email:",
            registerpart10: "Telephone:",
            registerpart11: "Password:",
            registerpart12: "Password Again:",
            registerpart131: "Personal Data Protection",
            registerpart132: "Commercial Electronic Message Confirmation Text",
            registerpart133: "User Agreement",
            registerpart134: "Cookie Policy",
            registerpart14: "read and approve.",
            registerpart15: "Register",

            afterloginpart1: "Profile:",
            afterloginpart2: "Shipping Count:",
            afterloginpart3: "Subscription status:",
            afterloginpart4: "Balance:",

            invoiceaddresspart1: "Dear Users,",
            invoiceaddresspart2:
              "The information you provide here will be used in the invoices that will be issued for your payments. Therefore, the information you provide here should match the payment details. All entities, including individual companies (such as LTD, AŞ, etc.), fall under the category of Legal Entity. If you have a company, you should select the address type as Legal Entity and fill in the fields for Tax Office and Tax Number that will be opened. If you do not have a company, you should select the address type as Natural Person.",
            invoiceaddresspart3: "Customer Details",
            invoiceaddresspart4: "Name",
            invoiceaddresspart5: "Surname",
            invoiceaddresspart6: "Address",
            invoiceaddresspart7: "City",
            invoiceaddresspart8: "Province",
            invoiceaddresspart9: "Postal Code",
            invoiceaddresspart10: "Country",
            invoiceaddresspart11: "Type of Membership",
            invoiceaddresspart12: "Individual",
            invoiceaddresspart13: "Corporate",
            invoiceaddresspart14: "Tax Office",
            invoiceaddresspart15: "Tax Number",
            invoiceaddresspart16: "Turkish Trade Registry",
            invoiceaddresspart17: "Tax Sheet",
            invoiceaddresspart18: "List of Authorized Signatures",
            invoiceaddresspart19: "Update",
            invoiceaddresspart20: "Company name",
            invoiceaddresspart21: "Phone  number",
            invoiceaddresspart22: "Signature",
            invoiceaddresspart23: "Tax certificate",
            invoiceaddresspart24: "Click to upload",

            accountpassswordpart1: "Password Change:",
            accountpassswordpart2: "Old Password:",
            accountpassswordpart3: "New Password:",
            accountpassswordpart4: "Confirm Password:",
            accountpassswordpart5: "Change Password",

            invoicepart1: "Card Selection",

            createshipmentpart1: "Receiver Information",
            createshipmentpart2: "Full Name:",
            createshipmentpart3: "Country:",
            createshipmentpart4: "City:",
            createshipmentpart5: "Province:",
            createshipmentpart6: "Address:",
            createshipmentpart7: "Postal Code:",
            createshipmentpart8: "Phone Number:",
            createshipmentpart9: "Email:",
            createshipmentpart10: "Product Info",
            createshipmentpart11: "Product Type:",
            createshipmentpart12: "Currency:",
            createshipmentpart13: "Count:",
            createshipmentpart14: "Price:",
            createshipmentpart15: "GTIP Code:",
            createshipmentpart16: "Delete",
            createshipmentpart17: "Add Product",
            createshipmentpart18: "Parcel Info",
            createshipmentpart19: "Parcel count:",
            createshipmentpart20: "Shipping no:",
            createshipmentpart21: "Weight (kg):",
            createshipmentpart22: "Length (cm):",
            createshipmentpart23: "Width (cm):",
            createshipmentpart24: "Height (cm):",
            createshipmentpart25: "Shipping Price:",
            createshipmentpart26: "Amount:",
            createshipmentpart27: "Save Shipping",
            createshipmentpart28: "Shipping saved Successfully",
            createshipmentpart29:
              "For payment for shipping and printing STICKER go to SHIPPINGS page",
            createshipmentpart30: "Close",
            createshipmentpart31: "Description",
            createshipmentpart32: "Product",
            createshipmentpart33: "Provider list",
            createshipmentpart34: "Product list",

            paymentpart1: "Date",
            paymentpart2: "Amount",
            paymentpart3: "Description",
            paymentpart4: "Status",
            paymentpart9: "Payments",

            announcementpart1: "Announcements",
            announcementpart2: "Create Announcement",
            announcementpart3: "ID",
            announcementpart4: "Header",
            announcementpart5: "Subheader",
            announcementpart6: "Date",

            announcementcreatepart1: "Create announcement form",
            announcementcreatepart2: "Header",
            announcementcreatepart3: "Subheader",
            announcementcreatepart4: "Description",
            announcementcreatepart5: "Create Announcement",
            announcementcreatepart6: "Announcement edit form",
            announcementcreatepart7: "Save changes",

            supportpart1: "Create Ticket:",
            supportpart2: "Create Ticket",
            supportpart3: "Create New Ticket",
            supportpart4: "Unit:",
            supportpart5: "Subject:",
            supportpart6: "Description:",
            supportpart7: "Upload File:",
            supportpart8: "Create Ticket",
            supportpart9: "Close",
            supportpart10: "Tickets List",
            supportpart11: "ID",
            supportpart12: "Creation Date",
            supportpart13: "Subject",
            supportpart14: "Status",
            supportpart15: "close",
            supportpart16: "Responsible Person",

            shippingspart1: "My Shipments",
            shippingspart2: "Country",
            shippingspart3: "Status",
            shippingspart4: "Table",
            shippingspart5: "ID",
            shippingspart6: "Pay Status",
            shippingspart7: "Status",
            shippingspart8: "Label",
            shippingspart9: "Receiver",
            shippingspart10: "Order Number",
            shippingspart11: "Country",
            shippingspart12: "Count",
            shippingspart13: "Value",
            shippingspart14: "Date",
            shippingspart15: "Upload Invoice",
            shippingspart16: "Edit",
            shippingspart17: "Delete",
            shippingspart18: "Are you sure you want to delete this row?",
            shippingspart19: "Delete",
            shippingspart20: "Close",
            shippingspart21: "New Shipment",
            shippingspart22: "Actions",

            faqpart0: "Frequently Asked Questions",
            faqpart1: "What is Kavvay?",
            faqpart2:
              "Kavvay is a courrier software service that can be used by e-commerce sites selling abroad from Turkey. You can manage your orders and shipments through your special Kavvay account and ensure that your shipments reach your customers via Kavvay courrier software technology.",
            faqpart3: "Is there a fee to use Kavvay?",
            faqpart4:
              "We charge a small fee for our customers who send their orders with their own courrier agreements and who only want to use the Kavvay software and integration service. For fee calculation you need to visit our Price calculation menu.",
            faqpart5: "How does Kavvay price calculation work?",
            faqpart6:
              "You can switch to the price calculation menu from your Kavvay account. After entering the width, height, height and weight information of your shipment on the page you are passing through, you can see the shipping fee by selecting the country. When calculating the weight of order shipments, physical weight and volumetric weight are calculated. The higher of the two weights is the weight to be charged.   volumetric weight (deci weight); It is obtained by multiplying the width, height and height values of the shipment with each other and dividing by 5000. (Length * Width * Height) / 5000 = Volumetric weight.",
            faqpart7: "Can I just ship without integrating my stores?",
            faqpart8:
              "You can ship without integrating your stores. You need to create a separate shipment for all your orders.",
            faqpart9: "What information is shared with Kavvay?",
            faqpart10:
              "Kavvay; With the permission and knowledge of all marketplaces, the customer can access their name, address, phone number, if any, product content and price. This information is required for easy shipment creation.",
            faqpart11: "How can I top up my Kavvay balance?",
            faqpart12:
              "In order to increase the balance of your Kavvay account, you can perform the necessary actions from the Profile interface.",
            faqpart13: "How can I send my cargo to Kavvay?",
            faqpart14:
              "After you create your labels for your shipments from your Kavvay account, you can add your Kavvay labels to your orders and make them ready for shipping.  Then you can send it to the address on the label from the nearest courier company branches.",
            faqpart15: " How can I track my shipping?",
            faqpart16:
              "The tracking status of your shipments will be updated from your account via the order number printed with the label you have prepared for your shipments.",
            faqpart17:
              "Can the shipment not be allowed to exit during customs clearance?",
            faqpart18:
              "The Last mile delivery process managing by our side, from the time your shipments leave the Kavvay warehouse until they are delivered to the customer. Each country may have customs procedures and customs limits. Example: The limit for the European Union is 22 Euros, for the USA it is 800 USD, for Azerbaijan it is 300 USD per month, for Uzbekistan it is 1000 USD per quarter. If the value of your shipment exceeds these amounts, your buyer is required to pay customs duty. Let us convey that when the buyer does not pay the customs duty, this tax should be paid by you.",
            faqpart19: "Are there restrictions on heavy and bulky shipments?",
            faqpart20:
              "Limitations apply to the weight, volumetric weight and dimensions of the shipment. Packages can be up to 30 kg.",
            faqpart21: "What information is required for shipments abroad?",
            faqpart22:
              "For shipments from Turkey to abroad, since the packages will be subject to customs procedures, some information about the shipment must be filled in completely.",
            faqpart23: "Which products are prohibited to be sent?",
            faqpart24:
              "- Live animal\n- Antiques (delicate and/or breakable)\n- Asbestos/Asbestos\n - Precious metals as bullion - Cash Money\n - Firearms and their parts and ammunition\n - Hazardous or flammable substances (determined by IATA regulation)\n - Human body parts or ashes\n - Jewels, precious metals and gems\n - Narcotic substances (illegal)               ",

            faqlist1: "FAQ",
            faqlist2: "Create faq",
            faqlist3: "ID",
            faqlist4: "Question title",
            faqlist5: "Question answer",
            faqlist6: "Status",

            faqcreatepart1: "Create FAQ form",
            faqcreatepart2: "Question title",
            faqcreatepart3: "Question answer",
            faqcreatepart4: "Create FAQ",
            faqcreatepart5: "FAQ edit form",
            faqcreatepart6: "Save changes",

            editshippingspart0: "Edit Shipping Data",
            editshippingspart1: "ID",
            editshippingspart2: "Payment Status",
            editshippingspart3: "Status",
            editshippingspart4: "Label",
            editshippingspart5: "Receiver",
            editshippingspart6: "Order Number",
            editshippingspart7: "Country",
            editshippingspart8: "Count",
            editshippingspart9: "Value",
            editshippingspart10: "Date",
            editshippingspart11: "Upload Invoice",
            editshippingspart12: "Upload Invoice",
            editshippingspart13: "Update",
            editshippingspart14: "Cancel",

            profilepartprimarytitle: "PROFILE",
            profilepartname: "Name",
            profilepartsurname: "Surname",
            profilepartemail: "Email",
            profilepartphone: "Phone",
            profilepartprimarybtn: "Update",
            profilepartsecondarytitle: "Security",
            profilepartoldpassword: "Old Password",
            profilepartnewpassword: "New Password",
            profilepartconfirmpassword: "Confirm Password",
            profilepartsecondarybtn: "Change Password",

            agentpart1: "Agents",
            agentpart2: "Create customer",
            agentpart3: "Customer name",
            agentpart4: "Subscription type",
            agentpart5: "Tax ID",
            agentcreatepart1: "Create agent form",
            agentcreatepart2: "Customer name",
            agentcreatepart3: "Customer surname",
            agentcreatepart4: "Company name",
            agentcreatepart5: "Country",
            agentcreatepart6: "Province",
            agentcreatepart7: "Post code",
            agentcreatepart8: "Address",
            agentcreatepart9: "Phone number",
            agentcreatepart10: "Tax ID",
            agentcreatepart11: "Tax office",
            agentcreatepart12: "Current balance",
            agentcreatepart13: "Current pack balance",
            agentcreatepart14: "Create agent",
            agentcreatepart15: "Subscription type",
            agentcreatepart16: "Agent edit form",

            subscriptionlistpart1: "Subscriptions",
            subscriptionlistpart2: "Create subscription",
            subscriptionlistpart3: "ID",
            subscriptionlistpart4: "Agent ID",
            subscriptionlistpart5: "Package ID",
            subscriptionlistpart6: "Paid amount",
            subscriptionlistpart7: "Start date",
            subscriptionlistpart8: "Paid amount",
            subscriptionlistpart9: "Agent Name",
            subscriptionlistpart10: "Package Name",
            subscriptionlistpart11: "Transaction number",
            subscriptionlistpart12: "Expire date",

            subscriptioncreatepart1: "Create subscription form",
            subscriptioncreatepart2: "Package ID",
            subscriptioncreatepart3: "Package amount",
            subscriptioncreatepart4: "Subscription type",
            subscriptioncreatepart5: "Create subscription",
            subscriptioncreatepart6: "Subscription edit form",
            subscriptioncreatepart7: "Save changes",

            packagelistpart1: "Packages",
            packagelistpart2: "Create package",
            packagelistpart3: "ID",
            packagelistpart4: "Package name",
            packagelistpart5: "Package price",
            packagelistpart6: "Pack balance",
            packagelistpart7: "Available",
            packagelistpart8: "Start date",

            packagecreatepart1: "Package create form",
            packagecreatepart2: "Package name",
            packagecreatepart3: "Package price",
            packagecreatepart4: "Package balance",
            packagecreatepart6: "Package availability",
            packagecreatepart7: "Package discount",
            packagecreatepart8: "Save Package",
            packagecreatepart9: "Package Edit form",
            packagecreatepart10: "Save changes",

            providerlistpart1: "Providers",
            providerlistpart2: "Create provider",
            providerlistpart3: "Name",
            providerlistpart4: "Country",
            providerlistpart5: "Warehouse address",
            providerlistpart6: "Provider edit form",
            providerlistpart45: "Status",
            providerlistpart7: "Save changes",
            providerlistpart8: "Provider create form",
            providerlistpart9: "Create provider",
            providerlistpart10: "Country ID",
            providerlistpart11: "City",
            providerlistpart12: "Post code",
            providerlistpart13: "Legal Address",
            providerlistpart14: "Warehouse address",
            providerlistpart15: "Phone number",
            providerlistpart16: "Delivery price",
            providerlistpart17: "Delivery period",

            dashboardlistpart1: "Waiting",
            dashboardlistpart2: "All",
            dashboardlistpart3: "On the way",
            dashboardlistpart4: "Delivered",
            dashboardlistpart5: "Cancelled",

            adminsidebarpart1: "Dashboard",
            adminsidebarpart2: "Profile",
            adminsidebarpart3: "Shipping List",
            adminsidebarpart4: "Agent List",
            adminsidebarpart5: "Provider List",
            adminsidebarpart6: "FAQ List",
            adminsidebarpart7: "Announcement List",
            adminsidebarpart8: "Payment history",
            adminsidebarpart9: "Subscriptions",
            adminsidebarpart10: "Packages",
            adminsidebarpart11: "Countries",

            agentsidebarpart1: "Dashboard",
            agentsidebarpart2: "Profile",
            agentsidebarpart3: "Customer Details",
            agentsidebarpart4: "Create shipping",
            agentsidebarpart5: "Shipping List",
            agentsidebarpart6: "Calculate Price",
            agentsidebarpart7: "FAQ",
            agentsidebarpart8: "Payments",
            agentsidebarpart9: "Log out",

            providersidebarpart1: "Dashboard",
            providersidebarpart2: "Profile",
            providersidebarpart3: "Shipping List",
            providersidebarpart4: "FAQ List",
            providersidebarpart5: "Announcements",
            providersidebarpart6: "Support",

            countrylistpart1: "Countries",
            countrylistpart2: "Create country",
            countrylistpart3: "ID",
            countrylistpart4: "Country name",
            countrylistpart5: "ISO",
            countrylistpart6: "Provider",
            countrylistpart7: "Delivery price",
            countrylistpart8: "Status",

            tablepart1: "Actions",

            orderinputplaceholder1: "Write your name",
            orderinputplaceholder2: "Write your surname",
            orderinputplaceholder3: "Write your email",
            orderinputplaceholder4: "Choose country",
            orderinputplaceholder5: "Write your city",
            orderinputplaceholder6: "Write your province",
            orderinputplaceholder7: "Write your address",
            orderinputplaceholder8: "Write your postal code",
            orderinputplaceholder9: "Write your description",
            orderinputplaceholder10: "Product name",
            orderinputplaceholder11: "Product category",
            orderinputplaceholder12: "Write your count",
            orderinputplaceholder13: "Write your price",
            orderinputplaceholder14: "Write your GTIP code",
            orderinputplaceholder15: "Write your parcel weight",
            orderinputplaceholder16: "Write your parcel length",
            orderinputplaceholder17: "Write your parcel width",
            orderinputplaceholder18: "Write your parcel height",
            orderinputplaceholder19: "Write your phone number",
            orderinputplaceholder22: "Choose your agent",
            orderinputplaceholder20: "Calculate",
            orderinputplaceholder21: "Price",

            agentinputplaceholder1: "Write customer name",
            agentinputplaceholder2: "Write customer surname",
            agentinputplaceholder3: "Write TAX ID",
            agentinputplaceholder4: "Write TAX office",
            agentinputplaceholder5: "Write current balance",
            agentinputplaceholder6: "Write current pack balance",
            agentinputplaceholder7: "Write company name",

            subscriptionhomeprcingmethod: "Yearly",
            subscriptionhomecontact: "Special offer",
            subscriptionhomecontact2: "Choose package",
            subscriptionhomecontact3: "Contact us",

            providerinputplaceholder1: "Write provider name",
            providerinputplaceholder2: "Write provider phone",
            providerinputplaceholder3: "Write provider legal address",
            providerinputplaceholder4: "Write provider warehouse address",
            providerinputplaceholder5: "Write provider delivery price",
            providerinputplaceholder6: "Write provider delivery period",

            providerpricingcolumn1: "Name",
            providerpricingcolumn2: "From",
            providerpricingcolumn3: "To",
            providerpricingcolumn4: "Price",
            providerpricingcolumn5: "Pricing list",
            providerpricingcolumn6: "Add price",
            providerpricingcolumn7: "PerKgCalc",

            subscriptionhometitle: "Kavvay Pricing",

            clickandshoppart1: "Manage your global shipments from one point",
            clickandshoppart2: "Easy and multi integration",
            clickandshoppart3:
              "Integrate with new delivery carrier in a short time",
            clickandshoppart4: "Request the demo",

            updatelocalcargomodalheader: "Update Local Cargo",
            updatelocalcargoname: "Local name",
            updatelocalcargotrackingnumber: "Tracking number",
            updatelocalcargonameplaceholder: "Write local name",
            updatelocalcargotrackingnumberplaceholder: "Write tracking number",
            shippingfilter1: "Choose country",
            shippingfilter2: "Status",

            registerinputplaceholder1: "Write your password",
            registerinputplaceholder2: "Write your password again",

            subscriptiondetails1: "Subscription details:",
            subscriptiondetails2: "Annual price:",
            subscriptiondetails3: "Pack balance:",
            subscriptiondetails4: "Start date:",
            subscriptiondetails5: "Expire date:",
            subscriptiondetails6: "Available",

            alert1: "Your profile has not yet been verified",
            alert2: "Your customer account has not been approved.",

            faqquestionpart1: "What is Kavvay?",
            faqanswerpart1:
              "Kavvay is an e-shipping software service that can be used by e-commerce sites selling abroad from Turkey. By managing your orders and shipments through your private Kavvay account, you can ensure that your shipments reach your customers abroad via Kavvay E-Shipment software technology.",
            faqquestionpart2: "Is there a fee to use Kavvay?",
            faqanswerpart2:
              "We charge a small fee for our customers who send their orders with their own courrier agreements and who only want to use the Kavvay software and integration service. For fee calculation you need to visit our Price calculation menu.",
            faqquestionpart3: "How does Kavvay price calculation work?",
            faqanswerpart3:
              "You can switch to the Price calculation menu from your Kavvay account. After entering the width, length, height and weight information of your shipment on the page you go to, you can see the shipping fee by selecting the country. When calculating the weight of order shipments, physical weight and volumetric weight are calculated. The higher of the two weights is the weight to be charged.\n Volumetric weight (desi weight); It is calculated by multiplying the width, length and height values of the shipment and dividing by 5000. (Width * Length * Height) / 5000 = Volumetric weight.",
            faqquestionpart4: "Can I just ship without integrating my stores?",
            faqanswerpart4:
              "You can ship without integrating your stores. You must create separate shipments for all your orders.",
            faqquestionpart5: "What information is shared with Kavvay?",
            faqanswerpart5:
              "Kavvay; With the permission and knowledge of all marketplaces, customers can access their name, address, phone number, product content and price. This information is required for easy shipment creation.",
            faqquestionpart6: "How can I get Kavvay packages?",
            faqanswerpart6:
              "After becoming a member of Kavvay, you can start using it actively by paying for the package that suits you.",
            faqquestionpart7: "How can I send my cargo to Kavvay?",
            faqanswerpart7:
              "After creating your labels for your shipments from your Kavvay account, you can add your Kavvay labels to your orders and make them ready for shipment. You can send it to the address shown on the label with your contracted cargo.",
            faqquestionpart8: "How can I track my cargo?",
            faqanswerpart8:
              "The movement status of your shipments will be updated in your account using the order number printed with the label you prepared for your shipments.",
            faqquestionpart9:
              "Can the shipment be denied exit during customs procedures?",
            faqanswerpart9:
              "The entire operation is managed by us, from the time your shipments leave the Kavvay office until they are delivered to the customer. Each country may have customs procedures and customs limits. Example: For the European Union the limit is 22 Euros, for the USA it is 800 USD, for Azerbaijan it is 300 USD per month, for Uzbekistan it is 1000 USD per quarter. If the value of your shipment exceeds these amounts, your recipient must pay customs duty. Let us inform you that when the buyer does not pay the customs tax, this tax should be paid by you.",
            faqquestionpart10:
              "Are there any restrictions on heavy and bulky shipments?",
            faqanswerpart10:
              "Limitations apply to the weight, volumetric weight and dimensions of the shipment. Packages can weigh up to 30 kg.",
            faqquestionpart11:
              "What information is required for shipments abroad?",
            faqanswerpart11:
              "For shipments from Turkey to abroad, some information regarding the shipment must be filled in completely, as the packages will be subject to customs clearance.",
            faqquestionpart12: "Which products are prohibited to be sent?",
            faqanswerpart12:
              "- Live animal\n- Antiques (delicate and/or breakable)\nAsbestos/Asbestos\n- Precious metals, as bullion\n- Cash Money\n- Firearms and their parts and ammunition\n- Hazardous or flammable substances (specified by IATA regulations)\n- Human body parts or ashes\n- Jewellery, precious metals and precious stones\n- Narcotic substances (illegal)",
          },
        },
      },
      tr: {
        translation: {
          description: {
            homepart1: "Yurtdışına Hızlı Gönderi",
            homepart2:
              "Kavvay, uluslararası gönderi sürecinizi sorunsuzca ve otomatik şekilde yönetir, belirli ülkelere uygun fiyattan havayolu ile gönderi yapmanıza yardımcı olur.",
            homepart3: "Ücretsiz Kayıt Ol",
            homepart4: "ENTEGRASYONLAR",
            homepart5:
              "Gönderilerinizi, Kavvay altyapısıyla hızlandırın ve uygun fiyatlardan gönderilerinizi yapmaya başlayın.",
            homepart6: "Entegrasyonlar hakkında daha fazla bilgi için.",
            homepart7: "Partnerlerimiz",
            homepart8: "SIKÇA SORULAN SORULAR",
            homepart9: "İletişim Bilgileri",
            homepart10: "İletişim bilgileri",
            homepart11:
              "Adres: Nispetiye Mahallesi, Gazi Güçnar sokak, Uygur İş Merkezi No 4/2, Beşiktaş, İstanbul, 34000",
            homepart12: "BİZİM AVANTAJLARIMIZ",
            homepart13: "Gönder",

            contactpart1: "İsim yazınız",
            contactpart2: "Soyisim yazınız",
            contactpart3: "E-posta yazınız",
            contactpart4: "Mesaj yazınız",
            contactpart5: "Numaranızı yazın",

            Rpoart0: "Şifreyi yenile",

            footerpart0: "Şirket",
            footerpart1: "Hakkımızda",
            footerpart2: "SSS",
            footerpart3: "Blog",
            footerpart4: "Sözleşmeler",
            footerpart5: "Kişisel Verilerin Korunması",
            footerpart6: "Ticari Elektronik İleti Onay Metni",
            footerpart7: "Kullanıcı Sözleşmesi",
            footerpart8: "Çerez Politikası",
            footerpart9: "Uygunluk bildirimi",
            footerpart10: "güvenlik profili",
            footerpart11: "İletişim",
            footerpart12: "Destek",
            footerpart13:
              "2023 Tüm hakları Kavvay Lojistik ve Teknoloji Anonim Şirketine aittir.",

            navpart1: "Hakkımızda",
            navpart2: "Tıkla Gönder",
            navpart3: "Fiyatlandırma",
            navpart4: "İletişim",
            navpart5: "Giriş yap",
            navpart6: "Kayıt ol",
            navmenupart1: "Paketler",
            navmenupart2: "Gönderi hesapla",

            aboutpart1: "Hakkımızda",
            aboutpart2:
              "KAVVAY İLE E-TİCARET İÇİN SATIN ALMA SONRASI DENEYİMİ İYİLEŞTİRİN",
            aboutpart3:
              "Biz, Türkiyede yola başlamış ve farklı ülkeler ve farklı bölgelere dağılmış teknoloji ve perakende uzmanlarından oluşan bir ekiple dünyaya yayılmak isteyen genç ve hızlı büyüyen bir şirketiz. E-Ticaret işletmeleri için yüksek performanslı çözümler oluşturmayı seviyoruz ve her şeyi hızlı ve olağanüstü kalitede oluşturmak için amansız bir dürtüye sahibiz.",
            aboutpart4:
              "Firmaların arka planda önemli bir kargo süreci yaşadığının farkındayız. Hayatınızı kolaylaştırmak ve tüm kargo süreçlerinizi yönetmek fikriyle yola çıktık. Kargo firmalarına kolay bir nakliye deneyimi sunarken, en dinamik fiyatlandırma ve hızlı teslimat ile uygun kargo alternatifleri.",
            aboutpart5:
              "Geliverli işletmelere; Entegre oldukları tüm kargo şirketlerinden avantajlı fiyatlar alarak çeşitli imkanlar sağlıyoruz. Kargo işlemlerinin artık çok pratik olması gerektiğinin bilincindeyiz, buradayız.",
            aboutpart6: "Nakliye süreciniz bizimle çok daha kolay!",
            aboutpart7: "Neden gel?",
            aboutpart8: "Kargo Alternatifi",
            aboutpart9:
              "Tüm kargo firmaları ile aynı anda çalışma imkanına sahip olabilir ya da sizler için listelediğimiz kargo firmaları arasından size en uygun olanı seçebilirsiniz.",
            aboutpart10: "Avantajlı Fiyat",
            aboutpart11:
              "Tüm kargo firmaları ile aynı anda çalışma imkanına sahip olabilir ya da sizler için listelediğimiz kargo firmaları arasından size en uygun olanı seçebilirsiniz.",
            aboutpart12: "Kişiselleştirilmiş Süreç",
            aboutpart13:
              "Tüm kargo firmaları ile aynı anda çalışma imkanına sahip olabilir ya da sizler için listelediğimiz kargo firmaları arasından size en uygun olanı seçebilirsiniz.",
            aboutpart14: "Kullanım kolaylığı",
            aboutpart15:
              "Tüm kargo firmaları ile aynı anda çalışma imkanına sahip olabilir ya da sizler için listelediğimiz kargo firmaları arasından size en uygun olanı seçebilirsiniz.",

            epart1: "Tıkla Gönder",
            epart2: "Kavvay ile Uluslararsı gönderilerinizi hızlandırın!",
            epart3:
              "Tüm sevkiyat süreçlerinizi yönetir; Hızlı, kolay ve ucuz kargo hizmeti veriyoruz!",
            epart4:
              "Tıkla Gönder, işletmeniz için nihai yurtdışı kargo çözümüdür. Önceden kararlaştırılan oranlarla ve birden fazla teslimat ortağıyla uğraşmak zorunda kalmadan yurtdışına göndermeye hemen başlayın.",
            epart5: "Gönderilerinizi ekleyin",
            epart6:
              "Gönderileri bir kontrol paneli aracılığıyla, bir e-tabloyla veya Kavvay API ve Kavvay e-Ticaret Bağlayıcılarını kullanarak oluşturun. Gönderiler, yapay zeka destekli bir otomasyon motoru aracılığıyla otomatik olarak en ucuz, en hızlı veya en güvenilir teslimat ortağına atanır. Alternatif olarak, tercih ettiğiniz teslimat hizmetini manuel olarak da seçebilirsiniz.",
            epart7: "Etiketleri yazdırın ve yönetin",
            epart8:
              "Siparişlerinizi paketleyin, etiketi yazdırın ve gönderilmeye hazırsınız! Teslimat ortağı, teslimatınız hakkında bilgilendirilir.",
            epart9: "Siparişlerinizi takip edin",
            epart10:
              "Siparişlerinizi otomatik olarak takip edin ve geç teslimatlar, istisnalar veya memnun olmayan müşteriler hakkında uyarı alın. Kendi takip sayfanızı ekleyerek ve gönderinin durumu hakkında bilgi içeren otomatik e-posta veya SMS bildirimleri ayarlayarak müşterilerinize olağanüstü bir deneyim sunun. Hizmet seviyelerinizi iyileştirmek için beklenen teslim tarihlerini izleyerek ve ayarlayarak beklentileri yönetin. Geçmiş performansınızı değerlendirmek ve operasyonlarınızda iyileştirmeler yapmak için gelişmiş raporları kullanın.",

            calpart1: "Gönderi Ücreti Hesapla",
            calpart2: "Ülke Seçiniz",
            calpart3: "Yükseklik",
            calpart4: "En",
            calpart5: "Boy",
            calpart6: "Ağırlık",
            calpart7: "Fiyat:",
            calpart8: "Hesapla",
            calpart9: "Sıfırla",
            calpart10:
              "Gönderilerin ağırlığı hesaplanırken tartı üzerindeki ağırlığı ve hacimsel ağırlığı(desi) baz alınır.Hacimsel ağırlık hesaplamalarında gönderinizin en boy ve yükseklik değerlerinin birbiri ile çarpılmasının 5000 ile bölümü ile bulunmaktadır. (En  *Boy * Yükseklik) / 5000 = Ağırlık",
            calpart11:
              "Paketin geçerli ağırlığını hacimsel ağırlığıyla karşılaştırın. İki ağırlıktan daha ağır olanı, faturalandırılabilir ağırlıktır ve fiyatı hesaplamak için bunun kullanılması gerekir.",
            calpart12:
              "Gönderimleriniz gümrükleme gerektirmeyebilir. Gümrük ücretleri ve vergileri, öğelerinizin içeriğine ve değerine ve ülkenizin düzenlemelerine göre hesaplanır.",
            calinputplaceholderpart1: "Maks. 150 cm",
            calinputplaceholderpart2: "Maks. 120 cm",
            calinputplaceholderpart3: "Maks. 240 cm",
            calinputplaceholderpart4: "Maks. 30 kg",
            calerrorpart1: "Ülke seçiniz",
            calerrorpart2: "Yükseklik ekleyin",
            calerrorpart3: "En ekleyin",
            calerrorpart4: "Boy ekleyin",
            calerrorpart5: "Ağırlık ekleyin",
            calvalidateerrorpart1: "Maks yükseklik 150 cm",
            calvalidateerrorpart2: "Maks en 120 cm",
            calvalidateerrorpart3: "Maks boy 240 cm",
            calvalidateerrorpart4: "Maks ağırlık 30 kg",
            loginpart1: "Üye Girişi Yap",
            loginpart2: "veya",
            loginpart3: "Yeni üyelik oluşturun",
            loginpart4: "Beni hatırla",
            loginpart5: "Şifremi unuttum",
            loginpart6: "Giriş yap",

            menupart1: "Anasayfa",
            menupart2: "Profil",
            menupart3: "Ayarlar",
            menupart4: "Fatura Adresi",
            menupart5: "Hesap",
            menupart6: "Faturalar",
            menupart7: "Gönderi Oluştur",
            menupart8: "Fiyat Hesapla",
            menupart9: "Gönderilerim",
            menupart10: "Ödeme",
            menupart11: "SIKÇA SORULAN SORULAR",
            menupart12: "Duyurular",
            menupart13: "Destek",

            profilepart1: "Ayarlar:",
            profilepart2: "İsim:",
            profilepart3: "Soyad:",
            profilepart4: "Adres:",
            profilepart5: "İl:",
            profilepart6: "İlçe:",
            profilepart7: "Posta kodu:",
            profilepart8: "Üyelik Tipi:",
            profilepart9: "Bireysel",
            profilepart10: "Kurumsal",
            profilepart11: "Kimlik Numarası:",
            profilepart12: "Telefon numarası:",
            profilepart13: "Firma Adı:",
            profilepart14: "Vergi numarası:",
            profilepart15: "Vergi Dairesi:",
            profilepart16: "Güncelle",

            registerpart1: "Geliver ile",
            registerpart2: "kolayca gelsin!",
            registerpart3: "KAVVAY ile yurtdışı gönderileriniz daha hızlı",
            registerpart4: "Kayıt Ol",
            registerpart5: "veya",
            registerpart6: "Giriş Yap",
            registerpart7: "İsim:",
            registerpart8: "Soyisim:",
            registerpart9: "E-posta:",
            registerpart10: "Telefon:",
            registerpart11: "Şifre:",
            registerpart12: "Şifre Tekrar:",
            registerpart131: "Kişisel Verilerin Korunması",
            registerpart132: "Ticari Elektronik İleti Onay Metni",
            registerpart133: "Kullanıcı Sözleşmesi",
            registerpart134: "Çerez Politikası",
            registerpart14: "okudum ve onaylıyorum.",
            registerpart15: "Kayıt Ol",

            afterloginpart1: "Profil:",
            afterloginpart2: "Gönderi Sayısı:",
            afterloginpart3: "Abonelik durumu:",
            afterloginpart4: "Bakiye:",

            invoiceaddresspart1: "Değerli Kullanıcılarımız,",
            invoiceaddresspart2:
              "Yaptığınız ödemeler için kesilecek olan faturalarda burada beyan ettiğiniz bilgiler kullanılacaktır. Bu nedenle ödeme yapan kişi bilgileri ile burada yazdığınız bilgilerin eşleşmesi gerekmektedir. Şahıs şirketleri dahil LTD, AŞ, vb. tüm şirketler Tüzel Kişi kapsamındadır. Firmanız var ise adres tipini Tüzel Kişi olarak seçmeli ve açılacak olan Vergi Dairesi ve Vergi Numarası alanlarını doldurmalısınız. Eğer firmanız yok ise adres tipini Gerçek Kişi olarak seçmelisiniz.",
            invoiceaddresspart3: "Fatura adresi:",
            invoiceaddresspart4: "İsim:",
            invoiceaddresspart5: "Soyad:",
            invoiceaddresspart6: "Adres:",
            invoiceaddresspart7: "İl",
            invoiceaddresspart8: "İlçe",
            invoiceaddresspart9: "Posta Kodu:",
            invoiceaddresspart10: "Ülke",
            invoiceaddresspart11: "Üyelik Tipi",
            invoiceaddresspart12: "Bireysel",
            invoiceaddresspart13: "Kurumsal",
            invoiceaddresspart14: "Vergi Dairesi:",
            invoiceaddresspart15: "Vergi numarası:",
            invoiceaddresspart16: "Sicil Gazetesi:",
            invoiceaddresspart17: "Vergi Levhası:",
            invoiceaddresspart18: "İmza Sirküsü:",
            invoiceaddresspart19: "Güncelle",
            invoiceaddresspart20: "Şirket adı",
            invoiceaddresspart21: "Telefon",
            invoiceaddresspart22: "İmza sirküleri",
            invoiceaddresspart23: "Vergi levhası",
            invoiceaddresspart24: "Yüklemek için tıklayın",

            accountpassswordpart1: "Şifre Değiştirme:",
            accountpassswordpart2: "Eski Şifre:",
            accountpassswordpart3: "Yeni Şifre:",
            accountpassswordpart4: "Şifreyi Onayla:",
            accountpassswordpart5: "Şifre değiştir",

            invoicepart1: "Kart Seçimi",

            createshipmentpart1: "ALICI BİLGİLERİ",
            createshipmentpart2: "İsim soyisim",
            createshipmentpart3: "Ülke",
            createshipmentpart4: "İl",
            createshipmentpart5: "İlçe",
            createshipmentpart6: "Adres:",
            createshipmentpart7: "Posta Kodu:",
            createshipmentpart8: "Telefon:",
            createshipmentpart9: "E-posta:",
            createshipmentpart10: "Ürün adı",
            createshipmentpart11: "Ürün kategorisi:",
            createshipmentpart12: "Para Birimi:",
            createshipmentpart13: "Adet:",
            createshipmentpart14: "Fiyat:",
            createshipmentpart15: "GTİP:",
            createshipmentpart16: "Sil",
            createshipmentpart17: "Ekle",
            createshipmentpart18: "PAKET İŞLEMLERİ",
            createshipmentpart19: "Parsel sayısı:",
            createshipmentpart20: "Gönderi no:",
            createshipmentpart21: "Ağırlık (kg):",
            createshipmentpart22: "En (cm):",
            createshipmentpart23: "Boy (cm):",
            createshipmentpart24: "Yükseklik (cm):",
            createshipmentpart25: "Gönderi Ücreti:",
            createshipmentpart26: "Miktar:",
            createshipmentpart27: "Gönderiyi Kaydet",
            createshipmentpart28: "Gönderim Başarıyla Kaydedildi",
            createshipmentpart29:
              "Gönderim ücretini ödemek ve Etiket yazdırmak için GÖNDERİLERİM sayfasına git",
            createshipmentpart30: "Kapat",
            createshipmentpart31: "Açıklama",
            createshipmentpart32: "Ürün",
            createshipmentpart33: "Tedarikçi listesi",
            createshipmentpart34: "Gönderi listesi",

            paymentpart1: "Tarih",
            paymentpart2: "Ödeme tutarı",
            paymentpart3: "Açıklama",
            paymentpart4: "Durum",
            paymentpart9: "Ödemeler",

            announcementpart1: "Duyurular",
            announcementpart2: "Duyuru Oluştur",
            announcementpart3: "ID",
            announcementpart4: "Başlık",
            announcementpart5: "Alt Başlık",
            announcementpart6: "Tarih",

            announcementcreatepart1: "Duyuru oluşturma formu ",
            announcementcreatepart2: "Başlık",
            announcementcreatepart3: "Alt Başlık",
            announcementcreatepart4: "Açıklama",
            announcementcreatepart5: "Duyuru Oluştur",
            announcementcreatepart6: "Duyuru düzenleme formu",
            announcementcreatepart7: "Değişiklikleri Kaydet",

            supportpart1: "Talep Oluştur",
            supportpart2: "Talep Oluştur",
            supportpart3: "Yeni Talep Oluştur",
            supportpart4: "Birim:",
            supportpart5: "Konu:",
            supportpart6: "Açklama:",
            supportpart7: "Dosya Yükle:",
            supportpart8: "Talep Oluştur",
            supportpart9: "İptal et",
            supportpart10: "Talep Listesi",
            supportpart11: "ID",
            supportpart12: "Tarih",
            supportpart13: "Konu",
            supportpart14: "Durum",
            supportpart15: "İptal et",
            supportpart16: "Sorumlu kişi",

            shippingspart1: "Gönderilerim",
            shippingspart2: "Ülke",
            shippingspart3: "Durum",
            shippingspart4: "Tablosu",
            shippingspart5: "ID",
            shippingspart6: "Ödeme",
            shippingspart7: "Durum",
            shippingspart8: "Etiket",
            shippingspart9: "Alıcı",
            shippingspart10: "Sipariş N/",
            shippingspart11: "Ülke:",
            shippingspart12: "Adet",
            shippingspart13: "Gümrük Değeri",
            shippingspart14: "Tarih",
            shippingspart15: "Fatura Yükle",
            shippingspart16: "Düzenle",
            shippingspart17: "Sil",
            shippingspart18: "Bu satırı silmek istediğinizden emin misiniz?",
            shippingspart19: "Sil",
            shippingspart20: "Kapat",
            shippingspart21: "Gönderi oluştur",
            shippingspart22: "İşlemler",

            faqpart0: "Sıkça Sorulan Sorular",
            faqpart1: "Kavvay nedir?",
            faqpart2:
              "Kavvay, Türkiye’den yurt dışına satış yapa e-ticaret sitelerinin kullana bileceği E-gönderi yazılım hizmetidir. Size özel Kavvay hesabı üzerinden siparişlerinizi ve gönderilerinizi yöneterek, Kavvay E-Gönderi yazılım teknolojisi üzerinden gönderilerinizi müşterilerinize ulaşmasını sağlaya bilirsiniz.",
            faqpart3: "Kavvay’yı kullanmak ücretli mi?",
            faqpart4:
              "Sipariş gönderimini kendi kargo anlaşmalarıyla yapan, sadece Kavvay yazılım ve entegrasyon hizmetini kullanmak isteyen müşterilerimiz için küçük bir ücretlendirme yapmaktayız. Ücret hesaplama ve daha detaylı bilgi için Fiyat Hesaplama menüsüne geçiş yapmanız gerekiyor.",
            faqpart5: "Kavvay fiyat hesaplama nasıl çalışır? ",
            faqpart6:
              "Kavvay hesabınızdan Fiyat hespalama menüsüne geçiş yapa bilirsiniz. Geçiş yaptığınız sayfada gönderinizin en, boy ve yükseklik ve ağırlık bilgilerini girildikten sonra ülke seçimi yaparak gönderi ücretini göre bilirsiniz. Sipariş gönderilerinin ağırlığı hesaplanırken fiziksel ağırlık ve hacimsel ağırlık hesaplanıyor. İki ağırlıktan yüksek olan ücretlendirilecek ağırlıktır Hacimsel ağırlık (desi ağırlığı); gönderinin en, boy ve yükseklik değerlerinin birbiri ile çarpılmasının 5000 ile bölünmesi ile alınır. (Uzunluk * Genişlik * Yükseklik) / 5000 = Hacimsel ağırlık.",
            faqpart7:
              "Mağazalarımı entegre etmeden sadece gönderi yapa bilirmiyim?",
            faqpart8:
              "Mağazalarınızı entegre etmeden gönderi yapa bilirsiniz. Tüm siparişleriniz için ayrılıkta gönderi oluşturmanız gerekmektedir.",
            faqpart9: "Kavvay ile hangi bilgiler paylaşılmaktadır?",
            faqpart10:
              "Kavvay; tüm pazar yerlerinin izni ve bilgisi dahilinde müşteri adı, adresi, varsa telefon numarası, ürün içeriği ve fiyatına erişebiliyor. Bu bilgiler kolay gönderi oluşturulması için gereklidir.",
            faqpart11: "Kavvay bakiyemi nasıl arttırabilirim?",
            faqpart12:
              "Kavvay hesabınızın bakiyesinin artırmak için Profil arayüzünden gerekli işlemleri yapa bilirsiniz.",
            faqpart13: "Kargolarımı Kavvay apostrophe ya nasıl gönderebilirim?",
            faqpart14:
              "Kavvay hesabınızdan gönderileriniz için etiketlerinizi oluşturduktan sonra siparişlerinizin üzerine Kavvay etiketlerinizi ekleyerek gönderime hazır ede bilirsiniz.   Daha sonra Size en yakın kargo şirketi şubelerinden etiket üzerindeki adrese göndere bilirsiniz",
            faqpart15: "Kargo takip nasıl yapabilirim?",
            faqpart16:
              "Gönderileriniz için hazırladığınız etiketle basılan sipariş no üzerinden gönderilerinizin hareket durumunu hesabınızdan yenilenecektir",
            faqpart17:
              "Gönderim gümrüğe işlemleri sırasında çıkışına izin verilmeye bilirmi?",
            faqpart18:
              "Gönderileriniz Kavvay ofisinden çııktıktan sonra müşteriye teslim edilmesine kadar tüm operasyon bizim tarafımızdan yönetiliyor. Her bir ülkenin gümrük prosedürü ve gümrük limitleri olabilmektedir. Örnek: Avrupa Birliği için sınır 22 Euro, ABD için 800 USD’dır, Azerbaycan için aylık 300 USD, Özbekistan için her çeyrek için 1000 USD-dir. Gönderinizin değeri bu tutarları aştığı takdirde alıcınızın gümrük vergisi ödemesi gerekiyor. Alıcı gümrük vergisin ödemediği zaman, bu verginin sizin tarafınızdan ödenilmesi gerektiğini iletmiş olalım.",
            faqpart19: "Ağır ve hacimli gönderilerde sınırlamalar var mı?",
            faqpart20:
              "Gönderinin ağırlığı, hacimsel ağırlığı ve ebatları olmak üzere sınırlamalar uygulanmaktadır Paketler en fazla 30 kg olabilir.",
            faqpart21: "Yurt dışına gönderilerinde hangi bilgiler gereklidir?",
            faqpart22:
              "Türkiye’den yurt dışına yapacağınız gönderilerde, paketler gümrük işlemlerine tabi tutulacağı için gönderiye ait bazı bilgilerin esksiksiz doldurulması gerekli.",
            faqpart23: "Gönderilmesi yasak olan ürünler hangileridir?",
            faqpart24:
              "-Canlı hayvan\n- Antikalar (hassas ve/veya kırılabilir)\n- Asbest/Amyant\n- Değerli madenler, külçe olarak\n- Nakit para\n- Ateşli silahlar ve bunların parçaları ve mühimmat\n- Tehlikeli veya yanıcı maddeler (IATA yönetmeliği tarafından belirlenmiş)\n- İnsan beden parçaları veya külleri\n- Mücevherler, değerli metaller ve değerli taşlar\n- Narkotik maddeler (yasa dışı)",

            faqlist1: "FAQ",
            faqlist2: "FAQ Oluştur",
            faqlist3: "ID",
            faqlist4: "Soru başlığı",
            faqlist5: "Soru cevabı",
            faqlist6: "Status",

            faqcreatepart1: "FAQ oluşturma formu",
            faqcreatepart2: "Soru başlığı",
            faqcreatepart3: "Soru cevabı",
            faqcreatepart4: " FAQ oluştur",
            faqcreatepart5: "FAQ düzenleme formu",
            faqcreatepart6: "Değişiklikleri Kaydet",

            editshippingspart0: "Gönderi Verilerini Düzenle",
            editshippingspart1: "ID",
            editshippingspart2: "Öde",
            editshippingspart3: "Durum",
            editshippingspart4: "Etiket",
            editshippingspart5: "Alıcı",
            editshippingspart6: "Sipariş numarası",
            editshippingspart7: "Ülke:",
            editshippingspart8: "Adet",
            editshippingspart9: "Gümrük değeri",
            editshippingspart10: "Tarih",
            editshippingspart11: "Fatura Yükle",
            editshippingspart12: "Dosya seçin",
            editshippingspart13: "Güncelle",
            editshippingspart14: "İptal et",

            profilepartprimarytitle: "PROFİL",
            profilepartname: "Isim",
            profilepartsurname: "Soyisim",
            profilepartemail: "E-posta",
            profilepartphone: "Telefon",
            profilepartprimarybtn: "Güncelle",
            profilepartsecondarytitle: "Güvenlik",
            profilepartoldpassword: "Eski Şifre",
            profilepartnewpassword: "Yeni Şifre",
            profilepartconfirmpassword: "Şifreyi Onayla",
            profilepartsecondarybtn: "Şifreyi Değiştir",

            agentpart1: "Müşteriler",
            agentpart2: "Müşteri oluştur",
            agentpart3: "Müşteri adı",
            agentpart4: "Abonelik tipi",
            agentpart5: "Vergi numarası",

            agentcreatepart1: "Müşteri oluştur formu",
            agentcreatepart2: "Müşteri adı",
            agentcreatepart3: "Müşteri soyadı",
            agentcreatepart4: "Şirket adı",
            agentcreatepart5: "Ülke",
            agentcreatepart6: "İlçe",
            agentcreatepart7: "Posta kodu",
            agentcreatepart8: "Adres",
            agentcreatepart9: "Telefon numarası",
            agentcreatepart10: "Vergi numarası",
            agentcreatepart11: "Vergi dairesi",
            agentcreatepart12: "Mevcut bakiye",
            agentcreatepart13: " Mecut paket bakiyesi",
            agentcreatepart14: "Müşteri oluştur",

            subscriptionlistpart1: "Abonelikler",
            subscriptionlistpart2: "Abonelik oluştur",
            subscriptionlistpart3: "ID",
            subscriptionlistpart4: "Müşteri ID",
            subscriptionlistpart5: "Paket ID",
            subscriptionlistpart6: "Ödenen tutar",
            subscriptionlistpart7: "Başlangıç tarihi",
            subscriptionlistpart9: "Müşteri adı",
            subscriptionlistpart10: "Paket adı",
            subscriptionlistpart11: "İşlem numarası",
            subscriptionlistpart12: "Bitme tarihi",

            subscriptioncreatepart1: "Abonelik oluştur formu",
            subscriptioncreatepart2: " Paket ID",
            subscriptioncreatepart3: "Paket tutarı",
            subscriptioncreatepart4: "Abonelik tipi",
            subscriptioncreatepart5: "Aboneliği oluştur",
            subscriptioncreatepart6: "Abonelik düzenle formu",
            subscriptioncreatepart7: "Değişiklikleri kaydet",

            packagelistpart1: "Paketler",
            packagelistpart2: "Paket oluştur",
            packagelistpart3: "ID",
            packagelistpart4: "Paket adı",
            packagelistpart5: "Paket fiyatı",
            packagelistpart6: "Paket balans",
            packagelistpart7: "Paket süresi",
            packagelistpart8: "Başlangıç tarihi",

            packagecreatepart1: "Paket oluştur formu",
            packagecreatepart2: "Paket adı",
            packagecreatepart3: "Paket fiyatı",
            packagecreatepart4: "Paket balans",
            packagecreatepart6: "Paket süresi",
            packagecreatepart7: "Paket indirimi",
            packagecreatepart8: "Paketi kaydet",
            packagecreatepart9: "Paket düzenle formu",
            packagecreatepart10: "Paketi düzenle",

            providerlistpart1: "Tedarikçiler",
            providerlistpart2: "Tedarikçi oluştur",
            providerlistpart3: "İsim",
            providerlistpart4: "Ülke",
            providerlistpart5: "Adres",
            providerlistpart6: "TedariKçi düzenle formu",
            providerlistpart45: "Durum",
            providerlistpart7: "Değişiklikleri kaydet",
            providerlistpart8: "Tedarikçi oluştur  formu",
            providerlistpart9: "Tedarikçi düzenle",
            providerlistpart10: "Ülke",
            providerlistpart11: "İl",
            providerlistpart12: "Postal kod",
            providerlistpart13: "Kanuni Adres",
            providerlistpart14: "Adres",
            providerlistpart15: "Telefon numarası",
            providerlistpart16: "Ödeme",
            providerlistpart17: "Zaman",

            countrylistpart1: "Ülkeler",
            countrylistpart2: "Ülke oluştur",
            countrylistpart3: "ID",
            countrylistpart4: "Ülke adı",
            countrylistpart5: "ISO",
            countrylistpart6: "Tedarikçi",
            countrylistpart7: "Ödeme",
            countrylistpart8: "Status",

            dashboardlistpart1: "Bekleyen gönderiler",
            dashboardlistpart2: "Tüm gönderiler",
            dashboardlistpart3: "Yolda olan gönderiler",
            dashboardlistpart4: "Teslim edilen gönderiler",
            dashboardlistpart5: "İptal edilen gönderiler",

            adminsidebarpart1: "Anasayfa",
            adminsidebarpart2: "Kullanıcı",
            adminsidebarpart3: "Gönderiler",
            adminsidebarpart4: "Müşteri listesi",
            adminsidebarpart5: "Tedarikçi listesi",
            adminsidebarpart6: "SSS",
            adminsidebarpart7: "Duyurular",
            adminsidebarpart8: "Ödemeler",
            adminsidebarpart9: "Abuneler",
            adminsidebarpart10: "Paketler",
            adminsidebarpart11: "Ülkeler",

            agentsidebarpart1: "Ana sayfa",
            agentsidebarpart2: "Kullanıcı",
            agentsidebarpart3: "Müşteri bilgileri",
            agentsidebarpart4: "Gönderi oluştur",
            agentsidebarpart5: "Gönderiler",
            agentsidebarpart6: "Fiyat hesapla",
            agentsidebarpart7: "SSS",
            agentsidebarpart8: "Ödemeler",
            agentsidebarpart9: "Çıkış yap",

            providersidebarpart1: "Ana sayfa",
            providersidebarpart2: "Kullanıcı",
            providersidebarpart3: "Gönderiler",
            providersidebarpart4: "SSS",
            providersidebarpart5: "Duyurular",
            providersidebarpart6: "Destek",

            tablepart1: "İşlemler",

            orderinputplaceholder1: "İsminizi yazınız",
            orderinputplaceholder2: "Soyisminizi yazınız",
            orderinputplaceholder3: "E-posta adresinizi yazınız",
            orderinputplaceholder4: "Ülkenizi seçiniz",
            orderinputplaceholder5: "Şehrinizi yazınız",
            orderinputplaceholder6: "İlçenizi yazınız",
            orderinputplaceholder7: "Adresinizi yazınız",
            orderinputplaceholder8: "Posta kodunuzu yazınız",
            orderinputplaceholder9: "Açıklama yazınız",
            orderinputplaceholder10: "Ürün adı",
            orderinputplaceholder11: "Ürün kategorisi",
            orderinputplaceholder12: "Ürün adetini yazınız",
            orderinputplaceholder13: "Ürün gümrük değerini yazınız",
            orderinputplaceholder14: "Ürün GTİP numarasını yazınız",
            orderinputplaceholder15: "Ürün ağırlığını yazınız",
            orderinputplaceholder16: "Ürün enini yazınız",
            orderinputplaceholder17: "Ürün boyunu yazınız",
            orderinputplaceholder18: "Ürün yüksekliğini yazınız",
            orderinputplaceholder19: "Telefon numaranızı yazınız",
            orderinputplaceholder20: "Hesapla",
            orderinputplaceholder21: "Fiyat",
            orderinputplaceholder22: "Müşterini seçiniz",
            agentinputplaceholder1: "Müşteri adını yazınız",
            agentinputplaceholder2: "Müşteri soyadını yazınız",
            agentinputplaceholder3: "Vergi numarasını yazınız",
            agentinputplaceholder4: "Vergi dairesini yazınız",
            agentinputplaceholder5: "Mevcut bakiyeyi yazınız",
            agentinputplaceholder6: "Mevcut paket bakiyesini yazınız",
            agentinputplaceholder7: "Şirket adını yazınız",
            agentcreatepart15: "Abonelik tipi",
            agentcreatepart16: "Müşteri düzenle formu",

            subscriptionhometitle: "Kavvay Fiyatlandırma",
            subscriptionhomeprcingmethod: "Yıllık",
            subscriptionhomecontact: "Özel teklif",
            subscriptionhomecontact2: "Paket seçin",
            subscriptionhomecontact3: "BİZE YAZIN",

            subscriptiondetails1: "Abune detayları:",
            subscriptiondetails2: "Yıllık fiyat:",
            subscriptiondetails3: "Paket balansı:",
            subscriptiondetails4: "Başlama tarihi:",
            subscriptiondetails5: "Bitme tarihi:",
            subscriptiondetails6: "Uygun",

            providerinputplaceholder1: "Tedarikçi adını yazınız",
            providerinputplaceholder2: "Tedarikçi numarasını yazınız",
            providerinputplaceholder3: "Tedarikçi kanuni adresini yazıfnız",
            providerinputplaceholder4: "Tedarikçi anbar adresini yazınız",
            providerinputplaceholder5: "Tedarikçi kargo parasını yazınız",
            providerinputplaceholder6: "Tedarikçi teslimat aralığını yazınız",

            providerpricingcolumn1: "Name",
            providerpricingcolumn2: "From",
            providerpricingcolumn3: "To",
            providerpricingcolumn4: "Price",
            providerpricingcolumn5: "Price list",
            providerpricingcolumn6: "Add price",
            providerpricingcolumn7: "PerKgCalc",


            registerinputplaceholder1: "Parolanızı yazınız",
            registerinputplaceholder2: "Tekrar parolanızı yazınız",

            clickandshoppart1: "Yurtdışına gönderilerini tek panelde yönet",
            clickandshoppart2: "Kolay ve çoklu entegrasyon",
            clickandshoppart3:
              "Farklı kurye şirketlerine kısa sürede entegre ol",
            clickandshoppart4: "Özel Demo Talep Edin",

            updatelocalcargomodalheader: "Gönderi Güncelle",
            updatelocalcargoname: "İsim",
            updatelocalcargotrackingnumber: "Takip numarası",
            updatelocalcargonameplaceholder: "İsminizi yazınız",
            updatelocalcargotrackingnumberplaceholder:
              "Takip numarasını yazınız",
            shippingfilter1: "Ülke seçin",
            shippingfilter2: "Durum",

            alert1: "Kullanıcı adınız onaylanmamıştır.",
            alert2: "Müşteri hesabınız hala onaylanmamıştır",

            faqquestionpart1: "Kavvay nedir?",
            faqanswerpart1:
              "Kavvay, Türkiye’den yurt dışına satış yapan e-ticaret sitelerinin kullana bileceği E-gönderi yazılım hizmetidir. Size özel Kavvay hesabı üzerinden siparişlerinizi ve gönderilerinizi yöneterek, Kavvay E-Gönderi yazılım teknolojisi üzerinden gönderilerinizi yurtdışındakı müşterilerinize ulaşmasını sağlaya bilirsiniz.",
            faqquestionpart2: "Kavvay’yı kullanmak ücretli mi?",
            faqanswerpart2:
              "Sipariş gönderimini kendi kargo anlaşmalarıyla yapan, sadece Kavvay yazılım ve entegrasyon hizmetini kullanmak isteyen müşterilerimiz için küçük bir ücretlendirme yapmaktayız. Ücret hesaplama ve daha detaylı bilgi için Fiyatlandırma sayfasını ziyaret ede bilirsiniz.",
            faqquestionpart3: "Kavvay fiyat hesaplama nasıl çalışır? ",
            faqanswerpart3:
              "Kavvay hesabınızdan Fiyat hespalama menüsüne geçiş yapa bilirsiniz. Geçiş yaptığınız sayfada gönderinizin en, boy ve yükseklik ve ağırlık bilgilerini girildikten sonra ülke seçimi yaparak gönderi ücretini göre bilirsiniz. Sipariş gönderilerinin ağırlığı hesaplanırken fiziksel ağırlık ve hacimsel ağırlık hesaplanıyor. İki ağırlıktan yüksek olan ücretlendirilecek ağırlıktır.\n Hacimsel ağırlık (desi ağırlığı); gönderinin en, boy ve yükseklik değerlerinin birbiri ile çarpılmasının 5000 ile bölünmesi ile alınır. (En * Boy * Yükseklik) / 5000 = Hacimsel ağırlık.",
            faqquestionpart4:
              "Mağazalarımı entegre etmeden sadece gönderi yapa bilirmiyim?",
            faqanswerpart4:
              "Mağazalarınızı entegre etmeden gönderi yapa bilirsiniz. Tüm siparişleriniz için ayrılıkta gönderi oluşturmanız gerekmektedir.",
            faqquestionpart5: "Kavvay ile hangi bilgiler paylaşılmaktadır?",
            faqanswerpart5:
              "Kavvay; tüm pazar yerlerinin izni ve bilgisi dahilinde müşteri adı, adresi, varsa telefon numarası, ürün içeriği ve fiyatına erişebiliyor. Bu bilgiler kolay gönderi oluşturulması için gereklidir.",
            faqquestionpart6: "Kavvay paketlerini nasıl ala bilirim?",
            faqanswerpart6:
              "Kavvay-a üye olduktan sonra size uygun paketi için ödeme yaparak aktif kullanmaya başlaya bilirsiniz.",
            faqquestionpart7: "Kargolarımı Kavvay'ya nasıl gönderebilirim?",
            faqanswerpart7:
              "Kavvay hesabınızdan gönderileriniz için etiketlerinizi oluşturduktan sonra siparişlerinizin üzerine Kavvay etiketlerinizi ekleyerek gönderime hazır ede bilirsiniz. Etiketde gösterilen adrese anlaşmalı kargolarınızla göndere bilirsiniz.",
            faqquestionpart8: "Kargo takip nasıl yapa bilirim?",
            faqanswerpart8:
              "Gönderileriniz için hazırladığınız etiketle basılan sipariş no üzerinden gönderilerinizin hareket durumunu hesabınızdan yenilenecektir.",
            faqquestionpart9:
              "Gönderim gümrüğe işlemleri sırasında çıkışına izin verilmeye bilirmi?",
            faqanswerpart9:
              "Gönderileriniz Kavvay ofisinden çııktıktan sonra müşteriye teslim edilmesine kadar tüm operasyon bizim tarafımızdan yönetiliyor. Her bir ülkenin gümrük prosedürü ve gümrük limitleri olabilmektedir. Örnek: Avrupa Birliği için sınır 22 Euro, ABD için 800 USD’dır, Azerbaycan için aylık 300 USD, Özbekistan için her çeyrek için 1000 USD-dir. Gönderinizin değeri bu tutarları aştığı takdirde alıcınızın gümrük vergisi ödemesi gerekiyor. Alıcı gümrük vergisin ödemediği zaman, bu verginin sizin tarafınızdan ödenilmesi gerektiğini iletmiş olalım.",
            faqquestionpart10:
              "Ağır ve hacimli gönderilerde sınırlamalar var mı?",
            faqanswerpart10:
              "Gönderinin ağırlığı, hacimsel ağırlığı ve ebatları olmak üzere sınırlamalar uygulanmaktadır. Paketler en fazla 30 kg olabilir.",
            faqquestionpart11:
              "Yurt dışına gönderilerinde hangi bilgiler gereklidir?",
            faqanswerpart11:
              "Türkiye’den yurt dışına yapacağınız gönderilerde, paketler gümrük işlemlerine tabi tutulacağı için gönderiye ait bazı bilgilerin esksiksiz doldurulması gerekli.",
            faqquestionpart12: "Gönderilmesi yasak olan ürünler hangileridir?",
            faqanswerpart12:
              "- Canlı hayvan\n- Antikalar (hassas ve/veya kırılabilir)\n- Asbest/Amyant\n- Değerli madenler, külçe olarak\n- Nakit para\n- Ateşli silahlar ve bunların parçaları ve mühimmat\n- Tehlikeli veya yanıcı maddeler (IATA yönetmeliği tarafından belirlenmiş)\n- İnsan beden parçaları veya külleri\n- Mücevherler, değerli metaller ve değerli taşlar\n- Narkotik maddeler (yasa dışı)",
          },
        },
      },
    },
  });

export default i18n;
