import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  subscriptionDetails: null,
  subscriptionDetailsLoading: false,
  subscriptionDetailsError: null,
  subscriptionUpdate: null,
  subscriptionUpdateLoading: false,
  subscriptionUpdateError: null,
  subscriptionDelete: null,
  subscriptionDeleteLoading: false,
  subscriptionDeleteError: null,
  subscriptionCreate: null,
  subscriptionCreateLoading: false,
  subscriptionCreateError: null,
  subscriptionCreatePayment: null,
  subscriptionCreatePaymentLoading: false,
  subscriptionCreatePaymentError: null,
};

export const subscriptionList = createAsyncThunk(
  "subscription/subscriptionList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Subscription/GetSubscription`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const subscriptionDetails = createAsyncThunk(
  "subscription/subscriptionDetails",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Subscription/GetSubscription?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const subscriptionUpdate = createAsyncThunk(
  "subscription/subscriptionUpdate",
  async ({ updatedSubscription }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Subscription/UpdateSubscription`,
        updatedSubscription,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// export const subscriptionDelete = createAsyncThunk(
//   "subscription/subscriptionDelete",
//   async ({ id }: { id: number }, { getState, rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.delete(
//         `${API_URL}api/subscriptions/delete/${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error: any) {
//       console.log(rejectWithValue(error.response.data));
//     }
//   }
// );

export const subscriptionCreate = createAsyncThunk(
  "subscription/subscriptionCreate",
  async ({ createdSubscription }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Subscription/CreateSubscription`,
        createdSubscription,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscriptionList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(subscriptionList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(subscriptionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(subscriptionDetails.pending, (state) => {
        state.subscriptionDetailsLoading = true;
        state.subscriptionDetailsError = null;
      })
      .addCase(subscriptionDetails.fulfilled, (state, action) => {
        state.subscriptionDetailsLoading = false;
        state.subscriptionDetails = action.payload;
      })
      .addCase(subscriptionDetails.rejected, (state, action) => {
        state.subscriptionDetailsLoading = false;
        state.subscriptionDetailsError = action.payload;
      })
      .addCase(subscriptionCreate.pending, (state) => {
        state.subscriptionCreateLoading = true;
        state.subscriptionCreateError = false;
      })
      .addCase(subscriptionCreate.fulfilled, (state, action) => {
        state.subscriptionCreateLoading = false;
        state.subscriptionCreateSuccess = true;
      })
      .addCase(subscriptionCreate.rejected, (state, action) => {
        state.subscriptionCreateLoading = false;
        state.subscriptionCreateError = true;
      })
      .addCase(subscriptionUpdate.pending, (state) => {
        state.subscriptionUpdateLoading = true;
        state.subscriptionUpdateError = false;
      })
      .addCase(subscriptionUpdate.fulfilled, (state, action) => {
        state.subscriptionUpdateLoading = false;
        state.subscriptionUpdateSuccess = true;
      })
      .addCase(subscriptionUpdate.rejected, (state, action) => {
        state.subscriptionUpdateLoading = false;
        state.subscriptionUpdateError = true;
      });
  },
});

// Export actions and reducer
export const subscriptionActions = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
