import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import Loading from "../partials/Loader";
import successgif from "../../../assets/icons/paymentsuccess.jpg";
import failgif from "../../../assets/icons/paymentfail.jpg";
import pendinggif from "../../../assets/icons/paymentpending.jpg";
const PaymentStatus = () => { 
  const navigate = useNavigate();
  const { result } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [paymentResult, setPaymentResult] = useState("pending");

  useEffect(() => {
    // Set the payment result based on the parameter
    if (result === "success" || result === "fail" || result === "pending") {
      setPaymentResult(result);
    } else {
      // Default to 'pending' for unknown values
      setPaymentResult("pending");
    }

    // Simulate a 5-second loading time
    const loadingTimeout = setTimeout(() => {
      // Set the redirect flag to true after 5 seconds
      setRedirect(true);
    }, 5000);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(loadingTimeout);
  }, [result]);

  // Redirect to "/dashboard" when the flag is true
  useEffect(() => {
    if (redirect) {
      navigate("/dashboard");
    }
  }, [redirect, navigate]);

  return (
    <div>
      {paymentResult === "success" && (
        <div className="w-screen h-screen flex flex-col items-center justify-center text-center">
          <img src={successgif} alt="img" />
          <h2 className="font-bold">
            Payment was successfully processed. Thank you for your purchase.
          </h2>
        </div>
      )}
      {paymentResult === "fail" && (
        <div className="w-screen h-screen flex flex-col items-center justify-center text-center">
          <img src={failgif} alt="img" />
          <h2 className="font-bold">Payment failed. Please try again.</h2>
        </div>
      )}
      {paymentResult === "pending" && (
        <div className="w-screen h-screen flex flex-col items-center justify-center text-center">
          <img src={pendinggif} alt="img" />
          <h2 className="font-bold">
            Payment is pending. Please wait for the confirmation email.
          </h2>
        </div>
      )}{" "}
    </div>
  );
};

export default PaymentStatus;
