import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import announcementReducer from './slices/announcementSlice';
import faqReducer from './slices/faqSlice';
import orderReducer from "./slices/orderSlice";
import userReducer from "./slices/userSlice";
import agentReducer from "./slices/agentSlice";
import packageReducer from "./slices/packageSlice";
import subscriptionReducer from "./slices/subscriptionSlice";
import providerReducer from "./slices/providerSlice";
import subscribeReducer from "./slices/subscribeSlice";
import paymentReducer from "./slices/paymentSlice";
import countryReducer from "./slices/countrySlice";
import contactReducer from "./slices/contactSlice";
import fileReducer from "./slices/fileSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    announcement: announcementReducer,
    faq: faqReducer,
    order: orderReducer,
    user: userReducer,
    agent: agentReducer,
    package: packageReducer,
    subscription: subscriptionReducer,
    provider: providerReducer,
    subscribe: subscribeReducer,
    payment: paymentReducer,
    country: countryReducer,
    contact: contactReducer,
    file: fileReducer,
  },
});

export default store;