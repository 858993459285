import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsUser } from "../../../redux/slices/userSlice";
import { GiHamburgerMenu } from "react-icons/gi";
import profilePhoto from "../../../assets/images/fakeprofile.jpg";
import { useTranslation } from "react-i18next";
const MobileHeader = ({ sidebarOpen, setSidebarOpen }) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);

  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleDropdownChange = (event) => {
    const selectedLanguage = event.target.value;
    setSelectedLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  const availableLanguages = {
    en: "EN",
    tr: "TR",
  };

  return (
    <div className="flex justify-between items-center px-6 p-4 bg-[#fff] sidebarshadow">
      <div
        className="lg:hidden flex"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <GiHamburgerMenu className="text-[28px] text-[#000] cursor-pointer" />
      </div>
      <div className="flex flex-1 justify-end items-center flex-end  gap-4">
        <div className="block">
          <div className="relative">
            <select
              value={selectedLanguage}
              onChange={(e) => handleDropdownChange(e)}
              className="cursor-pointer flex border-[1px] border-slate-300 rounded-[5px] px-4 py-[4px] text-[14px]  outline-indigo-400"
            >
              {Object.keys(availableLanguages).map((languageCode) => (
                <option
                  key={languageCode}
                  value={languageCode}
                  className="cursor-pointer border-[1px] border-slate-300"
                >
                  {availableLanguages[languageCode]}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="lg:block hidden cursor-pointer">
          <svg
            className="fill-current duration-300 ease-in-out"
            width="20"
            height="20"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1999 14.9343L15.6374 14.0624C15.5249 13.8937 15.4687 13.7249 15.4687 13.528V7.67803C15.4687 6.01865 14.7655 4.47178 13.4718 3.31865C12.4312 2.39053 11.0812 1.7999 9.64678 1.6874V1.1249C9.64678 0.787402 9.36553 0.478027 8.9999 0.478027C8.6624 0.478027 8.35303 0.759277 8.35303 1.1249V1.65928C8.29678 1.65928 8.24053 1.65928 8.18428 1.6874C4.92178 2.05303 2.4749 4.66865 2.4749 7.79053V13.528C2.44678 13.8093 2.39053 13.9499 2.33428 14.0343L1.7999 14.9343C1.63115 15.2155 1.63115 15.553 1.7999 15.8343C1.96865 16.0874 2.2499 16.2562 2.55928 16.2562H8.38115V16.8749C8.38115 17.2124 8.6624 17.5218 9.02803 17.5218C9.36553 17.5218 9.6749 17.2405 9.6749 16.8749V16.2562H15.4687C15.778 16.2562 16.0593 16.0874 16.228 15.8343C16.3968 15.553 16.3968 15.2155 16.1999 14.9343ZM3.23428 14.9905L3.43115 14.653C3.5999 14.3718 3.68428 14.0343 3.74053 13.6405V7.79053C3.74053 5.31553 5.70928 3.23428 8.3249 2.95303C9.92803 2.78428 11.503 3.2624 12.6562 4.2749C13.6687 5.1749 14.2312 6.38428 14.2312 7.67803V13.528C14.2312 13.9499 14.3437 14.3437 14.5968 14.7374L14.7655 14.9905H3.23428Z"
              fill=""
            />
          </svg>{" "}
        </div>

        <div className="flex gap-2">
          <div className="flex flex-col text-right">
            <h4>{user?.name}</h4>
            <p className="text-[12px] opacity-[0.6]">{user?.email}</p>
          </div>
          <div className="bg-slate-50 rounded-[50%] w-12 h-12 flex items-center justify-center border-[2px] border-indigo-400 text-[24px] font-bold">
            <img src={profilePhoto} alt="profile" className="rounded-[50%]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
