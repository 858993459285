import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  providerDetails: null,
  providerDetailsLoading: false,
  providerDetailsError: null,
  providerUpdate: null,
  providerUpdateLoading: false,
  providerUpdateError: null,
  providerUpdateStatusSuccess: null,
  providerUpdateStatusLoading: false,
  providerUpdateStatusError: null,
  providerDelete: null,
  providerDeleteLoading: false,
  providerDeleteError: null,
  providerCreate: null,
  providerCreateLoading: false,
  providerCreateError: null,
};

export const providerList = createAsyncThunk(
  "provider/providerList",
  async ({countryId}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Provider/GetProviders?countryId=${countryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const providerDetails = createAsyncThunk(
  "provider/providerDetails",
  async ({ id }, {rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Provider/GetProviders?providerId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data[0];
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const providerUpdate = createAsyncThunk(
  "provider/providerUpdate",
  async ({updatedProvider }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Provider/UpdateProvider`,
        updatedProvider,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const providerUpdateStatus = createAsyncThunk(
  "provider/providerUpdateStatus",
  async ({updatedProvider }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Provider/UpdateProvider`,
        updatedProvider,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const providerDelete = createAsyncThunk(
  "provider/providerDelete",
  async ({ deletedProvider }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Provider/UpdateProvider`,
        deletedProvider,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);


export const providerCreate = createAsyncThunk(
  "provider/providerCreate",
  async ({ provider }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Provider/AddProvider`,
        provider,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const providerSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(providerList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(providerList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(providerList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(providerDetails.pending, (state) => {
        state.providerDetailsLoading = true;
        state.providerDetailsError = null;
      })
      .addCase(providerDetails.fulfilled, (state, action) => {
        state.providerDetailsLoading = false;
        state.providerDetails = action.payload;
      })
      .addCase(providerDetails.rejected, (state, action) => {
        state.providerDetailsLoading = false;
        state.providerDetailsError = action.payload;
      })
      .addCase(providerCreate.pending, (state) => {
        state.providerCreateLoading = true;
        state.providerCreateError = false;
      })
      .addCase(providerCreate.fulfilled, (state, action) => {
        state.providerCreateLoading = false;
        state.providerCreateSuccess = true;
      })
      .addCase(providerCreate.rejected, (state, action) => {
        state.providerCreateLoading = false;
        state.providerCreateError = true;
      })
      .addCase(providerUpdateStatus.pending, (state) => {
        state.providerUpdateStatusLoading = true;
        state.providerUpdateStatusError = false;
      })
      .addCase(providerUpdateStatus.fulfilled, (state, action) => {
        state.providerUpdateStatusLoading = false;
        state.providerUpdateStatusSuccess = true;
      })
      .addCase(providerUpdateStatus.rejected, (state, action) => {
        state.providerUpdateStatusLoading = false;
        state.providerUpdateStatusError = true;
      })
      .addCase(providerDelete.pending, (state) => {
        state.providerDeleteLoading = true;
        state.providerDeleteError = false;
      })
      .addCase(providerDelete.fulfilled, (state, action) => {
        state.providerDeleteLoading = false;
        state.providerDeleteSuccess = true;
      })
      .addCase(providerDelete.rejected, (state, action) => {
        state.providerDeleteLoading = false;
        state.providerDeleteError = true;
      });
  },
});

// Export actions and reducer
export const providerActions = providerSlice.actions;
export default providerSlice.reducer;
