import React, { useEffect, useState} from "react";
import { useNavigate, Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { detailsUser } from "../../../redux/slices/userSlice";
import logo from "../../../assets/logo/kavvay_new_logo.svg";
import secondaryLogo from "../../../assets/logo/kavvay_secondary_logo.png";
import { agentLinks, adminLinks, providerLinks } from "../../../utils/sidebarData";
const Sidebar = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [sidebarOpen, setSidebarOpen] = useState(true);


  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogOut = () => {
    localStorage.removeItem("token");  
    window.location.href = "/login";
};

  const handleNavLinkClick = (route) => {
    navigate(route);
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0; // Fallback for older browsers
    }
  };

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);

  return (
    <>
      <div
        className={`${
          sidebarOpen
            ? "w-[20%] lg:block hidden block h-screen z-50 "
            : "w-[5%] lg:block hidden block h-screen z-50 "
        }`}
      >
        <div className="w-[100%] bg-[#fff] sidebarshadow max-h-screen lg:h-screen overflow-y-auto z-50 transform translate-x-0 transition-transform duration-300 ease-in-out">
          <div
            className={`${
              !sidebarOpen && "justify-center"
            } flex items-center px-4 pt-6`}
          >
            <Link exact to="/">
              <img
                src={logo}
                alt=""
                className={`${
                  sidebarOpen ? "block" : "hidden"
                } font-normal w-[120px]`}
              />
              <img
                src={secondaryLogo}
                alt=""
                className={`${
                  sidebarOpen ? "hidden" : "block"
                } font-normal w-[30px] h-[30px]`}
              />
            </Link>
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <ul className="px-[10px] lg:px-[15px] py-[15px]">
              {user && user.userRole === "admin" && (
                <>
                  {adminLinks.map((item, key) => (
                    <li
                      key={key}
                      className={`${
                        !sidebarOpen && "justify-center"
                      } flex gap-4  items-center cursor-pointer hover:bg-indigo-100 py-2 rounded-[5px] hover:text-[#3C50E0]`}
                      onClick={() => handleNavLinkClick(item?.url)}
                    >
                      <div
                        className={`${sidebarOpen ? "" : ""} p-2 rounded-[50%]`}
                      >
                        <svg
                          className="fill-current"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.10322 0.956299H2.53135C1.5751 0.956299 0.787598 1.7438 0.787598 2.70005V6.27192C0.787598 7.22817 1.5751 8.01567 2.53135 8.01567H6.10322C7.05947 8.01567 7.84697 7.22817 7.84697 6.27192V2.72817C7.8751 1.7438 7.0876 0.956299 6.10322 0.956299ZM6.60947 6.30005C6.60947 6.5813 6.38447 6.8063 6.10322 6.8063H2.53135C2.2501 6.8063 2.0251 6.5813 2.0251 6.30005V2.72817C2.0251 2.44692 2.2501 2.22192 2.53135 2.22192H6.10322C6.38447 2.22192 6.60947 2.44692 6.60947 2.72817V6.30005Z"
                            fill=""
                          />
                          <path
                            d="M15.4689 0.956299H11.8971C10.9408 0.956299 10.1533 1.7438 10.1533 2.70005V6.27192C10.1533 7.22817 10.9408 8.01567 11.8971 8.01567H15.4689C16.4252 8.01567 17.2127 7.22817 17.2127 6.27192V2.72817C17.2127 1.7438 16.4252 0.956299 15.4689 0.956299ZM15.9752 6.30005C15.9752 6.5813 15.7502 6.8063 15.4689 6.8063H11.8971C11.6158 6.8063 11.3908 6.5813 11.3908 6.30005V2.72817C11.3908 2.44692 11.6158 2.22192 11.8971 2.22192H15.4689C15.7502 2.22192 15.9752 2.44692 15.9752 2.72817V6.30005Z"
                            fill=""
                          />
                          <path
                            d="M6.10322 9.92822H2.53135C1.5751 9.92822 0.787598 10.7157 0.787598 11.672V15.2438C0.787598 16.2001 1.5751 16.9876 2.53135 16.9876H6.10322C7.05947 16.9876 7.84697 16.2001 7.84697 15.2438V11.7001C7.8751 10.7157 7.0876 9.92822 6.10322 9.92822ZM6.60947 15.272C6.60947 15.5532 6.38447 15.7782 6.10322 15.7782H2.53135C2.2501 15.7782 2.0251 15.5532 2.0251 15.272V11.7001C2.0251 11.4188 2.2501 11.1938 2.53135 11.1938H6.10322C6.38447 11.1938 6.60947 11.4188 6.60947 11.7001V15.272Z"
                            fill=""
                          />
                          <path
                            d="M15.4689 9.92822H11.8971C10.9408 9.92822 10.1533 10.7157 10.1533 11.672V15.2438C10.1533 16.2001 10.9408 16.9876 11.8971 16.9876H15.4689C16.4252 16.9876 17.2127 16.2001 17.2127 15.2438V11.7001C17.2127 10.7157 16.4252 9.92822 15.4689 9.92822ZM15.9752 15.272C15.9752 15.5532 15.7502 15.7782 15.4689 15.7782H11.8971C11.6158 15.7782 11.3908 15.5532 11.3908 15.272V11.7001C11.3908 11.4188 11.6158 11.1938 11.8971 11.1938H15.4689C15.7502 11.1938 15.9752 11.4188 15.9752 11.7001V15.272Z"
                            fill=""
                          />
                        </svg>
                      </div>
                      <h3
                        className={`${
                          sidebarOpen ? "block" : "hidden"
                        } font-normal`}
                      >
                        <Trans
                          i18nKey={`description.adminsidebarpart${key + 1}`}
                        >
                          {item?.text}
                        </Trans>
                      </h3>
                    </li>
                  ))}
                </>
              )}

              {user && user.userRole === "agentuser" && (
                <>
                  {agentLinks.map((item, key) => (
                    <li
                      key={key}
                      className={`${
                        !sidebarOpen && "justify-center"
                      } flex gap-4  items-center cursor-pointer hover:bg-indigo-100 py-2 rounded-[5px] hover:text-[#3C50E0]`}
                      onClick={() => handleNavLinkClick(item?.url)}
                    >
                      <div
                        className={`${sidebarOpen ? "" : ""} p-2 rounded-[50%]`}
                      >
                        {item?.icon}
                      </div>
                      <h3
                        className={`${
                          sidebarOpen ? "block" : "hidden"
                        } font-normal`}
                      >
                        <Trans
                          i18nKey={`description.agentsidebarpart${key + 1}`}
                        >
                          {item?.text}
                        </Trans>
                      </h3>
                    </li>
                  ))}
                </>
              )}

              {user && user.userRole === "provideruser" && (
                <>
                  {providerLinks.map((item, key) => (
                    <li
                      key={key}
                      className="flex gap-4 items-center cursor-pointer  hover:bg-indigo-500 py-2 rounded-[5px] hover:text-white"
                      onClick={() => handleNavLinkClick(item?.url)}
                    >
                      <div className="p-2 rounded-[50%] hover:bg-indigo-500">
                        {item?.icon}
                      </div>
                      <h3 className="font-normal">
                        <Trans
                          i18nKey={`description.providersidebarpart${key + 1}`}
                        >
                          {item?.text}
                        </Trans>
                      </h3>
                    </li>
                  ))}
                </>
              )}

              <div
                className={`${
                  sidebarOpen ? "w-[250px]" : "w-[50px]"
                } absolute bottom-[2%]`}
              >
                <hr className="w-[100%]" />

                <li
                  className={`${
                    !sidebarOpen && "justify-center"
                  } flex gap-4 items-center cursor-pointer hover:bg-indigo-100 py-2 rounded-[5px] hover:text-[#3C50E0]`}
                  onClick={() => handleLogOut()}
                >
                  <div className="p-2 rounded-[50%]">
                    <svg
                      className="fill-current"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_130_9814)">
                        <path
                          d="M12.7127 0.55835H9.53457C8.80332 0.55835 8.18457 1.1771 8.18457 1.90835V3.84897C8.18457 4.18647 8.46582 4.46772 8.80332 4.46772C9.14082 4.46772 9.45019 4.18647 9.45019 3.84897V1.88022C9.45019 1.82397 9.47832 1.79585 9.53457 1.79585H12.7127C13.3877 1.79585 13.9221 2.33022 13.9221 3.00522V15.0709C13.9221 15.7459 13.3877 16.2802 12.7127 16.2802H9.53457C9.47832 16.2802 9.45019 16.2521 9.45019 16.1959V14.2552C9.45019 13.9177 9.16894 13.6365 8.80332 13.6365C8.43769 13.6365 8.18457 13.9177 8.18457 14.2552V16.1959C8.18457 16.9271 8.80332 17.5459 9.53457 17.5459H12.7127C14.0908 17.5459 15.1877 16.4209 15.1877 15.0709V3.03335C15.1877 1.65522 14.0627 0.55835 12.7127 0.55835Z"
                          fill=""
                        />
                        <path
                          d="M10.4346 8.60205L7.62207 5.7333C7.36895 5.48018 6.97519 5.48018 6.72207 5.7333C6.46895 5.98643 6.46895 6.38018 6.72207 6.6333L8.46582 8.40518H3.45957C3.12207 8.40518 2.84082 8.68643 2.84082 9.02393C2.84082 9.36143 3.12207 9.64268 3.45957 9.64268H8.49395L6.72207 11.4427C6.46895 11.6958 6.46895 12.0896 6.72207 12.3427C6.83457 12.4552 7.00332 12.5114 7.17207 12.5114C7.34082 12.5114 7.50957 12.4552 7.62207 12.3145L10.4346 9.4458C10.6877 9.24893 10.6877 8.85518 10.4346 8.60205Z"
                          fill=""
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_130_9814">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(0 0.052124)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <span
                    className={`${
                      sidebarOpen ? "block" : "hidden"
                    } font-normal`}
                  >
                    <Trans i18nKey="description.agentsidebarpart9"></Trans>
                  </span>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      {sidebarOpen ? (
        <span
          className="cursor-pointer top-[4%] absolute left-[20%] pl-4 lg:block hidden"
          onClick={handleToggleSidebar}
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </span>
      ) : (
        <span
          className="cursor-pointer top-[4%] absolute left-[5%] pl-4 lg:block hidden"
          onClick={handleToggleSidebar}
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: "scaleX(-1)" }}
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
            ``
          </svg>
        </span>
      )}
    </>
  );
};

export default Sidebar;
