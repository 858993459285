import React from "react";
import Form from "../../components/Home/Form";
import Footer from "../../components/Home/Footer";
import Navbar from "../../components/Home/NavBar";
export const Contact = () => {
  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
      <Navbar />
      <div className="w-[100%] mx-auto">
        <Form />
        <div className="Footer">
          <Footer faqID={"/"} supportID={"/contact"} />
        </div>
      </div>
    </div>
  );
};
