// CookieConsent.js
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [showCookieConsent, setShowCookieConsent] = useState(!cookies.cookieConsent);

  const handleAccept = () => {
    setCookie('cookieConsent', true, { path: '/', maxAge: 31536000 }); // 1 year expiration
    setShowCookieConsent(false);
  };

  const handleDismiss = () => {
    setShowCookieConsent(false);
  };

  return (
    showCookieConsent && (
      <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 z-50">
        <div className="max-w-screen-md mx-auto flex items-center justify-between">
          <p className="text-sm">
            We use cookies to enhance your experience. By using our website, you agree to our{' '}
            <a href="/privacypolicy" className="text-indigo-500">
              privacy policy
            </a>
            .
          </p>
          <div className="flex items-center space-x-4">
            <button
              onClick={handleAccept}
              className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded-full"
            >
              Accept
            </button>
            <button onClick={handleDismiss} className="bg-red-500 hover:bg-red-600  text-white px-4 py-2 rounded-full hover:text-white">
            Decline
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
