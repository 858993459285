import { useState, useEffect } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";

const Questions = () => {
  const { i18n } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleAnswer = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="bg-[#f8fafc] my-4 py-8">
      <h4 className="text-center font-bold text-[#475569] text-[20px]">
        <Trans
          i18nKey="description.homepart8"
          className="font-semibold text-[25px]"
        >
          FREQUENTLY ASKED QUESTIONS
        </Trans>
      </h4>
      <div className="flex flex-col mt-4">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((item, index) => (
          <div
            key={index}
            className="flex justify-center self-center flex-col  w-[90%] lg:w-[80%] sidebarshadow bg-white mt-4 p-4 rounded-[10px]"
          >
            <div
              className="flex justify-between  items-center font-bold cursor-pointer"
              onClick={() => toggleAnswer(index)}
            >
              <h4>
                <Trans i18nKey={`description.faqquestionpart${item}`}>
                  question1
                </Trans>
              </h4>
              <div>
                <span className="arrow-icon">
                  {index === expandedIndex ? (
                    <FaArrowUp className="icons" />
                  ) : (
                    <FaArrowDown className="icons" />
                  )}
                </span>
              </div>
            </div>
            {index === expandedIndex && (
              <div className="pt-2 text-[#475569] text-[16px]">
                <h4>
                  <Trans i18nKey={`description.faqanswerpart${item}`}>
                    answer1
                  </Trans>
                </h4>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Questions;
