import React from "react";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import edelivery from "../../assets/images/clickandshop.png";

const EDelivery = () => {
  const { i18n } = useTranslation();
  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
      <Navbar />
      <div className="w-[100%] mx-auto flex items-center justify-between lg:px-[30px] py-[50px]">
        <div className="w-[100%] lg:w-[40%]  lg:text-left">
          <h1 className="text-[40px] pb-2 ">
            <Trans i18nKey="description.clickandshoppart1">
              Multi-carrier shipping software
            </Trans>
          </h1>
          <p className="text-[18px] pb-4">
            <span>✔️</span>
            <Trans i18nKey="description.clickandshoppart2">
              250+ carriers integrated
            </Trans>
          </p>
          <p className="text-[18px]">
            <span>✔️</span>

            <Trans i18nKey="description.clickandshoppart3">
              Go-live with a new carrier in 1 day
            </Trans>
          </p>
          <Link to="/register">
            <button className="bg-indigo-500  text-white  p-4 rounded-[10px] mt-8">
              <Trans i18nKey="description.clickandshoppart4">Request the demo</Trans>
            </button>
          </Link>
        </div>
        <div className="w-[50%]  hidden lg:block">
          <img className="w-[100%]" src={edelivery} alt="" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EDelivery;
