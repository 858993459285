import { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Layout from "./layout/layout";
import Home from "./pages/Home/HeroPage";
import EDelivery from "./pages/Home/E-delivery";
import { Contact } from "./pages/Home/Contact";
import About from "./pages/Home/About";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ResetPassword from "./pages/Auth/ResetPassowrd";
import Dashboard from "./pages/Dashboard/Shared/Home";
import GDPR from "./pages/Home/gdpr";
import TAC from "./pages/Home/Terms";
import Cookiepoicy from "./pages/Home/CookiePolicy";
import Privacypolicy from "./pages/Home/Privacypolicy";
import Homecalprice from "./pages/Home/CalculatePrice";
import { detailsUser } from "./redux/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./partials/Loader";
import NotFound from "./components/Home/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentStatus from "./components/Dashboard/Shared/PaymentStatus";
import Questions from "./pages/Home/FrequentlyAQ";
import CookieConsent from "./components/Home/CookieConsent";
import routes from "./routes/index";


function Protected({ children }) {
  const { user } = useSelector(state => state.user);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
}


const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(detailsUser());
    setTimeout(() => setLoading(false), 1000);
  }, [dispatch]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <ToastContainer />
          <CookieConsent />
          <div>
            <Routes>
              <Route index path="/" exact element={<Home />} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/register" exact element={<Register />} />
              <Route path="/contact" exact element={<Contact />} />
              <Route path="/about" exact element={<About />} />
              <Route path="/calculator" exact element={<Homecalprice />} />
              <Route path="/resetpassword" exact element={<ResetPassword />} />
              <Route path="/edelivery" exact element={<EDelivery />} />
              <Route path="/gdpr" exact element={<GDPR />} />
              <Route path="/privacypolicy" exact element={<Privacypolicy />} />
              <Route path="/termsandconditions" exact element={<TAC />} />
              <Route path="/cookiepolicy" exact element={<Cookiepoicy />} />
              <Route path="/paymentstatus" exact element={<PaymentStatus />} />
              <Route path="/faq" exact element={<Questions />} />

              <Route element={<Layout />}>
                <Route index element={<Dashboard />} />
                {routes.map((route, index) => {
                  const {  path, component: Component } = route;
                  return (
                    <Route
                      key={index}
                      path={path}
                      exact={true}
                      element={
                        <Suspense fallback={<Loader />}>
                          <Protected>
                            <Component />
                          </Protected>
                        </Suspense>
                      }
                    />
                  );
                })}
              </Route>
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      )}
    </>
  );
};

export default App;
