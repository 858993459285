import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: null,
  error: null,
  subscribeCreate: null,
  subscribeCreateLoading: false,
  subscribeCreateError: null,
};

export const subscribeDetails = createAsyncThunk(
  `/subscribe/subscribeDetails`,
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Subscription/SubscriptionDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);


export const subscribeCreate = createAsyncThunk(
  "subscribe/subscribeCreate",
  async ({ subscribe }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/subscribe`,
        subscribe,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const subscribeSlice = createSlice({
  name: "subscribes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(subscribeDetails.pending, (state) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(subscribeDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(subscribeDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
     })
      .addCase(subscribeCreate.pending, (state) => {
        state.subscribeCreateLoading = true;
        state.subscribeCreateError = false;
      })
      .addCase(subscribeCreate.fulfilled, (state, action) => {
        state.subscribeCreateLoading = false;
        state.subscribeCreate = action.payload;
      })
      .addCase(subscribeCreate.rejected, (state, action) => {
        state.subscribeCreateLoading = false;
        state.subscribeCreateError = true;
      });
  },
});

// Export actions and reducer
export const subscribeActions = subscribeSlice.actions;
export default subscribeSlice.reducer;
