  import React, { useState, useEffect } from "react";
  import { Link } from "react-router-dom";
  import { useTranslation, Trans } from "react-i18next";
  import { useNavigate } from "react-router-dom";
  const SubNavbar = () => {
    const { i18n } = useTranslation();
    const [isActive, setActive] = useState(false);
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const navigate = useNavigate();
    const handleFiyatHesaplaClick = (event) => {
      event.preventDefault();
  
      // Check if the current location is not the homepage
      if (window.location.pathname !== "/") {
        // Redirect to the homepage without scrolling
        navigate("/");
      } else {
        // Scroll to the element with id "packages" on the home page
        const element = document.getElementById("packages");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    return (
      <div className="flex lg:flex-row flex-col items-start lg:items-center gap-6 py-4">
        <Link to="/about" activeClassName="active" className="list-none">
          <li
            className="text-[16px] text-[#264653] leading-6 hover:text-indigo-500"
            onClick={scrollToTop}
          >
            <Trans i18nKey="description.navpart1">Hakkımızda</Trans>
          </li>
        </Link>

        <Link to="/edelivery" activeClassName="active" className="list-none">
          <li
            className="text-[16px] text-[#264653] leading-6 hover:text-indigo-500"
            onClick={scrollToTop}
          >
            <Trans i18nKey="description.navpart2">Tıkla Gönder</Trans>
          </li>
        </Link>
        <div
          to="/calculator"
          activeClassName="active"
          className="list-none "
        >
          <li
            className="w-[100%] text-[16px] text-[#264653] leading-6 hover:text-indigo-500 cursor-pointer"
            onClick={() => setActive(!isActive)}
          >
            <Trans i18nKey="description.navpart3">Fiyat Hesapla</Trans>
          </li>
          <ul className={`${isActive ? "absolute mt-4" : "hidden"}`}>
            <Link to="/calculator">
              <li
                onClick={scrollToTop}
                className="text-[14px] bg-white sidebarshadow p-2 text-[#264653] leading-6 hover:text-indigo-500 cursor-pointer p-[5px]"
              >
                <Trans i18nKey="description.navmenupart2">Gonderi hesapla</Trans>
              </li>
            </Link>
            <a onClick={handleFiyatHesaplaClick} >
              <li className="text-[14px] bg-white sidebarshadow p-2 text-[#264653] leading-6 hover:text-indigo-500 cursor-pointer p-[5px]">
                <Trans i18nKey="description.navmenupart1">Fiyat Hesapla</Trans>
              </li>
            </a>
          </ul>
        </div>
        <Link to="/contact" activeClassName="active" className="list-none">
          <li
            onClick={scrollToTop}
            className="text-[16px] text-[#264653] leading-6  hover:text-indigo-500"
          >
            <Trans i18nKey="description.navpart4"> İletişim </Trans>
          </li>
        </Link>
      </div>
    );
  };

  export default SubNavbar;
