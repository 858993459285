import React from "react";
import Integrations from "../../components/Home/Integration";
import Questions from "../../components/Home/Questions";
import Footer from "../../components/Home/Footer";
import Form from "../../components/Home/Form";
import Subscription from "../../components/Dashboard/Shared/Subscription";
import Navbar from "../../components/Home/NavBar";
import { NavLink } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import homepagetr from "../../assets/images/homepagetr.png";
import homepageeng from "../../assets/images/homepageeng.png";

 const Home = () => {
  const { i18n } = useTranslation();

  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
      <Navbar />
      <div>
        <div className="flex items-center justify-between  py-[40px] lg:py-[50px]">
          <div className="w-[100%] lg:w-[40%]  text-center lg:text-left">
            <h1 className="text-[40px] pb-2 font-semibold">
              <Trans i18nKey="description.homepart1">
                Yurtdışına Hızlı Gönderi
              </Trans>
            </h1>
            <p>
              <Trans i18nKey="description.homepart2">
                Kavvay, uluslararası gönderi sürecinizi sorunsuzca ve otomatik
                şekilde yönetir, belirli ülkelere uygun fiyattan havayolu ile
                gönderi yapmanıza yardımcı olur.
              </Trans>
            </p>
            <NavLink to="/register">
              <button className=" bg-indigo-500 text-white  p-4 rounded-[10px] mt-8">
                <Trans i18nKey="description.homepart3">Register For Free</Trans>
              </button>
            </NavLink>
          </div>
          {i18n.language === "tr" ? (
            <div className="w-[30%] h-[40%] hidden lg:block">
            <img src={homepagetr} alt="" />
          </div>
          ) : (
            <div className="w-[30%] h-[40%] hidden lg:block">
            <img src={homepageeng} alt="" />
          </div>
          )}
          
        </div>

        <div>
          <Integrations />
        </div>
        {/* <div>
          <Slider />
        </div> */}
        <div>
          <Questions />
        </div>
        <div>
          <Subscription />
        </div>
        <div>
          <Form />
        </div>

        <div>
          <div className="partion_line"></div>
          <div>
            <Footer
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default Home;