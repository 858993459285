import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  packageDetails: null,
  packageDetailsLoading: false,
  packageDetailsError: null,
  packageUpdate: null,
  packageUpdateLoading: false,
  packageUpdateError: null,
  packageDelete: null,
  packageDeleteLoading: false,
  packageDeleteError: null,
  packageCreate: null,
  packageCreateLoading: false,
  packageCreateError: null,
};

export const packageList = createAsyncThunk(
  "package/packageList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Package/GetAllPackages`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const packageDetails = createAsyncThunk(
  "package/packageDetails",
  async ({ id }, {rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Package/GetPackages?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const packageUpdate = createAsyncThunk(
  "package/packageUpdate",
  async ({ updatedPackage }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Package/UpdatePackage`,
        updatedPackage,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// export const packageDelete = createAsyncThunk(
//   "package/packageDelete",
//   async ({ id }: { id: number }, { getState, rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.delete(
//         `${API_URL}api/packages/delete/${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error: any) {
//       console.log(rejectWithValue(error.response.data));
//     }
//   }
// );

export const packageCreate = createAsyncThunk(
  "package/packageCreate",
  async ({ createdPackage }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Package/CreatePackage`,
        createdPackage,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const packageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(packageList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(packageList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(packageList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(packageDetails.pending, (state) => {
        state.packageDetailsLoading = true;
        state.packageDetailsError = null;
      })
      .addCase(packageDetails.fulfilled, (state, action) => {
        state.packageDetailsLoading = false;
        state.packageDetails = action.payload;
      })
      .addCase(packageDetails.rejected, (state, action) => {
        state.packageDetailsLoading = false;
        state.packageDetailsError = action.payload;
      })
        .addCase(packageCreate.pending, (state) => {
          state.packageCreateLoading = true;
          state.packageCreateError = false;
        })
        .addCase(packageCreate.fulfilled, (state, action) => {
          state.packageCreateLoading = false;
          state.packageCreateSuccess = true;
        })
        .addCase(packageCreate.rejected, (state, action) => {
          state.packageCreateLoading = false;
          state.packageCreateError = true;
        })
      .addCase(packageUpdate.pending, (state) => {
        state.packageUpdateLoading = true;
        state.packageUpdateError = false;
      })
      .addCase(packageUpdate.fulfilled, (state, action) => {
        state.packageUpdateLoading = false;
        state.packageUpdateSuccess = true;
      })
      .addCase(packageUpdate.rejected, (state, action) => {
        state.packageUpdateLoading = false;
        state.packageUpdateError = true;
      });
  },
});

// Export actions and reducer
export const packageActions = packageSlice.actions;
export default packageSlice.reducer;
