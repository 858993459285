import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  orderDetails: null,
  orderDetailsLoading: false,
  orderDetailsError: null,
  orderLabel: null,
  orderLabelLoading: false,
  orderLabelError: null,
  orderUpdate: null,
  orderUpdateLoading: false,
  orderUpdateError: null,
  orderInvoiceUpdate: null,
  orderInvoiceUpdateLoading: false,
  orderInvoiceUpdateError: null,
  orderDelete: null,
  orderDeleteLoading: false,
  orderDeleteError: null,
  orderCreate: null,
  orderCreateLoading: false,
  orderCreateError: null,
  orderPayment: null,
  orderPaymentLoading: false,
  orderPaymentError: null,
  orderStatistics: null,
  orderStatistcisLoading: false,
  orderStatisticsError: null,
};

export const orderList = createAsyncThunk(
  "order/orderList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Order/GetOrder`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const orderDetails = createAsyncThunk(
  "order/orderDetails",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Order/GetOrder?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const orderLabelDetails = createAsyncThunk(
  "order/orderLabelDetails",
  async ({ transactionNumber }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/CreateLabel?transactionNumber=${transactionNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);



export const orderInvoiceUpdateData = createAsyncThunk(
  "order/orderInvoiceUpdate",
  async ({ updatedInvoiceOrder }, { rejectWithValue }) => {
    try {
      console.log(updatedInvoiceOrder);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Order/UpdateOrderLocalCargoInfo`,
        updatedInvoiceOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("An error occurred:", error);
      throw error; // Throw the error to be caught in the catch block of handleSubmit
    }
  }
);

export const orderUpdate = createAsyncThunk(
  "order/orderUpdate",
  async (
    {updatedOrder },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Order/UpdateOrder`,
        updatedOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const orderDelete = createAsyncThunk(
  "order/orderDelete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${apiConfig.API_URL}/api/Order/DeleteOrder`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { id: [id] }, // Pass the data in the request body
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const orderStatistics = createAsyncThunk(
  "order/orderStatistics",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Order/GetOrdersStatistics`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);
export const orderCreate = createAsyncThunk(
  "order/orderCreate",
  async ({ order }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Order/CreateOrder`,
        order,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const orderPay = createAsyncThunk(
  "order/orderPayment",
  async ({ orderPayment }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/PayForOrder`,
        orderPayment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(orderList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(orderList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(orderList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(orderDetails.pending, (state) => {
        state.orderDetailsLoading = true;
        state.orderDetailsError = null;
      })
      .addCase(orderDetails.fulfilled, (state, action) => {
        state.orderDetailsLoading = false;
        state.orderDetails = action.payload;
      })
      .addCase(orderDetails.rejected, (state, action) => {
        state.orderDetailsLoading = false;
        state.orderDetailsError = action.payload;
      })
      .addCase(orderLabelDetails.pending, (state) => {
        state.orderLabelDetailsLoading = true;
        state.orderLabelDetailsError = null;
      })
      .addCase(orderLabelDetails.fulfilled, (state, action) => {
        state.orderLabelDetailsLoading = false;
        state.orderLabelDetails = action.payload;
      })
      .addCase(orderLabelDetails.rejected, (state, action) => {
        state.orderLabelDetailsLoading = false;
        state.orderLabelDetailsError = action.payload;
      })
      .addCase(orderInvoiceUpdateData.pending, (state) => {
        state.orderInvoiceUpdateLoading = true;
        state.orderInvoiceUpdateError = false;
      })
      .addCase(orderInvoiceUpdateData.fulfilled, (state, action) => {
        state.orderInvoiceUpdateLoading = false;
        state.orderInvoiceUpdate = action.payload;
      })
      .addCase(orderInvoiceUpdateData.rejected, (state, action) => {
        state.orderInvoiceUpdateLoading = false;
        state.orderInvoiceUpdateError = true;
      })
      .addCase(orderCreate.pending, (state) => {
        state.orderCreateLoading = true;
        state.orderCreateError = false;
      })
      .addCase(orderCreate.fulfilled, (state, action) => {
        state.orderCreateLoading = false;
        state.orderCreateSuccess = true;
      })
      .addCase(orderCreate.rejected, (state, action) => {
        state.orderCreateLoading = false;
        state.orderCreateError = true;
      })
      .addCase(orderPay.pending, (state) => {
        state.orderPaymentLoading = true;
        state.orderCreateError = false;
      })
      .addCase(orderPay.fulfilled, (state, action) => {
        state.orderPaymentLoading = false;
        state.orderPayment = true;
      })
      .addCase(orderPay.rejected, (state, action) => {
        state.orderPaymentLoading = false;
        state.orderPaymentError = true;
      })
      .addCase(orderStatistics.pending, (state) => {
        state.orderStatisticsLoading = true;
        state.orderStatisticsError = null;
      })
      .addCase(orderStatistics.fulfilled, (state, action) => {
        state.orderStatisticsLoading = false;
        state.orderStatistics = action.payload;
      })
      .addCase(orderStatistics.rejected, (state, action) => {
        state.orderStatisticsLoading = false;
        state.orderStatisticsError = action.payload;
      })
  },
});

// Export actions and reducer
export const orderActions = orderSlice.actions;
export default orderSlice.reducer;
