import React from "react";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
const GDPR = () => {
  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
    <Navbar/>
    
    <div className="flex flex-col w-[100%]  mx-auto my-12">
      <h2 className="p-[20px] lg:p-[40px] text-center m-2 bg-[#d3d3d3]">
        KAVVAY GİZLİLİK POLİTİKASI VE AYDINLATMA METNİ
      </h2>
      <div className="p-8">
        <p className="text-[17px]">
          İşbu Gizlilik Politikası ve Aydınlatma Metni’nin (“<b>Politika</b>”)
          amacı, Hyrkan Group İç ve Dış Ticaret Danışmanlık Limited Şirketi (“
          <b>Şirket</b>”) tarafından yönetilmekte olan “www.kavvay.com” adresli
          internet sitesinin (“Site”) gerçek ve tüzel kişi kullanıcıları ile
          ziyaretçilerine (“<b>Kullanıcı/lar</b>” “<b>Müşteri/ler</b>”, “
          <b>Ziyaretçi/ler</b>” veya “<b>Siz</b>”) Site üzerinden sağlanan
          hizmetler (“<b>Hizmetler</b>”) için, Şirket’e sağlanan ve/veya
          Site’nin kullanımı aşamasında gerçek kişi Kullanıcılar ile tüzel kişi
          Kullanıcılar’ın çalışan ve yetkililerinden elde edilen kişisel
          verilerin işlenmesine ilişkin koşul ve şartlar hakkında Kullanıcılar’ı
          ve Ziyaretçiler’i aydınlatmaktır. Bu Politika’da tanımlanmayan
          ifadelerin yorumlanmasında Site’de yayımlanan Kullanıcı
          Sözleşmesi’ndeki (“<b>Sözleşme</b>”) tanımlamalar dikkate alınacaktır.
          Site’yi kullanırken kendinizi güvende hissedebilirsiniz. Ancak, lütfen
          unutmayınız ki hiçbir sistem tamamıyla güvenli değildir. Verilerinizi
          korumak için gerekli tüm adımları atsak dahi daima, güvence dışında
          kalma ihtimaliniz söz konusudur. Bu sebeple kişisel verilerinizi
          paylaşırken çok dikkat etmeniz gerektiğini hatırlatmak isteriz. Şirket
          tarafından talep edilen veriler dışında Şirket’in talebi olmaksızın
          sizin tarafınızdan iletilen verilerin işlenmesi ise işbu Politika
          kapsamında Şirket tarafından işlenen kişisel veriler olarak
          sayılmayacaktır. Bu sebeple e-posta ile mesaj yoluyla veya herhangi
          başka bir iletişim kanalıyla görüntülerinizi ve sayılanlarla sınırlı
          olmaksızın Şirket tarafından talep edilmeyen diğer kişisel
          verilerinizi paylaşmamanız gerektiğini önemle ihtar ederiz.
        </p>

        <h3>Hangi Verileriniz İşlenmektedir?</h3>

        <p>
          İşbu başlık altında, toplanan veriler, Şirket tarafından sunulan
          hizmetler kapsamında işlenip 6698 sayılı Kişisel Verilerin Korunması
          Kanunu (“<b>Kanun</b>”) uyarınca kişisel veri sayılan, Kullanıcılar’a
          ait kişisel verilerin ve kişisel veri haricinde olan verilerin ve
          çerezler aracılığı ile toplanan verilerin hangileri olduğu aşağıda
          gruplar halinde sıralanmıştır. Aksi açıkça belirtilmedikçe, işbu
          Politika’da arz edilen hüküm ve koşullar kapsamında “kişisel veri”
          ifadesi aşağıda yer alan bilgileri içermektedir.
        </p>
        <h3>
          Kişisel Veriler: - Üyelik İşlemleri İçin Toplanan Veriler: - Gerçek
          Kişi Kullancılar’dan Toplanan Veriler:
        </h3>
        <p>
          <ul>
            <li> Kimlik Bilgileri: Ad, soyad</li>
            <li>
              {" "}
              İletişim Bilgileri: E-posta adresi, telefonu numarası, adres
              bilgileri
            </li>
            <li>
              Mali Bilgiler: Banka İBAN no, Kredi kartı numarası (Sadece hizmeti
              satın almaya karar verdiğinizde istenir.)
            </li>
            <li> Kimlik Doğrulama, Güvenlik ve Takip Bilgileri: TCKN</li>
            <li> Diğer Bilgiler: Kişinin çalıştığı şirket</li>
            <li>
              {" "}
              <b>
                {" "}
                Tüzel Kişi Kullanıcılar’ın Yetkililerinden Toplanan Veriler:
              </b>
            </li>
            <li>Kimlik Bilgileri: Ad,</li>
            <li>
              İletişim Bilgileri: E-posta adresi, telefonu numarası, adres
              bilgileri
            </li>
            <li>
              <b>Fatura Bilgileri İçin Toplanan Veriler:</b> Ad soyad, Ticari
              Unvan, T.C. Kimlik Numarası, Vergi Numarası, Adres Bilgisi,
              Telefon Numarası, E-posta Adresi
            </li>
            <li>
              <b>Üçüncü Taraflara İlişkin Veriler:</b> Site üzerinden
              faydalanılan Hizmet esnasında, herhangi bir üçüncü taraf hesabına
              ait, Hizmet ile alakalı olarak paylaşılan, üçüncü tarafa ait
              kişisel veriler, örn. e-posta adresi, herhangi bir kullanıcı adı
              vb.
            </li>
            <li>
              <b>Diğer Veriler</b> -** Özel Nitelikli Kişisel Veriler:** Özel
              nitelikli kişisel veriler Kişisel Verileri Koruma Kurumu
              tarafından “öğrenilmesi halinde ilgili kişi hakkında ayrımcılık
              yapılmasına veya mağduriyete neden olabilecek nitelikteki veri”
              olarak tanımlanmaktadır. Şirket, Kullanıcılar’ının özel nitelikli
              verilerini işlememektedir.
            </li>
            <li>
              <b>Çerez Yoluyla İşlenen Veriler:</b> Başta giriş yapılan IP
              Adresi, kullanılan cihaz tipi, işletim sistemi ve tarayıcı
              bilgileri olmak üzere işlenen verilerinize dair detaylı bilgiye
              Site’de yayınlanan “Çerez Politikası”ndan ulaşabilirsiniz.
              Kanun’un 3 ve 7. maddeleri uyarınca, geri döndürülemeyecek şekilde
              anonim hale getirilen veriler, Kanun uyarınca kişisel veri olarak
              kabul edilmeyecek ve bu verilere ilişkin işleme faaliyetleri işbu
              Politika hükümleri ile bağlı olmaksızın gerçekleştirilecektir.
            </li>
          </ul>
          Kullanıcı, işbu Politika’ya konu olan bilgilerinin tam, doğru ve
          güncel olduğunu, bu bilgilerde herhangi bir değişiklik olması halinde
          bunları derhal güncelleyeceğini taahhüt eder. Kullanıcı’nın güncel
          bilgilerini paylaşmamasından kaynaklanan durumlardan Şirket’in
          herhangi bir sorumluluğu söz konusu olmayacaktır.
        </p>

        <h3>Toplanan Verilerden Hangilerini Anonim Hale Getireceğiz?</h3>
        <p>
          Verilerin anonim hale getirilmesi, kişilerin kimliğinin
          saptanabilmesinin önüne geçilmesi yahut belli gruplar içerisinde ayırt
          edilebilir olma özelliğini, bir gerçek kişiyle ilişkilendirilemeyecek
          şekilde kaybetmesi anlamına gelmektedir. Bu kapsamda anonim hale
          getirilerek kullanılan verileriniz (i) Site’yi kullanan Kullanıcılar
          ve Ziyaretçiler’in Site’ye giriş zamanları, (ii) inceledikleri ürünler
          ve bunların özellikleri, (iii) Site’yi kullanma ve hizmet satın alma
          sıklıkları, (iv) Kullanıcılar’ın hangi şehirden Site’yi kullandıkları
          yahut hizmet satın aldıklarına ilişkin veriler, (v) Site’de arama
          yapılan ülkeler ve bu ülkelerden hangi sıklıkla arama yapıldığına
          ilişkin bilgilerdir.
        </p>

        <h3>Verilerinizi Hangi Amaçlarla İşlemekteyiz?</h3>
        <p>
          Şirket, tüzel kişi Kullanıcılar’ın yetkililerinden topladığı kişisel
          verileri, Hizmetler’in ifası kapsamında gerekli lojistik
          operasyonların yürütülmesi için tercih edilen 3. Kişi konumundaki
          firmalar ile paylaşabilecektir. Şirket, Kullanıcılar tarafından
          sağlanan kişisel veriler ile Şirket tarafından bu kişisel veriler
          kullanılarak üretilen yeni verileri, Kullanıcılar’ın Site’den
          kesintisiz bir şekilde faydalanabilmesi, hizmet verilirken gerekli
          operasyonel sürecin (Kullanıcılar’ın Site’ye vakit kaybetmeden girerek
          Hizmet alabilmesi, Hizmet alacağı ve Site üzerinden ulaşacağı
          firmaları listeleyerek görebilmesi, seçtiği firmalardan hizmet almaya
          karar verdikleri firma ile arasındaki iletişim hatalarının ortadan
          kaldırılması vb.) yürütülmesi, Site’ye konu hizmetlerin sağlanması ve
          iyileştirilmesi, Kullanıcı’nın veri güvenliğinin sağlanması,
          Kullanıcı’lar ile ilişkilerin yönetimi süreçlerinin yönetilmesi,
          Hizmetler’in devamlılığı açısından Şirket’in birimleri arasındaki
          koordinasyonun sağlanması, Ziyaretçi kayıtlarının tutulması, promosyon
          ve avantajlardan Kullanıcı ve Ziyaretçi’lerin haberdar edilebilmesi
          için yapılacak pazarlama faaliyetleri hakkında bilgilendirme
          yapılabilmesi, Ziyaretçi’lerin verilerinden istatistiksel veriler
          oluşturulması, Şirket’in kanun ve yönetmelikten doğan
          yükümlülüklerinin yerine getirilmesi ve mevcut veyahut ileride
          oluşabilecek hukuki uyuşmazlıkların önüne geçilmesi amaçlarıyla
          işleyebilecektir.
        </p>

        <h3>Çerezlere İlişkin</h3>

        <p>
          Şirket, Kullanıcılar’ın Site’yi kullanımları hakkındaki bilgileri
          teknik bir iletişim dosyası olan çerezleri (Cookie) kullanarak elde
          edebilecek, bu kapsamda verileri işleyebilecek ve üçüncü kişiler
          tarafından sunulan analiz hizmetleri kapsamında işlenmesi amacıyla,
          sadece bu analiz hizmetlerinin gerektirdiği ölçüde kullanılması için
          üçüncü kişilere iletebilecektir. Bahsi geçen teknik iletişim
          dosyaları, ana bellekte saklanmak üzere Site’nin, Kullanıcılar’ın
          tarayıcısına (browser) gönderdiği küçük metin dosyalarıdır. Teknik
          iletişim dosyası bir web sitesi hakkında durum ve tercih ayarlarını
          saklayarak internetin kullanımını bu anlamda kolaylaştırır. Teknik
          iletişim dosyası, Site’yi zamansal oranlamalı olarak kaç kişinin
          kullandığı, bir kişinin Site’yi hangi amaçla, kaç kez ziyaret ettiği
          ve ne kadar kaldığı hakkında istatistiksel bilgileri elde etmek ve
          Kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik
          olarak reklam ve içerik üretilmesine yardımcı olmak üzere tasarlanmış
          olup, bu amaçlarla kullanılmaktadır. Teknik iletişim dosyası, ana
          bellekten başkaca herhangi bir kişisel veri almak için
          tasarlanmamıştır. Tarayıcıların pek çoğu başta teknik iletişim
          dosyasını kabul eder biçimde tasarlanmıştır ancak Kullanıcılar dilerse
          teknik iletişim dosyasının gelmemesini veya teknik iletişim dosyası
          gönderildiğinde ikaz verilmesini sağlayacak biçimde tarayıcı
          ayarlarını her zaman için değiştirebilecektir. Çerezlerle ilişkin
          detayları Site üzerindeki “Çerez Politikası’nda bulabilirsiniz.
          Sistemle ilgili sorunların tanımlanması ve sorunların ivedilikle
          giderilebilmesi için, Şirket gereken hallerde Kullanıcılar’ın IP
          adresini tespit edebilecek ve kullanabilecektir. IP adresleri, ayrıca
          Kullanıcılar’ı genel bir şekilde tanımlamak ve kapsamlı demografik
          bilgi toplamak amacıyla da kullanılabilecektir.
        </p>
        <h3>Verinin Toplanmasının Hukuki Nedeni</h3>
        <p>
          Söz konusu verilerin toplanmasının temel nedeni ilgili mevzuattaki
          yükümlülüklerdir. Ayrıca, Kullanıcı’lar ile aramızdaki sözleşme
          ilişkisi, meşru menfaatlerimiz ve benimsediğimiz ticari ilkeler gereği
          yukarıda sınıflandırılan kişisel verileri toplamaktayız.
        </p>
        <h3>Verilerinize Kimler Erişebilmektedir?</h3>
        <p>
          Şirket, Kullanıcılar ve Ziyaretçiler tarafından sağlanan kişisel
          verileri ve Şirket tarafından bu kişisel veriler kullanılarak üretilen
          yeni verileri, Hizmetler’in sağlanabilmesi ve/veya geliştirilmesi,
          Kullanıcılar’ın deneyiminin geliştirilmesi ve işbu Politika’nın
          “Verilerinizi Hangi Amaçlarla İşlemekteyiz?” başlığı altında
          belirtilen amaçlardan herhangi birinin gerçekleştirilmesi için
          Kullanıcı’nın Site’den almak istediği hizmeti ifa edecek olan yine
          Site üzerinden ulaşılan firma ile hizmet operasyonunun yürütülebilmesi
          için paylaşılmaktadır.
          <br />
          Şirket ayrıca, Kanun’un 5. ve 8. maddeleri uyarınca ve/veya ilgili
          mevzuattaki istisnaların varlığı halinde, işbu Politika’ya konu
          verileri, Kullanıcılar’dan ayrıca rızasını almaksızın işleyebilecek ve
          üçüncü kişilerle paylaşabilecektir. Bu durumlardan başlıca olanlar
          aşağıda belirtilmiştir:
          <br />
          <ul>
            <li>Kanunlarda açıkça öngörülmesi,</li>
            <li>
              Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda
              bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin,
              kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün
              korunması için zorunlu olması,
            </li>
            <li>
              Sözleşme de dâhil olmak üzere bir sözleşmenin kurulması veya
              ifasıyla doğrudan doğruya ilgili olması kaydıyla, kişisel
              verilerin işlenmesinin gerekli olması,
            </li>
            <li>
              Şirket’in hukuki yükümlülüğünü yerine getirebilmesi için zorunlu
              olması,
            </li>
            <li>
              Kullanıcılar’ın kendileri tarafından alenileştirilmiş olması, Bir
              hakkın tesisi, kullanılması veya korunması için veri işlemenin
              zorunlu olması,
            </li>
            <li>
              Kullanıcılar’ın temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, Şirket’in meşru menfaatleri için veri işlenmesinin
              zorunlu olması.
            </li>
          </ul>
          Şirket, yukarıda anılan amaçların yerine getirilmesi ile sınırlı
          olarak, veri barındırma hizmeti almak için topladığı kişisel verileri
          sadece:
          <ul>
            <li>Açık rızanızın bulunduğu,</li>
            <li>
              Kanun’da öngörülen hallerin mevcut olduğu ve veri aktarılacak
              ülkede yeterli korumanın bulunduğu ve
            </li>
            <li>
              Kanun’da öngörülen hallerin mevcut olduğu ve aktarılacak ülkede
              yeterli korumanın bulunmamasına rağmen yeterli korumanın ilgili
              ülkedeki veri sorumlusu tarafından taahhüt edilmesi ve Kişisel
              Verileri Koruma Kurulu (“<b>Kurul</b>”)’nun da izninin bulunduğu
              hallerden birinin varlığında yurt dışındaki hizmet veren firmalara
              (kendisine, bağlı şirketlerine, alt yüklenicilerine veya
              uluslararası düzeyde güvenirliliği kanıtlanmış hizmet
              sağlayıcılara ait olabilir) aktarma hakkına sahip olacaktır.
            </li>
          </ul>
        </p>

        <h3>KULLANICILAR’IN DİKKATİNE:</h3>
        <p>
          Üçüncü taraflara bilerek sağlanan verilerden Kullanıcı’nın kendisi
          sorumlu olduğu gibi, ilgili paylaşımlarda Şirket’in hiçbir sorumluluğu
          bulunmamaktadır.
        </p>

        <h3>Verilerinize Erişim Hakkınız ve Düzeltme Talepleriniz Hakkında</h3>
        <p>
          Kullanıcılar, Şirket’e başvurarak kendisiyle ilgili;
          <ul>
            <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
            <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
            <li>
              Kişisel verilerin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme,
            </li>
            <li>
              Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
              kişileri bilme,
            </li>
            <li>
              Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
              bunların düzeltilmesini isteme,
            </li>
            <li>
              Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
              bunların düzeltilmesini isteme,
            </li>
            <li>
              İlgili mevzuatta öngörülen şartlar çerçevesinde kişisel verilerin
              silinmesini veya yok edilmesini isteme,
            </li>
            <li>
              lgili mevzuat uyarınca yapılan düzeltme, silme ve yok edilme
              işlemlerinin, kişisel verilerin aktarıldığı üçüncü kişilere
              bildirilmesini isteme,
            </li>
            <li>
              İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
              edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
              çıkmasına itiraz etme,
            </li>
            <li>
              Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
              uğraması halinde zararın giderilmesini talep etme haklarına
              sahiptir.
            </li>
          </ul>
          <br />
          Kullanıcılar, yukarıda belirtilen talepleri doğrultusunda
          kullanabilecekleri kanundan doğan başvuru hakkını Kanun’un 13.
          Maddesinin 1. Fıkrası ve ilgili diğer mevzuat hükümleri uyarınca
          aşağıda yer verilen yöntemlerden biri ile ya da Kişisel Verilerin
          Korunması Kurulu’nca belirlenecek diğer yöntemlerle ileterek
          kullanabilecektir. Kanun’da belirtilen mevcut yöntemler şu şekildedir:
          <ol>
            <li>
              Site’de bulunan Kişisel Verilerin Korunması Kanunu Uyarınca
              Başvuru Formu’nu (“Başvuru Formu”) doldurarak ıslak imzaladıktan
              sonra Şirket merkezi olan Nisbetiye mahallesi, Gazi Güçnar sokak,
              Uygur İş Merkezi kapı no 4, daire no 7, BEŞİKTAŞ İSTANBUL adresine
              iletilmesi (Başvuru sahibinin kimliğini teşvik edici belgesi ile
              bizzat gelip elden teslim etmesi suretiyle.)
            </li>
            <li>
              Başvuru Formu’nu doldurulup 5070 sayılı Elektronik İmza Kanunu’nda
              tanımlı “güvenli elektronik imza” ile imzalandıktan sonra güvenli
              elektronik imzalı formun “info@kavvay.com” e-posta adresine
              gönderilmesi suretiyle (E-posta’nın konu kısmına “Kişisel
              Verilerin Korunması Kanunu Bilgi Talebi” yazılacaktır.)
            </li>
            <li>
              Başvuru Formu’nun doldurulup ıslak imzalı olarak imzalandıktan
              sonra Şirket merkezi olan olan Nisbetiye mahallesi, Gazi Güçnar
              sokak, Uygur İş Merkezi kapı no 4, daire no 7, BEŞİKTAŞ İSTANBUL
              adresine noter vasıtasıyla gönderilmesi suretiyle (tebligat
              zarfına “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi
              Talebi” yazılacaktır.)
            </li>
          </ol>
          Başvuru sahibinin kendisi dışında bir kişinin talepte bulunması için
          konuya ilişkin olarak kişisel veri sahibi tarafından başvuruda
          bulunacak kişi adına düzenlenmiş özel vekâletname bulunmalıdır.
        </p>

        <h3>Kişisel Verilerin Saklama Süresi</h3>
        <p>
          Şirket, 6698 sayılı Kanun ve Kişisel Verilerin Silinmesi, Yok Edilmesi
          Veya Anonim Hale Getirilmesi Hakkında Yönetmelik (“<b>Yönetmelik</b>”)
          hükümlerine uygun şekilde hareket edecektir. Şirket, Kullanıcılar
          tarafından sağlanan kişisel verileri, Kullanıcılar’ın Site’den ve
          Hizmetler’den faydalanabilmesi için işbu Politika ile belirtilen
          Hizmetler’in mahiyetinden kaynaklanan yükümlülüklerin yerine
          getirilmesi amacıyla; Müşteriler’den toplanan veriler, kendilerine
          Hizmet’in sağlandığı son günden, Ziyaretçiler’den toplanan veriler ise
          iletişim izinlerinin kaldırıldığı tarihten itibaren 10 yıl boyunca
          muhafaza edecek ve Yönetmelik uyarınca kişisel verileri imha etme
          yükümlülüğünün ortaya çıktığı tarihi takip eden ilk periyodik imha
          işleminde, kişisel verileri imha edecektir. Şirket, Kanun’un 13.
          Maddesine istinaden kişisel verilerinin imha edilmesini talep eden
          Kullanıcılar’a karşı Yönetmelik’in 12. Maddesinden doğan
          yükümlülüklerini gerektiği şekilde yerine getirecektir.
        </p>
        <h3>Veri Güvenliğine İlişkin Önlemlerimiz ve Taahhütlerimiz</h3>
        <p>
          Şirket, ilgili mevzuatta belirlenen veya işbu Politika’da ifade edilen
          şartlarda, Site üzerinden kendisine elektronik ortamda iletilen,
          <ul>
            <li>Kişisel verilerin hukuka aykırı olarak işlenmemesini,</li>
            <li>Kişisel verilere hukuka aykırı olarak erişilmemesini,</li>
            <li>
              Kişisel verilerin muhafazasını sağlamak amacıyla uygun güvenlik
              düzeyini temin etmeye yönelik gerekli teknik ve idari tedbirleri
              almayı, gerekli denetimleri yaptırmayı taahhüt eder.
            </li>
          </ul>
          <br />
          Şirket, Kullanıcılar hakkında elde ettiği kişisel verileri işbu
          Politika ve 6698 sayılı Kanun hükümlerine aykırı olarak başkasına
          açıklayamaz ve işleme amacı dışında kullanmayacaktır. Site üzerinden
          başka uygulamalara link verilmesi halinde, Şirket uygulamaların
          gizlilik politikaları ve içeriklerine yönelik herhangi bir sorumluluk
          taşımayacaktır.
        </p>
        <h3>İletişim İzni</h3>
        <p>
          İşbu Politika’da belirtilmiş ve elektronik iletişim izni ile bizimle
          paylaşılmasına rıza göstermiş olduğunuz kişisel verilerinizin,
          tarafınıza çeşitli avantajların sağlanıp sunulabilmesi ve size özel
          reklam, satış, pazarlama, anket, rezervasyon ayrıcalığı ve benzer
          amaçlı her türlü elektronik iletişim yapılması ve diğer iletişim
          mesajlarının gönderilmesi amacıyla; toplanmasına, saklanmasına,
          işlenmesine, kullanılmasına, aktarımına izin vermiş bulunmaktasınız.
          Şirket, kayıt sırasında e-posta adresi ile doğrulama, şifre
          unutulduğunda doğru kişi olduğunu doğrulama, internet sitesi
          oluşturulması, internet sitesi üzerinden reklam verilmesi, internet
          sitesinin paylaşılması vb., güvenlik sebebiyle bildirim yapılması,
          e-fatura iletilmesi amacıyla Kullanıcılar ile posta üzerinden; kayıt
          sırasında telefon numarası doğrulama, çift faktörlü doğrulama yöntemi
          olarak bildirim yapma, şifresini unuttuğunda doğru kişi olduğunu
          doğrulama amaçlı, güvenlik sebebiyle yapılacak bildirim amacıyla
          telefon veya SMS üzerinden Kullanıcılar ile iletişime geçecektir.
          Şirket, söz konusu kişisel verilerin, Kanunu’nun 12. Maddesi uyarınca
          güvenli şekilde saklanması, yetkisiz erişimlerin ve hukuka aykırı veri
          işleme durumlarının önlenmesi için gereken her türlü tedbiri
          alacaktır.
        </p>
        <h3>Yaş Sınırı Bakımından Kullanıcılar’ın Gizliliği</h3>
        <p>
          Şirket, 18 yaş altındaki kişilerin verilerini kasten toplamayacak ve
          kayıt altına almayacaktır. 18 yaşın altındaysanız, Platformu ve
          Hizmetler’i kullanmamanızı ve hiçbir kişisel verinizi Şirket ile
          paylaşmamanız gerektiğini önemli bildiririz. Şirket, 18 yaş altındaki
          bir kişinin verilerinin toplandığının öğrenilmesi anında verileri
          silmek için makul adımları atacaktır.
        </p>
        <h3>Politika’daki Değişiklikler</h3>
        <p>
          Şirket, işbu Politika hükümlerini dilediği zaman Site üzerinden
          yayımlamak suretiyle değiştirebilecektir. Şirket’in değişiklik yaptığı
          Politika hükümleri yayınlandığı tarihte yürürlük kazanacaktır. İşbu
          Politika’da yapılan değişikliklerden haberdar olmaları için Şirket,
          Kullanıcılar’a gerekli bilgilendirmeleri yapacaktır.
        </p>
        <h3>Uyuşmazlık Çözümü</h3>
        <p>
          İşbu Politika, Türkiye Cumhuriyeti kanunlarına tabidir. Bu
          Politika’dan kaynaklanan veya bu Sözleşme ile ilişkili olan tüm
          uyuşmazlıklar, İstanbul Merkez (Çağlayan) adliyesi ve icra
          dairelerinde çözümlenecektir.
          <br />
          İşbu Politika’nın Kullanıcılar tarafından onaylanmaması halinde
          Hizmetler’den yararlanamayacağınızı belirtmek isteriz.
        </p>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default GDPR;
