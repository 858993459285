import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  agentDetails: null,
  agentDetailsLoading: false,
  agentDetailsError: null,
  agentDetailsInvoice: null,
  agentDetailsInvoiceLoading: false,
  agentDetailsInvoiceError: null,
  agentUpdate: null,
  agentUpdateLoading: false,
  agentUpdateError: null,
  agentUpdateInvoice: null,
  agentUpdateInvoiceLoading: false,
  agentUpdateInvoiceError: null,
  agentDelete: null,
  agentDeleteLoading: false,
  agentDeleteError: null,
  agentCreate: null,
  agentCreateLoading: false,
  agentCreateError: null,
};

export const agentList = createAsyncThunk(
  "agent/agentList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Agent/GetAgents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const agentDetails = createAsyncThunk(
  "agent/agentDetails",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Agent/GetAgents?agentId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const agentDetailsInvoiceData = createAsyncThunk(
  "agent/agentDetailsInvoice",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Agent/GetAgents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      // Use rejectWithValue here
      return rejectWithValue(error.response.data);
    }
  }
);

export const agentUpdateInvoice = createAsyncThunk(
  "agent/agentUpdateInvocie",
  async ({ updatedAgentInvoice }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Agent/UpdateAgent`,
        updatedAgentInvoice,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const agentUpdate = createAsyncThunk(
  "agent/agentUpdate",
  async ({ updatedAgent }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Agent/UpdateAgent`,
        updatedAgent,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const agentCreate = createAsyncThunk(
  "agent/agentCreate",
  async ({ agent }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/Agent/AddAgent`,
        agent,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const agentSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(agentList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(agentList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(agentList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(agentDetails.pending, (state) => {
        state.agentDetailsLoading = true;
        state.agentDetailsError = null;
      })
      .addCase(agentDetails.fulfilled, (state, action) => {
        state.agentDetailsLoading = false;
        state.agentDetails = action.payload;
      })
      .addCase(agentDetails.rejected, (state, action) => {
        state.agentDetailsLoading = false;
        state.agentDetailsError = action.payload;
      })
      .addCase(agentDetailsInvoiceData.pending, (state) => {
        state.agentDetailsInvoiceLoading = true;
        state.agentDetailsInvoiceError = null;
      })
      .addCase(agentDetailsInvoiceData.fulfilled, (state, action) => {
        state.agentDetailsInvoiceLoading = false;
        state.agentDetailsInvoice = action.payload;
      })
      .addCase(agentDetailsInvoiceData.rejected, (state, action) => {
        state.agentDetailsInvoiceLoading = false;
        state.agentDetailsInvoiceError = action.payload;
      })
      .addCase(agentUpdate.pending, (state) => {
        state.agentUpdateLoading = true;
        state.agentUpdateError = null;
      })
      .addCase(agentUpdate.fulfilled, (state, action) => {
        state.agentUpdateLoading = false;
        state.agentUpdate = action.payload;
      })
      .addCase(agentUpdate.rejected, (state, action) => {
        state.agentUpdateLoading = false;
        state.agentUpdateError = action.payload;
      })
      .addCase(agentUpdateInvoice.pending, (state) => {
        state.agentUpdateInvoiceLoading = true;
        state.agentUpdateInvoiceError = null;
      })
      .addCase(agentUpdateInvoice.fulfilled, (state, action) => {
        state.agentUpdateInvoiceLoading = false;
        state.agentUpdateInvoice = action.payload;
      })
      .addCase(agentUpdateInvoice.rejected, (state, action) => {
        state.agentUpdateInvoiceLoading = false;
        state.agentUpdateInvoiceError = action.payload;
      })
      .addCase(agentCreate.pending, (state) => {
        state.agentCreateLoading = true;
        state.agentCreateError = false;
      })
      .addCase(agentCreate.fulfilled, (state, action) => {
        state.agentCreateLoading = false;
        state.agentCreateSuccess = true;
      })
      .addCase(agentCreate.rejected, (state, action) => {
        state.agentCreateLoading = false;
        state.agentCreateError = true;
      });
  },
});

// Export actions and reducer
export const agentActions = agentSlice.actions;
export default agentSlice.reducer;
