import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { subscribeDetails } from "../../../redux/slices/subscribeSlice";
import Loader from "../../../partials/Loader";
import { useTranslation, Trans } from "react-i18next";
  
const SubscriptionDetails = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { data, loading, error } = useSelector((state) => state.subscribe);

  useEffect(() => {
    dispatch(subscribeDetails());
  }, [dispatch]);

  if (loading)
    return (
      <div>
        {" "}
        <Loader />
      </div>
    );
  if (error) return <div>{error}</div>;
  return (
    <>
      <div className="w-[100%] bg-white sidebarshadow mt-4 rounded-[10px] p-4">
        <div className="flex  justify-between items-center">
          <h2 className="text-[18px] font-semibold">              <Trans i18nKey="description.subscriptiondetails1">Subscription details</Trans>
</h2>
          <p className="text-[14px]  text-white bg-indigo-400 px-4 py-2 rounded-[10px] cursor-pointer">
            {data?.name}
          </p>
        </div>
        <div>
          <h3 className="pt-2 opacity-[0.7]">
          <Trans i18nKey="description.subscriptiondetails2">Subscription details</Trans>
            <span className="font-bold pl-[5px]">{data?.annuallyPrice} TRY</span>
          </h3>
          <h3 className="pt-2 opacity-[0.7]">
          <Trans i18nKey="description.subscriptiondetails3">Subscription details</Trans>
            <span className="font-bold pl-[5px]">{data?.currentPackBalance}</span>
          </h3>
          <h3 className="pt-2 opacity-[0.7]">
          <Trans i18nKey="description.subscriptiondetails4">Subscription details</Trans>
            <span className="font-bold pl-[5px]">
              {data?.startDate.substr(8, 2)}-{data?.startDate.substr(5, 2)}-
              {data?.startDate.substr(0, 4)} {data?.startDate.substr(11, 8)}
            </span>
          </h3>
          <h3 className="pt-2 opacity-[0.7]">
          <Trans i18nKey="description.subscriptiondetails5">Subscription details</Trans>
            <span className="font-bold pl-[5px]">
              {data?.expireDate.substr(8, 2)}-{data?.expireDate.substr(5, 2)}-
              {data?.expireDate.substr(0, 4)} {data?.expireDate.substr(11, 8)}
            </span>
          </h3>

          <div className="pt-2 opacity-[0.7] flex flex-end justify-end mt-2 ">
            <span className="font-bold px-4 py-2 rounded-[10px] border-indigo-400 border-[1px]">
            <Trans i18nKey="description.subscriptiondetails6">Subscription details</Trans>
            </span>
          </div>
        </div>
      </div>
      {/* <Alert /> */}
    </>
  );
};

export default SubscriptionDetails;
