let apiConfig = {
    API_URL: "",
};

if (process.env.NODE_ENV === 'development') {
    apiConfig.API_URL = "https://sandbox.kavvay.com";
} else if (process.env.NODE_ENV === 'production') {
    apiConfig.API_URL = "https://sandbox.kavvay.com";
}

export default apiConfig;
