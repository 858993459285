import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: null,
  error: null,
  providerCountryListData: null,
  providerCountryLoading: null,
  providerCountryError: null, 
  agentCountryListData: null,
  agentCountryLoading: null,
  agentCountryError: null,
};

export const countryList = createAsyncThunk(
  `/country/countryDetails`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Country/getCountries`,
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);


export const providerCountryList = createAsyncThunk(
  `/providerCountry/countryDetails`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Country/GetProviderCountries`,
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);


export const agentCountryList = createAsyncThunk(
  `/agentCountry/countryDetails`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiConfig.API_URL}/api/Country/GetAgentCountries`,
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(countryList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(countryList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(countryList.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(providerCountryList.pending, (state) => {
        state.providerCountryLoading = true;
        state.providerCountryError = false;
      })
      .addCase(providerCountryList.fulfilled, (state, action) => {
        state.providerCountryLoading = false;
        state.providerCountryListData = action.payload;
      })
      .addCase(providerCountryList.rejected, (state, action) => {
        state.providerCountryLoading = false;
        state.providerCountryError = true;
      })
      .addCase(agentCountryList.pending, (state) => {
        state.agentCountryLoading = true;
        state.agentCountryError = false;
      })
      .addCase(agentCountryList.fulfilled, (state, action) => {
        state.agentCountryLoading = false;
        state.agentCountryListData = action.payload;
      })
      .addCase(agentCountryList.rejected, (state, action) => {
        state.agentCountryLoading = false;
        state.agentCountryError = true;
      });
  },
});

// Export actions and reducer
export const countryActions = countrySlice.actions;
export default countrySlice.reducer;
