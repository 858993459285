import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
const Cookiepolicy = () => {
  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
    <Navbar/>
    <div className="flex flex-col w-[100%] mx-auto my-12">
      <h2 className="text-center p-[15px] lg:p-[40px] bg-[#d3d3d3]">
        <Trans>KAVVAY ÇEREZ POLİTİKASI</Trans>
      </h2>
      <div className="p-8">
        <p>
          <Link to="/"> www.kavvay.com </Link> adresli internet sitemizden
          (“Kavvay”) en verimli şekilde faydalanabilmeniz ve kullanıcı
          deneyiminizi geliştirmek adına Hyrkan Group İç ve Dış Ticaret
          Danışmanlık Limited Şirketi (“Şirket”) tarafından çerez (Cookie)
          kullanmaktadır. Politika’nın amacı, Kavvay’nun sunduğu hizmetler
          (“Hizmetler”) dahilinde topladığı çerezler, bu çerezlerin türleri,
          hangi amaçla toplandığı ve çerez ayarlarınıza ilişkin bilgi
          sağlamaktır. Kavvay tarafından toplanan kişisel verilerle ilgili
          detaylı bilgiye Kavvay internet sitesinde yayınlanan “Kavvay Gizlilik
          Politikası ve Aydınlatma Metni”nden ulaşabilirsiniz.
        </p>

        <h3>Çerez Teknolojisi Nedir?</h3>
        <p>
          Çerezler, bir web sitesini ziyaret ettiğinizde cihazınıza (bilgisayar,
          cep telefonu veya diğer mobil cihazlar) indirilen metin dosyalarıdır.
          Çerezler, web siteleri tarafından geniş çerçevede kullanılan bir
          sistemdir. Web tarayıcılarının ön kabulle tanımlanmış olması sebebiyle
          ziyaretiniz ile ilgili cihazınızın dil, ayarlar, tercihler vb.
          bilgilerinin hatırlanmasına yardımcı olur. Web sitesini ziyaretiniz
          sırasında elde edilen bu bilgiler çerez olarak cihazınıza indirilen
          bilgi dosyalarıdır.
        </p>

        <h3>Çerez Türleri</h3>
        <h3>Gerekli Çerezler</h3>
        <p>
          <b> Tanımı:</b> Bu çerezler talep etmiş olunan Hizmetler’in
          sağlanmasını mümkün kılar. Bu çerezler olmadan, talep edilen
          Hizmetler’den düzgün bir şekilde yararlanmak mümkün değildir.
          <br />
          <br />
          <b>Amacı:</b> Kavvay’nun web sitesi üzerinden sağladığı Hizmetler’in
          devamlılığı için toplanması zorunlu çerezlerdir.
        </p>

        <h3>Performans Çerezleri</h3>
        <p>
          <b>Tanımı:</b> Bu çerezler, kullanıcıların sunulan hizmetleri ne
          şekilde kullandığı hakkında, örneğin kullanıcıların en sık hangi
          sayfalara gittiği ve bu web sayfalarında hata mesajları alıp almadığı
          hakkında bilgi toplar. Bu çerezler, ziyaret edilen sayfalar hakkında
          anonim bilgi toplar. Bu çerezlerin topladığı tüm bilgiler toplanır ve
          dolayısıyla anonim olur. Yalnızca hizmetlerin performansını
          iyileştirmek için kullanılır. Bir web sitesinin performansını
          geliştirmek amacıyla veri toplamak için çerezleri kullanan web
          analizleri bu kategoriye girer. Örneğin, tasarımları test etmek ve
          kullanıcı için tutarlı bir görünüm ve his sağlamak için
          kullanılabilirler. Bu kategori, davranışsal/ hedefli reklam ağları
          için kullanılan çerezleri içermez.
          <br />
          <br />
          <b> Amacı:</b> Performans çerezleri, Kavvay Hizmetler’ine nasıl
          erişildiğini, bunların nasıl kullanıldığını veya nasıl performans
          gösterdiğini analiz etmek için kullanırız. Bu bilgileri Kavvay
          Hizmetler’ini sürdürmek, geliştirmek ve sürekli olarak iyileştirmek
          için kullanırız. Ayrıca bir e-posta bültenini açıp açmadığınız,
          başkasına yönlendirip yönlendirmediğiniz veya herhangi bir içeriğine
          tıklayıp tıklamadığınız dahil olmak üzere size gönderdiğimiz e-posta
          bültenlerinden veya diğer iletişimlerden bilgiler de edinebiliriz. Bu
          bilgiler bize, bültenlerimizin ne kadar etkili olduğu hakkında bilgi
          verir ve ilginizi çeken bilgiler sunduğumuzdan emin olmamızı sağlar
        </p>

        <h3>Fonksiyonellik Çerezleri</h3>
        <p>
          <b>Tanımı:</b> Bu çerezler, web sitesinin yapmış olduğunuz seçimleri
          (kullanıcı adınız, diliniz veya bulunduğunuz bölge gibi) hatırlamasını
          ve daha gelişmiş, daha kişisel özellikler sunmasını sağlar. Bu
          çerezler, ayrıca metin boyutuyla, yazı tipleriyle ve web sayfalarının
          özelleştirebileceğiniz diğer kısımlarıyla ilgili yaptığınız
          değişiklikleri hatırlamak için de kullanılabilir. Bu çerezlerin
          topladığı bilgiler anonim hale getirilebilir ve diğer web sitelerinde
          gezinme faaliyetlerinizi izleyemez. Bu çerezler, deneyiminizi
          iyileştirmek için yaptığınız seçimleri hatırlar. Aynı çerez yeniden
          hedefleme için kullanılırsa "hedefleme veya reklam çerezleri"
          kategorisine de dahil edilebilir.
          <br />
          <br />
          <b> Amacı:</b> Bu çerezler, Kavvay’nun Hizmetler’ini tercihlerinize
          göre kişileştirme imkânı tanıyan çerezlerdir. Örneğin, Kavvay
          Hizmetler’ini kullanmaya devam ettiğinizde, edindiğimiz bilgiler
          neticesinde, kullanıcı adı, adres gibi bilgileri sizler için
          hatırlayabilir, size daha önceden aldığınız ve/veya uzun süre
          sayfasında kaldığınız veya sıklıkla ziyaret ettiğiniz sayfaların
          dikkatinize sunabiliriz.
        </p>

        <h3>Hedefleme veya reklam çerezleri</h3>
        <p>
          <b>Tanımı:</b> Bu çerezler, size ve ilgi alanlarınıza uygun reklamlar
          sunmak için kullanılır. Bunlar ayrıca bir reklamı kaç kez gördüğünüzü
          sınırlandırmak ve ayrıca reklam kampanyalarının ne kadar etkili
          olduğunu ölçmeye yardımcı olmak için de kullanılır. Web sitesi
          operatörünün izniyle reklam ağları tarafından yerleştirilirler. Bu
          çerezler, bir web sitesini ziyaret ettiğinizi hatırlar ve bu bilgi,
          reklam verenler gibi diğer kuruluşlarla paylaşılır. Hedefleme veya
          reklam çerezleri sıklıkla diğer kuruluşlar tarafından sağlanan site
          işlevselliği ile bağlantılıdır. Bu çerezler, reklamların size ve ilgi
          alanlarınıza uygun olması için gezinme alışkanlıklarınız hakkında
          bilgi toplar.
          <br />
          <br />
          <b>Amacı:</b> Bu çerezleri, Kavvay Hizmetler’ini alırken, ilgi
          alanlarınıza dayalı reklam sunmak amacıyla kullanmaktayız. Bu
          bilgiler, aynı zamanda belirli bir reklamın size kaç kez sunulduğunu
          kaydetmek, size tekrar tekrar aynı reklamları göstermemek ve bu
          reklamların ne kadar etkili olduğunu ölçmemize yardımcı olması
          amacıyla da kullanılabilmekteyiz.
          <br />
          <br />
          Ayrıca Kavvay’yu diğer web sitelerinde ve Hizmetler’de tanıtan
          reklamlar ve başka içerikleri sunmak için web sitesi yayıncıları,
          uygulama geliştiriciler, reklam ağları ve hizmet sağlayıcılar ile
          çalışmaktayız. Çerezler, başka web sitelerinde, uygulamalarda ve
          cihazlarda size ve ilgi alanlarınıza uygun olabilecek reklamları size
          göstermek, aldığınız reklamları düzenlemek ve bunların ne kadar etkili
          olduğunu ölçmek için kullanılabilir.
          <br />
          Ek olarak, Kavvay iş ortaklarının, Kavvay’nun Hizmetler’i içinde veya
          dışında, zaman içinde ve farklı web sitelerinde, uygulamalarda ve/veya
          cihazlarda çevrimiçi faaliyetleriniz hakkında bilgi toplamak dahil
          olmak üzere çerezleri, yukarıda tanımlanan aynı amaçlarla kullanmasına
          izin verebiliriz.
          <br />
          <br />
        </p>

        <h3>Çerezleri Yönetme</h3>
        <p>
          Çerezlerin, cihazınızda tutulmasını istemiyorsanız her zaman çerezlere
          ilişkin onayınızı geri çekebilir ve çerezleri silebilirsiniz.
          Çerezlerin toplanmasını engellemek için veyahut toplanan çerezlerin
          silinmesi için web tarayıcınızın ayarlar kısmından çerez ayarlarınızı
          değiştirebilirsiniz.
          <br />
          Çerezlerinizi silmeniz dahilinde web sitesi içerisinde tercihleriniz
          de silinecektir. Ayrıca çerezlerin toplanmasını engellemeniz halinde
          Kavvay’nun sağladığı Hizmetler’in [*] yararlanamayacaksınız. Bu
          kapsamda Hizmetler’den yararlanabilmek adına çerez kullanımına izin
          vermenizi tavsiye ederiz.
        </p>

        <h3>Politika’daki Değişiklikler</h3>
        <p>
          Kavvay, Politika’da tek taraflı olarak değişiklik yapma hakkını saklı
          tutar. Politika’da yapılan önemli değişiklikler tarafınıza mail, web
          sitesi içi pop-up ile bildirim yollarından biri veya birkaçı ile
          bildirilecektir.
        </p>

        <h3>İletişim</h3>
        <p>
          Ticaret Ünvanı: HYRKAN GROUP İÇ VE DIŞ TİCARET DANIŞMANLIK LİMİTED
          ŞİRKETİ
          <br />
          Adresi: Nisbetiye mahallesi, Gazi Güçnar sokak, Uygur İş Merkezi kapı
          no 4, daire no 7, BEŞİKTAŞ İSTANBUL
          <br />
          E-posta: info@kavvay.com
        </p>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Cookiepolicy;
