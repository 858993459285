import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  faqDetails: null,
  faqDetailsLoading: false,
  faqDetailsError: null,
  faqUpdate: null,
  faqUpdateLoading: false,
  faqUpdateError: null,
  faqDelete: null,
  faqDeleteLoading: false,
  faqDeleteError: null,
  faqCreate: null,
  faqCreateLoading: false,  
  faqCreateError: null,
};

export const faqList = createAsyncThunk(
  "faq/faqList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/FAQ/GetFAQs`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const faqDetails = createAsyncThunk(
  "faq/faqDetails",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/FAQ/GetFAQs?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const faqUpdate = createAsyncThunk(
  "faq/faqUpdate",
  async (
    { updatedFaq },
  ) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/FAQ/UpdateFAQ`,
        updatedFaq,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
     throw new Error(error.response.data.message);
    }
  }
);


// export const faqDelete = createAsyncThunk(
//   "faq/faqDelete",
//   async ({ id }: { id: number }, { getState, rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.delete(
//         `${API_URL}api/faqs/delete/${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error: any) {
//       console.log(rejectWithValue(error.response.data));
//     }
//   }
// );





export const faqCreate = createAsyncThunk(
  "faq/faqCreate",
  async ({ faq }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/FAQ/CreateFAQ`,
        faq,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Create the auth slice
const faqSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(faqList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(faqList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(faqList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(faqDetails.pending, (state) => {
        state.faqDetailsLoading = true;
        state.faqDetailsError = null;
      })
      .addCase(faqDetails.fulfilled, (state, action) => {
        state.faqDetailsLoading = false;
        state.faqDetails = action.payload;
      })
      .addCase(faqDetails.rejected, (state, action) => {
        state.faqDetailsLoading = false;
        state.faqDetailsError = action.payload;
      })
      .addCase(faqCreate.pending, (state) => {
        state.faqCreateLoading = true;
        state.faqCreateError = false;
      })
      .addCase(faqCreate.fulfilled, (state, action) => {
        state.faqCreateLoading = false;
        state.faqCreateSuccess = true;
      })
      .addCase(faqCreate.rejected, (state, action) => {
        state.faqCreateLoading = false;
        state.faqCreateError = true;
      })
      .addCase(faqUpdate.pending, (state) => {
        state.faqUpdateLoading = true;
        state.faqUpdateError = false;
      })
      .addCase(faqUpdate.fulfilled, (state, action) => {
        state.faqUpdateLoading = false;
        state.faqUpdateSuccess = true;
      })
      .addCase(faqUpdate.rejected, (state, action) => {
        state.faqUpdateLoading = false;
        state.faqUpdateError = true;
      })
  },
});

// Export actions and reducer
export const faqActions = faqSlice.actions;
export default faqSlice.reducer;
