import React from "react";
import { useTranslation, Trans } from "react-i18next";
import box from "../../assets/images/box.png";
import NavBar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
const About = () => {
  const { i18n } = useTranslation();

  return (

    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
      <NavBar />
      <div className="w-[100%] mx-auto flex flex-col items-center my-auto lg:flex-row justify-between pt-16">
        <div className="w-[50%]">
          <h1 className="text-[32px]">
            <Trans i18nKey="description.aboutpart1">
              Kavvay ile kolayca gelsin!
            </Trans>
          </h1>
          <h2 className="pt-4">
            <Trans i18nKey="description.aboutpart2">
              OPTIMIZE THE POST-PURCHASE EXPERIENCE FOR eCOMMERCE WITH KAVVAY
            </Trans>
          </h2>
          <article className="pt-4">
            <p className="text-[14] opacity-[0.7]">
              <Trans i18nKey="description.aboutpart3">
                {" "}
                We're a young and fast growing business born in Turkey and
                looking to expand across the globe with team of technology and
                retail experts spread across different countries and different
                regions. We like to build high performance solutions for
                eCommerce businesses and have an unrelenting drive to build
                things fast and with outstanding quality.
              </Trans>
            </p>
            <p className="text-[14] opacity-[0.7]">
              <Trans i18nKey="description.aboutpart4">
                {" "}
                We are aware that companies are experiencing an important cargo
                process in the background. We set out with the idea of ​​making
                your life easier and managing all your cargo processes. While
                providing an easy shipping experience to cargo companies, we
                offer the most suitable cargo alternatives with dynamic pricing
                and fast delivery.
              </Trans>
            </p>

            <p className="text-[14] opacity-[0.7]">
              <Trans i18nKey="description.aboutpart6">
                Your shipping process is much easier with us!
              </Trans>
            </p>
          </article>
        </div>
        <div>
          <img src={box} alt="" />
        </div>
      </div>
      {/* <Footer /> */}
      <Footer />
    </div>
  );
};


export default About;