import React from 'react'
import Navbar from "../../components/Home/NavBar";
import Questions from '../../components/Home/Questions';
import Footer from '../../components/Home/Footer';
const FrequentlyAQ = () => {
  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
    <Navbar />
    <div className="w-[100%] mx-auto">
      <Questions />
      <div className="Footer">
        <Footer faqID={"/"} supportID={"/contact"} />
      </div>
    </div>
  </div>
  )
}

export default FrequentlyAQ