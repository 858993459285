import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import logo from "../../assets/logo/kavvay_new_logo.svg";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
import { forgotPassword } from "../../redux/slices/userSlice";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailholder, setEmailholder] = useState("");

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language == "en") {
      setEmailholder("Enter your email");
    } else {
      setEmailholder("E-postanızı giriniz");
    }
  }, [i18n.language]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const emailData  = {
      email,
    }

    try {
      const response = await dispatch(forgotPassword({ emailData }));
      if (response && response.payload) {
        Swal.fire({
          title: "Success",
          html: "Password succesfully sent to email!",
          timer: 2000,
          timerProgressBar: true,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          didClose: () => {
            // Redirect to the desired URL after 2 seconds
            window.location.assign("/login"); // Replace with your desired URL
          },
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Try again later!",
        icon: "error",
      });
    }
  };
  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
      <Navbar />
      <div className="w-[50%] flex flex-col justify-center items-center mx-auto my-24 lg:my-32">
        <img className="w-[200px]" src={logo} alt="Logo" />
        <h1 className="text-[24px] my-4">
          <Trans i18nKey="description.Rpoart0">Reset Password</Trans>
        </h1>
        <form onSubmit={handleResetPassword} className="w-[100%]">
          <div className="w-[100%]">
            <label className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2">
              <Trans i18nKey="description.registerpart9">E-posta:</Trans>
            </label>
            <input
              className="w-[100%] text-[14px] px-[20px] py-[14px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
              type="email"
              placeholder={emailholder}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button
            type="submit"
            className="w-[100%] flex text-white mt-2 items-center justify-center bg-indigo-500 rounded-[5px] cursor-pointer border-none px-[10px] py-[10px] text-[18px]"
          >
            <Trans i18nKey="description.Rpoart0">Reset Password</Trans>
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
