import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { subscribeCreate } from "../../../redux/slices/subscribeSlice";
import { packageList } from "../../../redux/slices/packageSlice";
import {Link} from "react-router-dom";
import Loader from "../Agent/SubscriptionLoader";
const Subscription = ({ data }) => {
  const { i18n } = useTranslation();
  const [loadingStates, setLoadingStates] = useState({});
  const dispatch = useDispatch();
  const { data: packageData, loading } = useSelector((state) => state.package);
  useEffect(() => {
    dispatch(packageList());
  }, [dispatch]);

  const packagesEng = [
    {
      id: 1,
      name: "Starter",
      description: "Standard plan",
      features: [
        "Up to 350 shipments per month",
        "Basic features",
        "2 Carrier selection",
        "1 Store connection",
        "1 User seat",
      ],
      monthly: {
        amount: 75,
        type: 1,
        currency: "TRY",
        interval: "",
      },
      annual: {
        amount: 25,
        type: 2,
        currency: "TRY",
        interval: "",
      },
    },
    {
      id: 2,
      name: "Silver",
      description: "Premium plan",
      features: [
        "Up to 2000 shipments per month",
        "Standart features",
        "Multiple Carrier selection",
        "1 Store connection",
        "1 User seat",
      ],
      monthly: {
        amount: 350,
        type: 1,
        currency: "TRY",
        interval: "",
      },
      annual: {
        amount: 45,
        type: 2,
        currency: "TRY",
        interval: "",
      },
    },
    {
      id: 3,
      name: "Gold",
      features: [
        "Up to 10000 shipments per month",
        "Advanced features",
        "Multi-Carrier selection",
        "5 User seat",
        "5 Store connection",
        "API",
        "Webhooks",
      ],
      description: "Premium plan",
      monthly: {
        amount: 1500,
        type: 1,
        currency: "TRY",
        interval: "",
      },
      annual: {
        amount: 75,
        type: 2,
        currency: "TRY",
        interval: "",
      },
    },
    {
      id: 4,
      name: "Platinum ",
      description: "Platinum plan",
      isHidden: true,
      features: [
        "Unlimited shipments per month",
        "Complete  features",
        "Unlimited Carrier selection connection",
        "Unlimited User seat",
        "Unlimited store",
        "API",
        "Webhooks",
      ],
      monthly: {
        amount: "",
        currency: "TRY",
        type: 1,
        interval: "",
        isAmountHidden: true,
        description: "Contact us",
      },
      annual: {
        amount: "",
        currency: "TRY",
        type: 2,
        interval: "",
        isAmountHidden: true,
        description: "Contact us",
      },
    },
  ];  

  const packagesTr = [
    {
      id: 1,
      name: "Baslangıç",
      description: "Standard plan",
      features: [
        "350 gönderi oluşturma",
        "2 Kargo şirketi seçimi",
        "1 Mağaza Entegrasyonu",
        "1 Kullanıcı",
      ],
      monthly: {
        amount: 75,
        type: 1,
        currency: "TRY",
        interval: "",
      },
      annual: {
        amount: 65,
        type: 2,
        currency: "TRY",
        interval: "",
      },
    },
    {
      id: 2,
      name: "Gümüş",
      description: "Premium plan",
      features: [
        "2000 gönderi oluşturma",
        "Standart özellikler",
        "Çoklu Kargo şirketi seçimi",
        "1 Mağaza Entegrasyonu",
        "1 Kullanıcı",
      ],
      monthly: {
        amount: 350,
        type: 1,
        currency: "TRY",
        interval: "",
      },
      annual: {
        amount: 300,
        type: 2,
        currency: "TRY",
        interval: "",
      },
    },
    {
      id: 3,
      name: "Altın",
      features: [
        "10000 gönderi oluşturma",
        "Gelişmiş özellikler",
        "Çoklu Kargo şirketi seçme",
        "5 Mağaza Entegrasyonu",
        "5 Kullanıcı",
        "API",
        "Webhooks",
      ],
      description: "Premium plan",
      monthly: {
        amount: 1500,
        type: 1,
        currency: "TRY",
        interval: "",
      },
      annual: {
        amount: 1271,
        type: 2,
        currency: "TRY",
        interval: "",
      },
    },
    {
      id: 4,
      name: "Platinium",
      description: "Platinium plan",
      isHidden: true,
      features: [
        "Limitsiz gönderi oluşturma",
        "Tüm özellikler",
        "Limitsiz Kargo şirketi seçme",
        "Limitsiz Mağaza Entegrasyonu",
        "Limitsiz Kullanıcı",
        "API",
        "Webhooks",
      ],
      monthly: {
        amount: "",
        currency: "TRY",
        type: 1,
        interval: "",
        isAmountHidden: true,
        description: "Contact us",
      },
      annual: {
        amount: "",
        currency: "TRY",
        type: 2,
        interval: "",
        isAmountHidden: true,
        description: "Contact us",
      },
    },
  ];

  const [packages, setPackages] = useState(packagesEng);

  useEffect(() => {
    if (i18n.language === "en") {
      setPackages(packagesEng);
    } else if (i18n.language === "tr") {
      setPackages(packagesTr);
    } else {
      setPackages(packagesEng);
    }
  }, [i18n.language, packagesEng, packagesTr, setPackages]);

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));

    try {
      const subscribe = {
        packageId: id,
      };
      if (data) {
        const response = await dispatch(subscribeCreate({ subscribe }));
        if (response && response.payload.returnUrl) {
          const responseUrl = response.payload.returnUrl;
          localStorage.setItem(
            "paymesOrderId",
            response?.payload?.paymesOrderId
          );
          window.location.assign(responseUrl);
        } else {
          console.error("Invalid or missing response URL property");
        }
      } else {
        window.location.replace = "/login";
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }
  };
  return (
    <div className="pt-4 flex-col py-4 z-40" id="packages">
      <h3 className="font-semibold text-[#455694] text-center my-2 text-[25px]">
        <Trans i18nKey="description.subscriptionhometitle">Subscriptions</Trans>
      </h3>
      {loading ? (
        <div>  
          Loading...
        </div>
      ) : (
        <div className="py-4 subscriptioncards">
          {packages.map((item, i) => (
            <div
              key={i}
              className="p-2 w-[90%] mx-auto bg-white subscriptionboxshadow rounded-[5px] cursor-pointer my-2 transition-transform transform hover:scale-105"
            >
              <h5 className="text-xl font-medium text-indigo-400 p-4">
                {item?.name}
              </h5>
              {item?.annual?.isAmountHidden ? (
                <div className="flex items-baseline text-gray-900 py-2 px-4 ">
                  <span className="text-2xl font-semibold">
                    <Trans i18nKey="description.subscriptionhomecontact"></Trans>
                  </span>
                </div>
              ) : (
                <div className="flex items-baseline text-gray-900 py-2 px-4">
                  <span className="text-4xl font-extrabold tracking-tight">
                    {packageData?.find((p) => p.id === item?.id)?.annuallyPrice}
                  </span>
                  <span className="ml-1  font-normal text-gray-500 ">
                  {item.interval}  TRY /
                    <Trans i18nKey="description.subscriptionhomeprcingmethod">
                    </Trans>
                  </span>
                </div>
              )}

              <ul className="space-y-5 p-4">
                {item?.features?.map((feature, i) => (
                  <li className="flex space-x-3 items-center" key={i}>
                    <svg
                      className="flex-shrink-0 w-8 h-8 text-blue-600 dark:text-blue-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    <span className="text-base font-normal leading-tight text-gray-500 text-[14px]">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
              {item?.isHidden ? (
               <Link
               to="/contact"
               className="absolute  bottom-4 left-6 right-6 p-6 mt-12 text-blue-500 hover:text-white text-center bg-blue-200 hover:bg-blue-500  font-medium rounded-[5px] text-sm px-5 py-2.5 mt-auto"
             >
               <Trans i18nKey="description.subscriptionhomecontact3">
                      Subscriptions
                    </Trans>
             </Link>
              ) : (
                <div className="mt-12 px-4 mx-4 ">
                  {loadingStates[item.id] ? (
                    <Loader />
                  ) : (
                    <div
                      className="absolute  bottom-4 left-6 right-6 p-6 mt-12 text-blue-500 hover:text-white text-center bg-blue-200 hover:bg-blue-500  font-medium rounded-[5px] text-sm px-5 py-2.5 mt-auto"
                      onClick={(e) => handleSubmit(e, item?.id, item?.type)}
                    >
                     <Trans i18nKey="description.subscriptionhomecontact2">
                      Subscriptions
                    </Trans>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Subscription;
