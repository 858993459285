import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { registerUser } from "../../redux/slices/authSlice";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const { i18n } = useTranslation();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setpasswordAgain] = useState("");
  const [phone, setPhone] = useState("");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== passwordAgain) {
      toast.error("Passwords do not match");
      return;
    }

    const passwordStrengthRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordStrengthRegex.test(password)) {
      toast.error(
        "Password must be at least 8 characters long and contain a mix of uppercase and lowercase letters and numbers."
      );
      return;
    }
  

    try {
      const userData = { name, surname, email, password, passwordAgain, phone };
      const response = await registerUser(userData);
      if (response) {
        Swal.fire({
          title: "Success",
          html: "You have successfully registered!",
          timer: 2000,
          timerProgressBar: true,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          didClose: () => {
            window.location.assign("/login");
          },
        });
      } else {
        swal("Error!", response?.message || "Unknown error occurred", "error");
      }
    } catch (error) {
      swal(
        "Error!",
        error.message
          ? "User with this e-mail already exist"
          : "Please try again!",
        "error"
      );
      console.error(error);
    }
  };

  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
      <Navbar />
      <div className="flex  w-[85%] lg:w-[80%] mx-auto my-12  flex-col lg:flex-row justify-between  items-center  gap-8  py-16 py-8 ">
        <div className="w-[100%] lg:w-[60%]  mx-auto">
          <h2 className="font-extrabold text-center text-[24px] lg:text-[32px]">
            <Trans i18nKey="description.registerpart4">Kayıt Olun</Trans>
          </h2>

          <h3 className="text-[20px] text-center pt-[10px] opacity-[0.7]">
            <Trans i18nKey="description.registerpart3">
              KAVVAY ile yurtdışı gönderileriniz daha hızlı
            </Trans>
          </h3>
          <div className="text-center flex items-center justify-center gap-2">
            <p>
              <Trans i18nKey="description.registerpart5">veya</Trans>
            </p>
            <NavLink className="pl-[5px] text-[#0000ff]" to="/login">
              <Trans i18nKey="description.registerpart6">Giriş Yapın</Trans>
            </NavLink>
          </div>

          <form onSubmit={handleRegister}>
            <div className="pt-2">
              <label
                htmlFor="firstName"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold py-2"
              >
                <Trans i18nKey="description.registerpart7">İsim</Trans>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder={i18n.t("description.orderinputplaceholder1")}
                value={name}
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="pt-2">
              <label
                htmlFor="lastName"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart8">Soyisim:</Trans>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder={i18n.t("description.orderinputplaceholder2")}
                value={surname}
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                onChange={(e) => setSurname(e.target.value)}
                required
              />
            </div>
            <div className="pt-2">
              <label
                htmlFor="email"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart9">E-posta:</Trans>
              </label>
              <input
                type="email"
                value={email}
                placeholder={i18n.t("description.orderinputplaceholder3")}
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="pt-2">
              <label
                htmlFor="telephone"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart10"> Telefon:</Trans>
              </label>
              <PhoneInput
                international
                defaultCountry="TR"
                onChange={setPhone}
                id="phone"
                name="phone"
                className="numinput"
                required
              />
            </div>

            <div className="pt-2">
              <label
                htmlFor="password"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart11">Parola:</Trans>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder={i18n.t("description.registerinputplaceholder1")}
                value={password}
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="pt-2">
              <label
                htmlFor="confirmPassword"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart12">
                  Parola Tekrar:
                </Trans>
              </label>
              <input
                type="password"
                id="confirmPassword"
                placeholder={i18n.t("description.registerinputplaceholder2")}
                name="confirmPassword"
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                value={passwordAgain}
                onChange={(e) => setpasswordAgain(e.target.value)}
                required
              />
            </div>

            <div className="flex flex-row justify-between m-[5px] pt-[10px] gap-2">
              <label className="gap-2 cursor-pointer">
                <input
                  required
                  type="checkbox"
                  className="w-[20px] h-[20px] cursor-pointer"
                />
              </label>
              <div>
                <div className="flex flex-row">
                  <p>
                    <NavLink to="/GDPR" className="text-[#0000ff]">
                      <Trans i18nKey="description.registerpart131">
                        Kişisel Verilerin Korunması
                      </Trans>
                    </NavLink>
                    ,
                    <NavLink to="/privacypolicy" className="text-[#0000ff]">
                      <Trans i18nKey="description.registerpart132">
                        Ticari Elektronik İleti Onay Metni
                      </Trans>
                    </NavLink>
                    ,
                    <NavLink
                      to="/Termsandconditions"
                      className="text-[#0000ff]"
                    >
                      <Trans i18nKey="description.registerpart133">
                        Kullanıcı Sözleşmesi
                      </Trans>
                    </NavLink>
                    ,
                    <NavLink to="/Cookiepolicy" className="text-[#0000ff]">
                      <Trans i18nKey="description.registerpart134">
                        Çerez Politikası
                      </Trans>
                    </NavLink>
                    ,
                    <Trans i18nKey="description.registerpart14">
                      okudum ve onaylıyorum.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              {/* Your other input fields go here */}
              <button
                type="submit"
                className="w-[100%] flex items-center justify-center bg-indigo-500 rounded-[5px] cursor-pointer border-none px-[10px] py-[10px] text-[18px]"
              >
                <span className="flex-1 text-white">
                  <Trans i18nKey="description.registerpart15">Kayıt Ol</Trans>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
