import React from "react";
import MagentoLogo from "../../assets/brands/magentoLogo.svg";
import OpenCartLogo from "../../assets/brands/opencartLogo.svg";
import WooCommerceLogo from "../../assets/brands/woocommerceLogo.svg";
import ShopifyIconLogo from "../../assets/brands/shopifyLogo.svg";
import { Trans } from "react-i18next";
const Integration = () => {
  return (
    <div className="mt-4">
      <h3 className="font-semibold text-[#455694] text-center my-2 text-[25px]">
        <Trans i18nKey="description.homepart4">INTEGRATIONS </Trans>
      </h3>
      <div className="flex flex-col  items-center pt-4">
        <div className="text-center lg:text-left pt-8 lg:pt-0 w-[70%]">
          <h3 className="text-[20px] font-bold text-center">
            <Trans i18nKey="description.homepart5">
              Speed up your shipments with Kavvay and start sending your
              shipments at affordable prices.
            </Trans>
          </h3>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 mt-2 gap-8">
          <div className="bg-[#fff] cursor-pointer sidebarshadow rounded-[20px] w-[120px] min-h-[100px] p-4 mt-2 flex items-center justify-center">
            <img src={MagentoLogo} alt="magentoLogo" />
          </div>
          <div className="bg-[#fff] cursor-pointer sidebarshadow rounded-[20px] w-[120px] min-h-[100px] p-4 mt-2 flex items-center justify-center">
            <img src={OpenCartLogo} alt="opencartLogo" />
          </div>
          <div className="bg-[#fff] cursor-pointer sidebarshadow rounded-[20px] w-[120px] min-h-[100px] p-4 mt-2 flex items-center justify-center">
            <img src={WooCommerceLogo} alt="woocommercelogo" />
          </div>
          <div className="bg-[#fff] cursor-pointer sidebarshadow rounded-[20px] w-[120px] min-h-[100px] p-4 mt-2 flex items-center justify-center">
            <img src={ShopifyIconLogo} alt="shopifylogo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
