import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  announcementDetails: null,
  announcementDetailsLoading: false,
  announcementDetailsError: null,
  announcementUpdate: null,
  announcementUpdateLoading: false,
  announcementUpdateError: null,
  announcementDelete: null,
  announcementDeleteLoading: false,
  announcementDeleteError: null,
  announcementCreate: null,
  announcementCreateLoading: false,
  announcementCreateError: null,
};

export const announcementList = createAsyncThunk(
  "announcement/announcementList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/GetAnnouncements`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const announcementDetails = createAsyncThunk(
  "announcement/announcementDetails",
  async ({ id }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/GetAnnouncements?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const announcementUpdate = createAsyncThunk(
  "announcement/announcementUpdate",
  async (
    { updatedAnnouncement },
    {rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/UpdateAnnouncement`,
        updatedAnnouncement,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
     throw new Error(error.response.data.message);
    }
  }
);

// export const announcementDelete = createAsyncThunk(
//   "announcement/announcementDelete",
//   async ({ id }: { id: number }, { getState, rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.delete(
//         `${API_URL}api/announcements/delete/${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error: any) {
//       console.log(rejectWithValue(error.response.data));
//     }
//   }
// );


export const announcementCreate = createAsyncThunk(
  "announcement/announcementCreate",
  async ({ announcement }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/CreateAnnouncement`,
        announcement,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const announcementSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(announcementList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(announcementList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(announcementList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(announcementDetails.pending, (state) => {
        state.announcementDetailsLoading = true;
        state.announcementDetailsError = null;
      })
      .addCase(announcementDetails.fulfilled, (state, action) => {
        state.announcementDetailsLoading = false;
        state.announcementDetails = action.payload;
      })
      .addCase(announcementDetails.rejected, (state, action) => {
        state.announcementDetailsLoading = false;
        state.announcementDetailsError = action.payload;
      })
      .addCase(announcementCreate.pending, (state) => {
        state.announcementCreateLoading = true;
        state.announcementCreateError = false;
      })
      .addCase(announcementCreate.fulfilled, (state, action) => {
        state.announcementCreateLoading = false;
        state.announcementCreateSuccess = true;
      })
      .addCase(announcementCreate.rejected, (state, action) => {
        state.announcementCreateLoading = false;
        state.announcementCreateError = true;
      })
      .addCase(announcementUpdate.pending, (state) => {
        state.announcementUpdateLoading = true;
        state.announcementUpdateError = false;
      })
      .addCase(announcementUpdate.fulfilled, (state, action) => {
        state.announcementUpdateLoading = false;
        state.announcementUpdateSuccess = true;
      })
      .addCase(announcementUpdate.rejected, (state, action) => {
        state.announcementUpdateLoading = false;
        state.announcementUpdateError = true;
      })
  },
});

// Export actions and reducer
export const announcementActions = announcementSlice.actions;
export default announcementSlice.reducer;
