import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  paymentDetails: null,
  paymentDetailsLoading: false,
  paymentDetailsError: null,
  paymentUpdate: null,
  paymentUpdateLoading: false,
  paymentUpdateError: null,
  paymentDelete: null,
  paymentDeleteLoading: false,
  paymentDeleteError: null,
  paymentCreate: null,
  paymentCreateLoading: false,
  paymentCreateError: null,
};

export const paymentList = createAsyncThunk(
  "payment/paymentList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/GetPayments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const paymentDetails = createAsyncThunk(
  "payment/paymentDetails",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/FAQ/GetFAQs?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const paymentUpdate = createAsyncThunk(
  "payment/paymentUpdate",
  async ({ updatedPayment }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/FAQ/UpdateFAQ`,
        updatedPayment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// export const paymentDelete = createAsyncThunk(
//   "payment/paymentDelete",
//   async ({ id }: { id: number }, { getState, rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.delete(
//         `${API_URL}api/payments/delete/${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error: any) {
//       console.log(rejectWithValue(error.response.data));
//     }
//   }
// );

export const paymentCreate = createAsyncThunk(
  "payment/paymentCreate",
  async ({ payment }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/FAQ/CreateFAQ`,
        payment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

// Create the auth slice
const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(paymentList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(paymentList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(paymentList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(paymentDetails.pending, (state) => {
        state.paymentDetailsLoading = true;
        state.paymentDetailsError = null;
      })
      .addCase(paymentDetails.fulfilled, (state, action) => {
        state.paymentDetailsLoading = false;
        state.paymentDetails = action.payload;
      })
      .addCase(paymentDetails.rejected, (state, action) => {
        state.paymentDetailsLoading = false;
        state.paymentDetailsError = action.payload;
      })
      .addCase(paymentCreate.pending, (state) => {
        state.paymentCreateLoading = true;
        state.paymentCreateError = false;
      })
      .addCase(paymentCreate.fulfilled, (state, action) => {
        state.paymentCreateLoading = false;
        state.paymentCreateSuccess = true;
      })
      .addCase(paymentCreate.rejected, (state, action) => {
        state.paymentCreateLoading = false;
        state.paymentCreateError = true;
      })
      .addCase(paymentUpdate.pending, (state) => {
        state.paymentUpdateLoading = true;
        state.paymentUpdateError = false;
      })
      .addCase(paymentUpdate.fulfilled, (state, action) => {
        state.paymentUpdateLoading = false;
        state.paymentUpdateSuccess = true;
      })
      .addCase(paymentUpdate.rejected, (state, action) => {
        state.paymentUpdateLoading = false;
        state.paymentUpdateError = true;
      });
  },
});

// Export actions and reducer
export const paymentActions = paymentSlice.actions;
export default paymentSlice.reducer;
