import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/kavvay_new_logo.svg";
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { detailsUser } from "../../redux/slices/userSlice";
import { Squash as Hamburger } from "hamburger-react";
import SubNavbar from "./SubNavbar";

const Navbar = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleDropdownChange = (event) => {
    const selectedLanguage = event.target.value;
    setSelectedLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  const availableLanguages = {
    en: "EN",
    tr: "TR",
  };

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);

  const [click, setClick] = useState(false);

  return (
    <>
      <nav className="sticky top-0 z-50 bg-white">
        <div className="flex items-center justify-between">
          <Link
            exact
            to="/"
            className="w-36 h-16 flex items-center justify-center"
          >
            <img src={Logo} alt="" className="w-100 h-100" />
          </Link>

          {/* Desktop Navigation */}
          <ul className={`hidden lg:flex justify-between items-center gap-4`}>
            <SubNavbar />
            {user ? (
              <li className="bg-indigo-500 rounded-[5px] border-[1px] border-slate-400 text-white px-4 py-2">
                <Link to="/dashboard">
                  {" "}
                  <button className="">
                    <Trans i18nKey="">
                      {user?.name} {user?.surname}
                    </Trans>
                  </button>{" "}
                </Link>
              </li>
            ) : (
              <>
                <li>
                  <Link to="/login" className="nav-links px-4 py-2 rounded-[5px] border-[1px] border-slate-400">
                    <Trans i18nKey="description.navpart5"> Giriş yap </Trans>
                  </Link>
                </li>
                <li >
                  <Link to="/register" className="nav-links bg-indigo-500 rounded-[5px] border-[1px] border-slate-400 text-white px-4 py-2">
                    <Trans i18nKey="description.navpart6">Register</Trans>
                  </Link>
                </li>
              </>
            )}

            <div className="lg:block hidden">
              <div className="relative">
                <select
                  value={selectedLanguage}
                  onChange={(e) => handleDropdownChange(e)}
                  className="cursor-pointer flex border-[1px] border-slate-300 rounded-[5px] px-4 py-[10px] text-[14px]  outline-indigo-400"
                >
                  {Object.keys(availableLanguages).map((languageCode) => (
                    <option
                      key={languageCode}
                      value={languageCode}
                      className="cursor-pointer border-[1px] border-slate-300"
                    >
                      {availableLanguages[languageCode]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </ul>

          {/* Mobile Sidebar */}
          <div className="lg:hidden block">
            <Hamburger toggled={click} toggle={setClick} />
            {click && (
              <ul className="fixed top-0 left-0 w-[80%] h-full bg-white sidebarshadow p-4 flex flex-col z-50 ">
                <Link
                  exact
                  to="/"
                  className="w-36 h-16 pt-2 flex flex-start items-start justify-start"
                >
                  <img src={Logo} alt="" className="w-100 h-100" />
                </Link>

                {user ? (
                  <Link to="/dashboard">
                    <li className="bg-indigo-500 rounded-[10px] border-[1px] border-slate-400 text-white px-4 py-2">
                      {" "}
                      <Trans i18nKey="">
                        {user?.name} {user?.surname}
                      </Trans>
                    </li>
                  </Link>
                ) : (
                  <>
                    <li className="px-4 py-2 rounded-[10px] border-[1px] border-slate-400">
                      <Link to="/login" className="nav-links">
                        <Trans i18nKey="description.navpart5">
                          {" "}
                          Giriş yap{" "}
                        </Trans>
                      </Link>
                    </li>
                    <li className="bg-indigo-500 rounded-[10px] border-[1px] border-slate-400 text-white px-4 py-2 mt-2">
                      <Link to="/register" className="nav-links">
                        <Trans i18nKey="description.navpart6">Register</Trans>
                      </Link>
                    </li>
                  </>
                )}
                <SubNavbar />
                <li className="nav-item dropdown-item  relative">
                  <div className="">
                    <select
                      value={selectedLanguage}
                      onChange={(e) => handleDropdownChange(e)}
                      className="cursor-pointer flex border-[1px] border-slate-300 rounded-[5px] px-4 py-[4px] text-[14px] mt-2 outline-indigo-400"
                    >
                      {Object.keys(availableLanguages).map((languageCode) => (
                        <option
                          key={languageCode}
                          value={languageCode}
                          className="cursor-pointer border-[1px] border-slate-300"
                        >
                          {availableLanguages[languageCode]}
                        </option>
                      ))}
                    </select>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
      <div className="w-[100%] h-[1px] bg-slate-300"></div>
    </>
  );
};

export default Navbar;
