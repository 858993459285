import { lazy } from "react";
const Dashboard = lazy(() => import("../pages/Dashboard/Shared/Home"));
const Profile = lazy(() => import("../pages/Dashboard/Shared/Profile"));
const OrderList = lazy(() => import("../pages/Dashboard/Shared/OrderList"));
const OrderDetails = lazy(() => import("../pages/Dashboard/Shared/OrderDetails"));
const OrderCreate = lazy(() => import("../pages/Dashboard/Shared/OrderCreate"));
const AgentList = lazy(() => import("../pages/Dashboard/SuperAdmin/AgentList"));
const AgentCreate = lazy(() => import("../pages/Dashboard/SuperAdmin/AgentCreate"));
const AgentEdit = lazy(() => import("../pages/Dashboard/SuperAdmin/AgentEdit"));
const ProviderList = lazy(() => import("../pages/Dashboard/SuperAdmin/ProviderList"));
const ProviderCreate = lazy(() => import("../pages/Dashboard/SuperAdmin/ProviderCreate"));
const ProviderEdit = lazy(() => import("../pages/Dashboard/SuperAdmin/ProviderEdit"));
const PackageList = lazy(() => import("../pages/Dashboard/SuperAdmin/PackageList"));
const PackageCreate = lazy(() => import("../pages/Dashboard/SuperAdmin/PackageCreate"));
const PackageEdit = lazy(() => import("../pages/Dashboard/SuperAdmin/PackageEdit"));
const SubscriptionList = lazy(() => import("../pages/Dashboard/SuperAdmin/SubscriptionList"));
const SubscriptionCreate = lazy(() => import("../pages/Dashboard/SuperAdmin/SubscriptionCreate"));
const SubscriptionEdit = lazy(() => import("../pages/Dashboard/SuperAdmin/SubscriptionEdit"));
const AnnouncementList = lazy(() => import("../pages/Dashboard/SuperAdmin/AnnouncementList"));
const AnnouncementCreate = lazy(() => import("../pages/Dashboard/SuperAdmin/AnnouncementCreate"));
const AnnouncementEdit = lazy(() => import("../pages/Dashboard/SuperAdmin/AnnouncementEdit"));
const PaymentList = lazy(() => import("../pages/Dashboard/SuperAdmin/PaymentList"));
const FAQList = lazy(() => import("../pages/Dashboard/SuperAdmin/FAQList"));
const FAQCreate = lazy(() => import("../pages/Dashboard/SuperAdmin/FAQCreate"));
const FAQEdit = lazy(() => import("../pages/Dashboard/SuperAdmin/FAQEdit"));
const CountryList = lazy(() => import("../pages/Dashboard/SuperAdmin/CountryList"));
const CustomerDetails = lazy(() => import("../pages/Dashboard/Shared/CustomerDetails"));
const PriceCalculator = lazy(() => import("../pages/Dashboard/Shared/PriceCalculator"));
const AgentFAQList = lazy(() => import("../pages/Dashboard/Agent/AgentFaqList"));

const routes = [
  {
    path: "/dashboard",
    title: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/profile",
    title: "Profile",
    component: Profile,
  },
  {
    path: "/orderlist",
    title: "OrderList",
    component: OrderList,
  },
  {
    path: "/order/edit/:id",
    title: "OrderDetails",
    component: OrderDetails,
  },
  {
    path: "/order/create",
    title: "OrderCreate",
    component: OrderCreate,
  },
  {
    path: "/agentlist",
    title: "AgentList",
    component: AgentList,
  },
  {
    path: "/agent/edit/:id",
    title: "AgentDetails",
    component: AgentEdit,
  },
  {
    path: "/agent/create",
    title: "AgentCreate",
    component: AgentCreate,
  },
  {
    path: "/agentlist",
    title: "AgentList",
    component: AgentList,
  },
  {
    path: "/agent/edit/:id",
    title: "AgentDetails",
    component: AgentEdit,
  },
  {
    path: "/agent/create",
    title: "AgentCreate",
    component: AgentCreate,
  },
  {
    path: "/provider/create",
    title: "ProviderCreate",
    component: ProviderCreate,
  },
  {
    path: "/provider/edit/:id",
    title: "ProviderEdit",
    component: ProviderEdit,
  },
  {
    path: "/providerlist",
    title: "ProviderList",
    component: ProviderList,
  },
  {
    path: "/package/create",
    title: "PackageCreate",
    component: PackageCreate,
  },
  {
    path: "/package/edit/:id",
    title: "PackageEdit",
    component: PackageEdit,
  },
  {
    path: "/packagelist",
    title: "PackageList",
    component: PackageList,
  },
  {
    path: "/subscription/create",
    title: "SubscriptionCreate",
    component: SubscriptionCreate,
  },
  {
    path: "/subscription/edit/:id",
    title: "SubscriptionEdit",
    component: SubscriptionEdit,
  },
  {
    path: "/subscriptionlist",
    title: "SubscriptionList",
    component: SubscriptionList,
  },
  {
    path: "/package/create",
    title: "PackageCreate",
    component: PackageCreate,
  },
  {
    path: "/package/edit/:id",
    title: "PackageEdit",
    component: PackageEdit,
  },
  {
    path: "/packagelist",
    title: "PackageList",
    component: PackageList,
  },
  {
    path: "/subscription/create",
    title: "SubscriptionCreate",
    component: SubscriptionCreate,
  },
  {
    path: "/subscription/edit/:id",
    title: "SubscriptionEdit",
    component: SubscriptionEdit,
  },
  {
    path: "/subscriptionlist",
    title: "SubscriptionList",
    component: SubscriptionList,
  },
  {
    path: "/package/create",
    title: "PackageCreate",
    component: PackageCreate,
  },
  {
    path: "/package/edit/:id",
    title: "PackageEdit",
    component: PackageEdit,
  },
  {
    path: "/packagelist",
    title: "PackageList",
    component: PackageList,
  },
  {
    path: "/subscription/create",
    title: "SubscriptionCreate",
    component: SubscriptionCreate,
  },
  {
    path: "/subscription/edit/:id",
    title: "SubscriptionEdit",
    component: SubscriptionEdit,
  },
  {
    path: "/subscriptionlist",
    title: "SubscriptionList",
    component: SubscriptionList,
  },
  {
    path: "/package/create",
    title: "PackageCreate",
    component: PackageCreate,
  },
  {
    path: "/package/edit/:id",
    title: "PackageEdit",
    component: PackageEdit,
  },
  {
    path: "/packagelist",
    title: "PackageList",
    component: PackageList,
  },
  {
    path: "/subscription/create",
    title: "SubscriptionCreate",
    component: SubscriptionCreate,
  },
  {
    path: "/subscription/edit/:id",
    title: "SubscriptionEdit",
    component: SubscriptionEdit,
  },
  {
    path:  "/subscriptionlist",
    title: "SubscriptionList",
    component: SubscriptionList,
  },
  {
    path:  "/announcement/create",
    title: "AnnouncementCreate",
    component: AnnouncementCreate,
  }
  ,
  {
    path:  "/announcement/edit/:id",
    title: "AnnouncementEdit",
    component: AnnouncementEdit,
  }
  ,
  {
    path:  "/announcementlist",
    title: "AnnouncementList",
    component: AnnouncementList,
  }
  ,
  {
    path:  "/paymentlist",
    title: "PaymentList",
    component: PaymentList,
  }
,
  {
    path:  "/faq/edit/:id",
    title: "FAQEdit",
    component: FAQEdit,
  }
  ,
  {
    path:  "/faqlist",
    title: "FAQList",
    component: FAQList,
  }
  ,
  {
    path:  "/faq/create",
    title: "FAQCreate",
    component: FAQCreate,
  },
  {
    path:  "/countrylist",
    title: "CountryList",
    component:CountryList,
  },
  {
    path:  "/customerdetails",
    title: "CustomerDetails",
    component: CustomerDetails,
  }
  ,
  {
    path:  "/pricecalculation",
    title: "PriceCalculator",
    component: PriceCalculator,
  }
  ,
  {
    path:  "/agent/faqlist",
    title: "Agent Faq List",
    component: AgentFAQList,
  }
  
  
];


export default routes;