import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";
const initialState = {
  data: null,
  loading: null,
  error: null,
  contactCreate: null,
  contactCreateLoading: false,
  contactCreateError: null,
};

export const contactDetails = createAsyncThunk(
  `/contact/contactDetails`,
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/GetKavvayContact`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);


export const contactCreate = createAsyncThunk(
  "contact/contactCreate",
  async ({ contact }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/ReceiveEmails`,
        contact,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(contactDetails.pending, (state) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(contactDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(contactDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
     })
      .addCase(contactCreate.pending, (state) => {
        state.contactCreateLoading = true;
        state.contactCreateError = false;
      })
      .addCase(contactCreate.fulfilled, (state, action) => {
        state.contactCreateLoading = false;
        state.contactCreate = action.payload;
      })
      .addCase(contactCreate.rejected, (state, action) => {
        state.contactCreateLoading = false;
        state.contactCreateError = true;
      });
  },
});

// Export actions and reducer
export const contactActions = contactSlice.actions;
export default contactSlice.reducer;
