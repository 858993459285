import React from "react";
import { FaInstagram, FaFacebookSquare, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import PaymentLogo from "../../assets/images/paymentList.png";
import { Trans } from "react-i18next";
import Logo from "../../assets/logo/kavvay_new_logo.svg";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="w-[95%] lg:w-[100%] mx-auto mt-4 mb-8">
      <div className="flex  lg:flex-row flex-col justify-between my-[10px] footer">
        <div>
          <Link
            exact
            to="/"
            className="w-36 h-16 flex items-center justify-center"
          >
            <img src={Logo} alt="" className="w-100 h-100" />
          </Link>
        </div>
        <div className="">
          <h3 className="font-bold">
            <Trans i18nKey="description.footerpart0">Company</Trans>
          </h3>
          <ul className="">
            <Link to="/about">
              {" "}
              <li
                onClick={scrollToTop}
                className="pt-2 mt-2 hover:text-indigo-500 cursor-pointer"
              >
                <Trans i18nKey="description.footerpart1">About</Trans>
              </li>
            </Link>
            <Link to="/faq">
              <li
                onClick={scrollToTop}
                className="pt-2 mt-2 hover:text-indigo-500 cursor-pointer"
              >
                <Trans i18nKey="description.footerpart2">FAQ</Trans>
              </li>
            </Link>
          </ul>
        </div>
        <div className="my-8 lg:my-0">
          <h3 className="font-bold">
            <Trans i18nKey="description.footerpart4">Legal</Trans>
          </h3>
          <ul>
            <Link to="/gdpr">
              <li
                onClick={scrollToTop}
                className="pt-2 mt-2 hover:text-indigo-500 cursor-pointer"
              >
                <Trans i18nKey="description.footerpart5">GDPR</Trans>
              </li>
            </Link>
            <Link to="/privacypolicy">
              <li
                onClick={scrollToTop}
                className="pt-2 mt-2 hover:text-indigo-500 cursor-pointer"
              >
                <Trans i18nKey="description.footerpart6">Privacy Policy</Trans>
              </li>
            </Link>
            <Link to="/termsandconditions">
              <li
                onClick={scrollToTop}
                className="pt-2 mt-2 hover:text-indigo-500 cursor-pointer"
              >
                <Trans i18nKey="description.footerpart7">
                  Terms and Conditions
                </Trans>
              </li>
            </Link>
            <Link to="/cookiepolicy">
              <li
                onClick={scrollToTop}
                className="pt-2 mt-2 hover:text-indigo-500 cursor-pointer"
              >
                <Trans i18nKey="description.footerpart8">Cookie Policy</Trans>
              </li>{" "}
            </Link>
          </ul>
        </div>
        <div className="footer_contactus">
          <h3 className="font-bold" onClick={scrollToTop}>
            <Trans i18nKey="description.footerpart11">Contact Us</Trans>
          </h3>
          <ul>
            <Link to="/contact">
              <li className="pt-2 mt-2" onClick={scrollToTop}>
                <Trans i18nKey="description.footerpart12">Support</Trans>
              </li>{" "}
            </Link>
          </ul>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <img src={PaymentLogo} alt="Paymentgateways" />
      </div>
      <div className="flex lg:flex-row flex-col justify-center items-center lg:gap-8">
        <p className="pt-4">
          &copy;
          <Trans i18nKey="description.footerpart13">
            {" "}
            2024 All rights reserved by KAVVAY{" "}
          </Trans>
        </p>
        <div className="flex items-center gap-2 pt-4">
          <Link to="https://www.instagram.com/kavvaycom/" target="_blank">
          <FaInstagram className="icons" size={20} color="gray" />
          </Link>
          <Link to="https://www.facebook.com/people/Kavvay/61555422250420/?mibextid=dGKdO6" target="_blank">
          <FaFacebookSquare className="icons" size={20} color="gray" />
          </Link>
          <Link to="https://twitter.com/kavvaycom" target="_blank">
          <FaTwitter className="icons" size={20} color="gray" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
