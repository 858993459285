import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const TOKEN_KEY = "token";

export const loginUser = async (userData) => {
  try {
    const response = await axios.post(
      `${apiConfig.API_URL}/api/Login`,
      userData
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(
      `${apiConfig.API_URL}/api/Register`,
      userData
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    access_token: localStorage.getItem(TOKEN_KEY) || null,
    isLoggedIn: false,
    isLoading: false,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = !!action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.access_token = null;
      state.isLoggedIn = false;
      localStorage.removeItem(TOKEN_KEY);
      window.location.assign("/login");
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    signinRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    signinSuccess: (state, action) => {
      state.user = action.payload.user;
      state.access_token = action.payload.access_token;
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = null;
      localStorage.setItem(TOKEN_KEY, action.payload.access_token);
    },
    signinFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    signupRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    signupSuccess: (state, action) => {
      state.user = action.payload.user;
      state.access_token = action.payload.access_token;
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = null;
      localStorage.setItem(TOKEN_KEY, action.payload.access_token);
    },
    signupFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setUser,
  logout,
  setLoading,
  setError,
  clearError,
  signinRequest,
  signinSuccess,
  signinFail,
  signupRequest,
  signupSuccess,
  signupFail
} = authSlice.actions;

export const signin = (email, password) => async (dispatch) => {
  dispatch(signinRequest());
  try {
    const response = await loginUser({ email, password });
    dispatch(signinSuccess(response));
  } catch (error) {
    let message = "";
    if (error.response && error.response.status === 401) {
      message = "Invalid username or password";
    } else {
      message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
    }
    dispatch(signinFail(message));
  }
};

export const signup = (userData) => async (dispatch) => {
  dispatch(signupRequest());
  try {
    const response = await registerUser(userData);
    dispatch(signupSuccess(response));
  } catch (error) {
    let message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch(signupFail(message));
  }
};


export const authActions = authSlice.actions;
export default authSlice.reducer;
