import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import NavBar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
import axios from "axios";
import { providerCountryList } from "../../redux/slices/countrySlice";
import apiConfig from "../../utils/apiConfig";
import Loader from "../../partials/Loader";
import { useDispatch, useSelector } from "react-redux";

const CalculatePrice = () => {
  const { i18n } = useTranslation();
  const [weight, setWeight] = useState(0);
  const [price, setPrice] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [loadingPricing, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [validateErrors, setValidateErrors] = useState({});
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const {
    providerCountryListData: countryData,
    providerCountryLoading: loading,
  } = useSelector((state) => state.country);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(providerCountryList());
  }, [dispatch]);

  useEffect(() => {}, [i18n]);

  const validateInput = () => {
    const newErrors = {};
    const valErrors = {};

    const validateMaxValue = (value, maxValue, field) => {
      if (parseFloat(value) > maxValue && parseFloat(value) !== 0) {
        valErrors[field] = `Max ${field} ${maxValue}`;
      }
      if (parseFloat(value) === 0) {
        newErrors[field] = `Please add ${field}`;
      }
    };

    validateMaxValue(height, 150, "height");
    validateMaxValue(width, 120, "width");
    validateMaxValue(length, 240, "length");
    validateMaxValue(weight, 30, "weight");

    if (selectedCountry === 0) {
      newErrors.selectedCountry = i18n.t("description.calerrorpart1");
    }

    setErrors(newErrors);
    setValidateErrors(valErrors);
  };

  console.log(validateErrors);

  const handleCalculate = async () => {
    // Show loading indicator
    setLoading(true);

    try {
      const data = {
        countryId: Number(selectedCountry),
        weight: Number(weight),
        height: Number(height),
        width: Number(width),
        length: Number(length),
      };

      // Validate inputs
      validateInput();

      if (
        weight === 0 ||
        height === 0 ||
        width === 0 ||
        length === 0 ||
        weight === "" ||
        height === "" ||
        width === "" ||
        length === "" ||
        selectedCountry === 0
      ) {
        setPrice(0);
        setLoading(false); // Hide loading indicator
        return; // Exit the function
      }

      const res = await axios.post(`${apiConfig.API_URL}/api/Calculate`, data);

      if (res.status === 200) {
        const resData = res.data;
        setPrice(resData);
      } else {
        console.error("Unexpected response status:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Hide loading indicator, whether the request succeeds or fails
      setLoading(false);
    }
  };

  // Call handleCalculate whenever there's a change in input fields or selected country
  useEffect(() => {
    handleCalculate();
  }, [selectedCountry, weight, height, width, length]);
  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
      <NavBar />
      <div className="bg-white p-4 sidebarshadow my-4 border-[1px] border-solid rounded-[10px] w-[100%] mx-auto flex lg:flex-row flex-col items-center justify-between gap-16 pt-16">
        <div className="w-[100%] lg:w-[80%] mx-auto">
          <h2 className="text-[24px] lg:text-[32px] font-bold text-center ">
            <Trans i18nKey="description.calpart1">
              Calculate Delivery Price:
            </Trans>
          </h2>
          <form>
            <div className="w-full pricingBoxes mt-8">
              <div className="flex flex-col mt-2">
                <label>
                  <Trans i18nKey="description.calpart2">Choose Country:</Trans>{" "}
                </label>
                {loading ? (
                  <div>
                    {" "}
                    <Loader />
                  </div>
                ) : (
                  <select
                    required
                    className="p-[10px] border-[1px] border-slate-300 rounded-[5px] text-[14px] mt-1 outline-indigo-400"
                    onChange={handleCountryChange}
                    value={selectedCountry}
                    placeholder={i18n.t("description.orderinputplaceholder4")}
                  >
                    <option value="0">
                      <Trans i18nKey="description.shippingfilter1"></Trans>
                    </option>{" "}
                    {countryData?.map((country, index) => (
                      <option key={index} value={country?.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                )}
                {errors.selectedCountry && (
                  <p className="text-red-500 text-[12px]">
                    <Trans i18nKey="description.calerrorpart1">
                      Height(max 30 cm):
                    </Trans>{" "}
                  </p>
                )}
              </div>

              <div className="flex flex-col mt-2">
                <label>
                  <Trans i18nKey="description.calpart3">
                    Height(max 30 cm):
                  </Trans>{" "}
                </label>
                <input
                  type="number"
                  min={0}
                  max={150}
                  step="any"
                  placeholder={i18n.t("description.calinputplaceholderpart1")}
                  onChange={(e) => {
                    // Remove leading zeros except when the input is '0' or an empty string
                    const inputValue = e.target.value.trim();
                    const sanitizedValue =
                      inputValue === "0" || inputValue === ""
                        ? inputValue
                        : inputValue.replace(/^0+/, "");

                    // Update the input value
                    e.target.value = sanitizedValue;

                    // Handle the input change
                    setHeight(sanitizedValue);
                  }}
                  className="p-[10px] border-[1px] border-slate-200 rounded-[5px] text-[14px] mt-1 outline-none"
                />

                {errors.height && (
                  <p className="text-red-500 text-[12px]">
                    <Trans i18nKey="description.calerrorpart2"></Trans>{" "}
                  </p>
                )}
                {validateErrors.height && (
                  <p className="text-red-500 text-[12px]">
                    <Trans i18nKey="description.calvalidateerrorpart1"></Trans>{" "}
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2">
                <label>
                  <Trans i18nKey="description.calpart4">
                    Width(max 120 cm):
                  </Trans>{" "}
                </label>
                <input
                  type="number"
                  min={0}
                  placeholder={i18n.t("description.calinputplaceholderpart2")}
                  max={120}
                  onChange={(e) => {
                    // Remove leading zeros except when the input is '0' or an empty string
                    const inputValue = e.target.value.trim();
                    const sanitizedValue =
                      inputValue === "0" || inputValue === ""
                        ? inputValue
                        : inputValue.replace(/^0+/, "");

                    // Update the input value
                    e.target.value = sanitizedValue;

                    // Handle the input change
                    setWidth(sanitizedValue);
                  }}
                  className="p-[10px] border-[1px] border-slate-200 rounded-[5px] text-[14px] mt-1 outline-none"
                />
                {errors.width && (
                  <p className="text-red-500 text-[12px]">
                    <Trans i18nKey="description.calerrorpart3">
                      Height(max 30 cm):
                    </Trans>{" "}
                  </p>
                )}
                {validateErrors.width && (
                  <p className="text-red-500 text-[12px]">
                    <Trans i18nKey="description.calvalidateerrorpart2"></Trans>{" "}
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2">
                <label>
                  <Trans i18nKey="description.calpart5">
                    Length(max 240 cm):
                  </Trans>{" "}
                </label>
                <input
                  type="number"
                  min={0}
                  max={240}
                  placeholder={i18n.t("description.calinputplaceholderpart3")}
                  onChange={(e) => {
                    // Remove leading zeros except when the input is '0' or an empty string
                    const inputValue = e.target.value.trim();
                    const sanitizedValue =
                      inputValue === "0" || inputValue === ""
                        ? inputValue
                        : inputValue.replace(/^0+/, "");

                    // Update the input value
                    e.target.value = sanitizedValue;

                    // Handle the input change
                    setLength(sanitizedValue);
                  }}
                  className="p-[10px] border-[1px] border-slate-200 rounded-[5px] text-[14px] mt-1 outline-none"
                />
                {errors.length && (
                  <p className="text-red-500 text-[12px]">
                    <Trans i18nKey="description.calerrorpart4">
                      Height(max 30 cm):
                    </Trans>{" "}
                  </p>
                )}
                {validateErrors.length && (
                  <p className="text-red-500 text-[12px]">
                    <Trans i18nKey="description.calvalidateerrorpart3"></Trans>{" "}
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2">
                <label>
                  <Trans i18nKey="description.calpart6">
                    Weight(max 30 kg):
                  </Trans>{" "}
                </label>
                <input
                  type="number"
                  max={30}
                  placeholder={i18n.t("description.calinputplaceholderpart4")}
                  min={0}
                  onChange={(e) => {
                    // Remove leading zeros except when the input is '0' or an empty string
                    const inputValue = e.target.value.trim();
                    const sanitizedValue =
                      inputValue === "0" || inputValue === ""
                        ? inputValue
                        : inputValue.replace(/^0+/, "");

                    // Update the input value
                    e.target.value = sanitizedValue;

                    // Handle the input change
                    setWeight(sanitizedValue);
                  }}
                  className="p-[10px] border-[1px] border-slate-200 rounded-[5px] text-[14px] mt-1 outline-none"
                />
                {errors.weight && (
                  <p className="text-red-500 text-[12px]">
                    <Trans i18nKey="description.calerrorpart5">
                      Height(max 30 cm):
                    </Trans>{" "}
                  </p>
                )}
                {validateErrors.weight && (
                  <p className="text-red-500 text-[12px]">
                    <Trans i18nKey="description.calvalidateerrorpart4"></Trans>{" "}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-3 items-center gap-2 mt-4 w-[100%]">
              <div className="col-span-1">
                {loadingPricing ? (
                  <div> Loading...</div>
                ) : (
                  <div className="w-[100%] bg-indigo-600 text-center text-white py-[10px]">
                    <Trans i18nKey="description.createshipmentpart14"></Trans> :{" "}
                    {Math.round(price, 2)} $
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4 opacity-[0.7] text-[12px]">
              <p>
                *{" "}
                <Trans i18nKey="description.calpart10">
                  Gönderilerin ağırlığı hesaplanırken tartı üzerindeki ağırlığı
                  ve hacimsel ağırlığı(desi) baz alınır. Hacimsel ağırlık
                  hesaplamalarında gönderinizin en boy ve yükseklik değerlerinin
                  birbiri ile çarpılmasının 5000 ile bölümü ile bulunmaktadır.
                  (Uzunluk * Genişlik * Yükseklik) / 5000 = Ağırlık
                </Trans>
                <br></br>*{" "}
                <Trans i18nKey="description.calpart11">
                  Paketin geçerli ağırlığını hacimsel ağırlığıyla karşılaştırın.
                  İki ağırlıktan daha ağır olanı, faturalandırılabilir
                  ağırlıktır ve fiyatı hesaplamak için bunun kullanılması
                  gerekir.
                </Trans>
                <br></br>*{" "}
                <Trans i18nKey="description.calpart12">
                  Gönderimleriniz gümrükleme gerektirmeyebilir. Gümrük ücretleri
                  ve vergileri, öğelerinizin içeriğine ve değerine ve ülkenizin
                  düzenlemelerine göre hesaplanır.
                </Trans>
              </p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CalculatePrice;
