import React from "react";
import { Trans } from "react-i18next";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
const TAC = () => {
  return (
    <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
    <Navbar/>
    <div className="flex flex-col w-[100%] mx-auto my-12">
      <h2 className="text-center p-[20px] lg:p-[40px] bg-[#d3d3d3]">
        <Trans>Kullanıcı (Üyelik) Sözleşmesi </Trans>
      </h2>
      <div className="p-8">
        <p>
          İş bu “Kullanıcı (Üyelik) Sözleşmesi” (“Sözleşme”), www.kavvay.com
          yani bu web sitesinin (“Site”) ve burada sunulan yazılımların ve
          hizmetlerin (“Hizmet(ler)”) kullanımını düzenlemektedir.
          <br />
          Sözleşme, Kullanıcının (Üyenin) elektronik ortamda kabulü ile birlikte
          yürürlüğe girecek olup; taraflarca Sözleşme ’de belirtilen usuller
          doğrultusunda sona erdirilmediği sürece yürürlükte kalmaya devam
          edecektir. İş bu Sözleşme, üyenin münferit abone, tek bir şirket veya
          kuruluş (“Üye(ler)”) olması fark etmeksizin, kullanıcı olan Üye ve
          “Kavvay Lojistik ve Teknoloji Anonim Şirketi ” kısaca markası ve web
          sayfa ismi ile “KAVVAY” ) arasında gerçekleşen yasal bir sözleşmedir.
          <br />
          Kullanıcı (Üye), web sitemize üye olmakla birlikte, işbu sözleşme ile
          bağlı olduğunu kabul etmiş olup Kullanıcı (Üyelik) Bilgilerinin,
          aşağıda ve web sayfasında yer verilen KVKK ’da belirtilen şekilde
          toplanmasını ve kullanılmasını da kabul etmiş sayılacaktır.{" "}
          <b>www.kavvay.com/kvkk/</b>
          <br />
          Eğer kullanıcı (üye); bir şirket, kuruluş veya tüzel kişi adına bir
          hesap açıyor veya kullanıyorsa: (i) iş bu sözleşmeyle bağlı olacak
          şekilde o kurumun yetkili temsilcisi olduğunu; (ii) iş bu sözleşmeyi
          okuduğunu; (iii) anladığını (iv) tüzel kişi adına onayladığını kabul
          ve taahhüt etmiş olacaktır.
        </p>

        <h3> 1) Hizmetin Tanımı </h3>

        <p>
          İşbu Site ve burada sunulan Hizmetler “Kavvay Lojistik ve Teknoloji
          Anonim Şirketi ” ‘ne ( kısaca markası ve web sayfa ismi ile “KAVVAY” )
          ait olup, perakendecilikle uğraşanlar için sipariş karşılama sürecini
          kolaylaştıran web tabanlı bir nakliye çözümüdür. Popüler pazarlara ve
          alışveriş platformlarına gerçek zamanlı entegrasyonlarla, sipariş alma
          ve toplu etiket oluşturmadan müşteri iletişimine kadar her türlü
          faaliyeti gerçekleştirmektedir. Gelişmiş özelleştirme seçenekleri,
          Kavvay ‘in işletmelere istenilen sayıda kullanıcı veya konumla uyum
          sağlamasını destekler. Kavvay ’in yazılımını ve yazılım dışında
          sunulan lojistik hizmetinden de faydalananlar “Üye” olarak
          adlandırılmaktadır. Her üyenin aldığı hizmetlerin, ücret ve koşullar
          birbirinden farklıdır.
        </p>

        <h3>2) Site ve Hizmete Dair Genel Bilgiler </h3>
        <p>
          2.1. Kavvay, site ’de ki bilgilerin doğruluğunu ve güncelliğini
          sürekli olarak kontrol eder. Ancak, gösterilen tüm çabalara rağmen
          ortaya çıkan yazım ve sistem hatalarından iyzi sorumlu değildir. Üye,
          Hizmet ’e erişimin muhtelif zamanlarda engellenebileceğini ya da
          erişimin kesilebileceği kabul eder.
          <br />
          2.2. Kavvay, site’de yer alan veya alacak olan bilgileri, formları,
          içeriği ve iş bu Kullanıcı(Üyelik) Sözleşmesi’ni dilediği zaman
          değiştirme hakkını saklı tutmaktadır.
          <br />
          2.3. Kavvay, hesapları feshedebilir, Hizmete gönderilen tüm içeriği
          veya bilgileri silebilir ve / veya herhangi bir sebepten dolayı Hizmet
          ’in (veya Hizmet ’in herhangi bir kısmının veya özelliğinin)
          kullanımını veya erişimini herhangi bir zamanda, kendi takdirine bağlı
          olarak, bildirimde bulunarak veya bulunmayarak engelleyebilir. Kavvay
          ‘in herhangi bir sebepten ötürü Site ’de yer alan herhangi bir hesabı
          (tamamen veya kısmen) feshetmesi kendi inisiyatifindedir. Ayrıca,
          Kavvay, herhangi bir üye verisinin görüntülenmesini durdurma hakkı da
          dahil olmak üzere Hizmet ’in herhangi bir yönünü durdurma hakkını
          saklı tutar.
          <br />
          2.4. Site diğer web sitelerine bağlantılar içerebilir ("Bağlantılı
          Siteler"). Bağlantılı Sitelerde, Kavvay ‘in herhangi bir kontrolü ve
          sorumluluğu bulunmamaktadır.
          <br />
          2.5. Taşınması Türk veya ilgili ülke mevzuatlarına göre yasaklanmış
          bulunan ürünlerin gönderilmesi talepleri karşılanmayacak olup Kavvay
          ‘in söz konusu hallerde Siteye ve ilgili hizmetlere erişimi engelleme
          hakkı bulunmaktadır.
          <br />
          2.6. Kavvay, üye tarafından kendisi ile paylaşılan bilgi ve verileri
          işbu Sözleşme ’ye ek niteliğinde olan “Gizlilik Politikası” kapsamında
          saklayacak ve kullanacaktır. Üye, Kavvay ’in yürürlükteki mevzuat
          uyarınca yetkili makamlardan talep gelmesi halinde üye ’nin kendisinde
          bulunan bilgilerini ilgili yetkili makamlarla paylaşabileceğini kabul
          eder.
          <br />
          2.7. Kavvay, üyeyi bilgilendirmeden gerekli gördüğü sistem
          değişikliklerini, güncellemelerini ve kısıtlamalarını değiştirme
          hakkını saklı tutar.
          <br />
        </p>
        <h3>3) Kullanıcının Hak ve Yükümlülükleri </h3>
        <p>
          3.1. Üye, Kullanıcı (Üyelik) hesabını ve işbu Sözleşme ile Site
          kullanımından doğan hak ve yükümlülüklerini herhangi bir şekilde
          üçüncü bir kişiye devir veya temlik edemez. Kavvay ’in sunduğu
          Hizmetler’den yararlananlar ve Site 'yi kullananlar, yalnızca hukuka
          uygun ve şahsi/kurumsal amaçlarla Site üzerinden işlem yapabilirler.
          Üye 'nin, Site kapsamında gerçekleştirdiği her işlem ve eylemdeki
          hukuki ve cezai sorumluluk kendisine aittir.
          <br />
          3.2. Üye tarafından Site ’ye erişim e-posta adresi ve şifresi
          kullanılarak gerçekleştirilecektir. Bu şifrenin gizliliğinin ve
          güvenliğinin korunmasından üye sorumlu olacak olup Site üzerinden söz
          konusu bilgilerin kullanımı ile gerçekleştirilen her türlü faaliyetin
          üye tarafından gerçekleştirildiği kabul edilecek ve aynı zamanda bu
          faaliyetlerden doğan her türlü yasal ve cezai sorumluluk üye ’ye ait
          olacaktır. Üye, şifresinin yetkisiz kullanımı veya güvenliğin başka
          şekilde ihlalinden haberdar olduğunda bu durumu derhal Kavvay ’e
          bildirecektir. Üye, sadece tanıtım, reklam, kampanya, promosyon,
          duyuru vb. pazarlama faaliyetleri kapsamında kullanılması ile sınırlı
          olmak üzere, Kavvay ’in, kendisine ait iletişim, portföy durumu ve
          demografik bilgilerini iştirakleri ya da bağlı bulunduğu şirketler ile
          paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bu kişisel
          bilgiler Kavvay bünyesinde müşteri profili belirlemek, müşteri
          profiline uygun promosyon ve kampanyalar sunmak ve istatistiksel
          çalışmalar yapmak amacıyla kullanılabilecektir. Üye, içerik ve diğer
          bilgilerin Kavvay veya üçüncü kişiler tarafından Türkiye’de veya yurt
          dışında bulunan veri merkezlerinde saklanabileceğini kabul eder.
          <br />
          3.3. Üye kendisine site içerisinde bildirilen gönderi takip numarası
          ile gönderisini takip etmek ve herhangi bir durumda ( Adres hatası,
          gümrüklü işlem dolayısı ile gecikme ve benzeri) gerek alıcı gerek
          Kavvay ile yazılı iletişime geçerek konunun çözümü için bilgi
          vereceğini ve gönderisini yurtdışına taşıyan ana taşıyıcılara ( DHL,
          UPS, Fedex, TNT, USPS, Royal Mail, Deutschepost, GLS, DPD ve diğerler
          firmalara ) ait web sayfalarından bizzat takip etmek ile sorumludur.
          Üye, gerek kendi gerek gönderinin nihai alıcısının takipsizlik
          iletişimsizliği sonucu oluşacak herhangi bir masraf veya durumdan
          Kavvay ‘i sorumlu tutmayacağını kabul eder.
        </p>
        <h3>4) Fikri Mülkiyet Hakları </h3>
        <p>
          4.1. Üye, Site ’de ve verilen Hizmet ’te tüm fikri mülkiyet haklarının
          Kavvay ’e ait olduğunu kabul ve beyan eder.
          <br />
          4.2. İşbu Kullanıcı(Üyelik) Sözleşmesi dahilinde Kavvay tarafından
          sarahaten izin verilen durumlar haricinde, üyeler, Kavvay ’in telif
          haklarına tabi çalışmalarını çoğaltamaz, işleyemez, dağıtamaz veya
          bunlardan türemiş çalışmalar yapamaz veya hazırlayamaz.
          <br />
        </p>

        <h3>
          5) Mücbir Sebepler Kavvay, hukuken mücbir sebep sayılan tüm
          durumlarda, işbu sözleşmeden ve gizlilik politikasından doğan
          yükümlülüklerinden herhangi birini tam olarak ve zamanında ifa
          edememekten dolayı sorumlu değildir. Böyle bir hususun varlığı halinde
          Kavvay ’in herhangi bir tazminat sorumluluğu doğmayacaktır.
        </h3>

        <h3>
          6) Ücretler ve Vergiler Üyelik dahilinde üyeye özel tanımlanan
          ücretler, Kavvay ’e abone olan üyeler için geçerli olacak olup, Kavvay
          farklı ücretler belirlemek hakkını haizdir.{" "}
        </h3>

        <p>
          6.1. Hizmet ile ilgili ödeme ve vergi gibi ücretlerin geçerli bir
          ödeme yöntemiyle ve zamanında ödenmesinden üye sorumludur. Aksi
          belirtilmedikçe, tüm ücretler ABD(Amerikan) Doları, AVRO(Euro) ve
          POUND(İngiliz Poundu) para cinsindendir. Tüm ödemeler Hizmet
          içerisinde belirtilen yöntemlerle yapılmalıdır. Tüm ücretler ve
          masraflar tarafımızdan alındıktan sonra kazanılır ve (a) açıkça
          belirtildiği durumlarda ve / veya (b) yürürlükteki yasaların
          gerektirdiği haller dışında, iade edilemez.
          <br />
          6.2. Üye satış, kullanım veya katma değer vergisi gibi Hizmet 'i
          kullanmasına dair öngörülen herhangi bir devlet vergisini ödemekle
          yükümlüdür. Kavvay 'in bu tür vergileri tahsil etmek zorunda olduğu
          ölçüde, geçerli vergi, faturalandırma hesabına eklenecektir ve üyeden
          tahsil edilecektir. Kavvay, yurtdışından iade olarak gelen gönderi
          navlun ücretlerini, ana taşıyıcı firmaların sonradan gönderi ile
          ilgili Kavvay ‘e fatura ettiği ek ücretleri, Türkiye veya yurtdışında
          oluşan vergi, ceza vb. tüm masrafları üyeden tahsil edecektir. Üye bu
          tahsilatı en başından gönderiyi yurtdışına gönderirken kabul etmiş
          sayılmaktadır.
          <br />
          6.3. Seçilen ödeme yöntemi hesabını tahsil etme yetkisi, Hizmet
          içindeki tercihler iptal edinceye veya değiştirilinceye dek yürürlükte
          kalacaktır; Ancak, böyle bir bildirim, Kavvay 'in makul bir şekilde
          harekete geçmesinden önce sunulan ücretleri etkilemeyecektir.
          <br />
          6.4. Kavvay, Hizmet için herhangi bir ücret veya ücret sınırını veya
          miktarını değiştirme ve üyelerine önceden bildirimde bulunarak yeni
          ücret veya şartlar oluşturma hakkını saklı tutar. Herhangi bir üyelik
          süresi için ödenen ücretler peşin olarak ödenir ve tamamen veya kısmen
          iade edilmez.
          <br />
          6.5. Hizmet iptal edilmediği müddetçe aylık üyelik her ayın sonunda,
          yıllık üyelik ise her yıl dönümü bitiminde otomatik olarak
          yenilenecektir.
          <br />
          6.6. Yıllık üyelikler için, yıllık yenileme tarihinden en az 30 gün
          önce, aboneliğin iptal edilmek istendiği bildirilmelidir. Aylık
          üyelikler için ise yedi (7) gün öncesinde bildirim yapmak
          gerekmektedir.
          <br />
          6.7. Üye, önden ödeme (depozito) göndererek cüzdanım özelliğine para
          ve bakiye yüklemektedir. Kavvay alacağı oluşması durumunda tüm alacak
          bakiyesini üye ’ye ait olan cüzdanım özelliğinde bulunan fazla
          bakiyeden tahsil etme hakkına sahiptir, üye bu tahsilat için Kavvay ’e
          en başından yetki vermiştir.
          <br />
          6.8. Üyeye ait olan cüzdanım özelliğinde borcuna karşılık yeterli
          miktar bulunmaması durumunda kredi kartı, eft/havale veya nakit olarak
          ödeyeceğini taahhüt etmiştir.
          <br />
          6.9. Üye herhangi bir sebepten ötürü (Yasalara aykırı içerik veya alt
          tedarikçi kurye firmalarının kararları nedeni ile) yurtdışına
          çıkartılamamış gönderiler ile ilgili; üyeden Kavvay ’in adresine
          taşınması esnasında oluşan iç nakliye(kargo) taşıma ücretini ve buna
          ilave olarak Kavvay ’in adresinden üyenin adresine iade olarak
          gönderilmesi için oluşacak iç nakliye(kargo) taşıma ücretini
          ödeyeceğini peşinen kabul etmiştir. Oluşacak bu iç nakliye(kargo)
          taşıma ücretlerinin tutarlarını belirleme hakkını Kavvay kendisinde
          saklı tutar.
        </p>

        <h3>7) Sözleşmenin Sonlandırılması </h3>
        <p>
          7.1. Taraflardan herhangi biri, diğer tarafça bildirilen elektronik
          posta adresine 1 (bir) hafta önceden yapacağı yazılı bir bildirimle
          işbu Sözleşme’yi dilediği zaman herhangi bir gerekçe göstermeksizin ve
          tazminat ödemeksizin feshedebilecektir.
          <br />
          7.2. İşbu Sözleşme’nin herhangi bir hükmünün veya sözleşmede yer alan
          herhangi bir ifadenin geçersizliği, yasaya aykırılığı ve
          uygulanamazlığı, Sözleşme’nin geri kalan hükümlerinin yürürlüğünü ve
          geçerliliğini etkilemeyecektir.
          <br />
          7.3. İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak
          her türlü uyuşmazlığın çözümünde İstanbul Çağlayan Adliyesi
          Mahkemeleri ve İcra Daireleri yetkilidir.
        </p>
      </div>
        </div>
        <Footer/>
        </div>  
  );
};

export default TAC;
